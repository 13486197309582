import React from 'react'
import UncoverCard from '../../Component/UncoverCard/UncoverCard'
import custom from "../../Assets/BC2.png"
import custom2 from "../../Assets/BC3.png"
import custom3 from "../../Assets/BC4.png"
import DiscoverCard from '../../Component/DiscoverCard/DiscoverCard'
import webImage from "../../Assets/image_main/Business Consulting.jpg"
import Bc1 from "../../Assets/Problem solving.avif"
import Bc2 from "../../Assets/Decision support.avif"
import Bc3 from "../../Assets/Resource Optimization.jpg"
import Bc4 from "../../Assets/Capacity building.jpg"
import DiscoverCardCenter from '../../Component/DiscoverCardCenter/DiscoverCardCenter'
import Goals from '../../Component/Goals/Goals'
import Strategic_Planning from "../../Assets/icon/strategic planning.jpg"
import Operational_Efficiency from "../../Assets/icon/strategic planning.jpg"
import Market_Research from "../../Assets/icon/market research and analysis.jpg"
import Management_Changes from "../../Assets/icon/management changes.jpg"
import Human_Resources from "../../Assets/icon/human resources consulting.jpg"
import Sales_and_Marketing from "../../Assets/icon/sales and marketing consulting.jpg"
import IT_Consulting from "../../Assets/icon/it consulting.jpg"
import Sustainability_Consulting from "../../Assets/icon/sustainability consulting.jpg"
import Customer_Experience from "../../Assets/icon/customer experience consulting.jpg"
import Startup_Consulting from "../../Assets/icon/startup consulting.jpg"
import Training_and_Development from "../../Assets/icon/training and development.jpg"
import Personalized_Solutions from "../../Assets/icon/personalized solution.jpg"
import Professionals_Experience from "../../Assets/icon/professionals experience.jpg"
import Comprehensive_thinking from "../../Assets/icon/comprehensive thinking.jpg"
import Data_Driven_Insights from "../../Assets/icon/data-driven insights.jpg"
import Ongoing_Support from "../../Assets/icon/ongoing support.jpg"
import Commitment_to_Results from "../../Assets/icon/commitment to results.jpg"
import Enhance_Business_Growth from "../../Assets/image/enhance business growth.jpg"
import Strategic_Problem_Solving from "../../Assets/image/strategic problem solving.jpg"
import Optimize_Resource_Allocation from "../../Assets/image/optimize resource allocation.jpg"
import Drive_Innovation_and_Adaptability from "../../Assets/image/drive innovation and adaptability.jpg"
import Change_Management_Support from "../../Assets/image/change management support.jpg"
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'


function BusinessConsulting() {
  return (
    <>
      <Helmet>
        <title>Consulting for Growth: Promoting Business Excellence</title>
        <meta
          name="description"
          content="With our consulting services, you can unlock the potential of your business and promote growth and excellence by using specialized strategies and knowledgeable advice."
        />
        <meta
          name="keywords"
          content="web development services in prayagraj, best website development in prayagraj, SMM services in prayagraj, best SMM services in prayagraj, digital marketing services and agency in prayagraj, best digital marketing agency in prayagraj, digital marketing in prayagraj, cloud computing services in prayagraj, financial advisory services in prayagraj, BPO Outsourcing services in Prayagraj"
        />
        <meta name="author" content="BizzBuzzCreations" />
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-XVSSY0BR1E"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-XVSSY0BR1E');
        `}
        </script>
      </Helmet>
      <div className="webdevelpment_page d-flex align-items-center justify-content-center text-center flex_page">
        {/* <div className="image_portion_web img-width">
          <Img src={webImage} alt="image" className="image_main" />
        </div> */}
        <div className="content_portion p-15">
          <h2 className="weight-600 mb-3">Business Consulting</h2>
          <h5 className="weight-500">
            Welcome to Bizz Buzz Creation, your trusted partner in business consulting.
          </h5>
          <p className="weight-400 mb-5">
            Business consulting provides expert advice to organizations on various aspects, including strategy, operations, finance, and human resources, helping them improve performance, solve problems, and achieve sustainable growth and success.
          </p>
          <button className="get_button"><Link className="text-decoration-none" to="/contact">Get Started</Link></button>
        </div>
      </div>
      <div className='web_portion'>
        <div className='head_portion'>
          <h1 className='font-20-res-16 weight-600 text-center mb-5'>Business Consulting Services We Offer</h1>
        </div>
        <div className='grid_portion'>
          <DiscoverCardCenter icon_image={Strategic_Planning} title="Strategic Planning" paragraph="Strategic planning involves defining an organization's vision, mission, and long-term goals. It outlines actionable strategies and resource allocation to achieve objectives, ensuring alignment and adaptability in a competitive environment." />
          <DiscoverCardCenter icon_image={Operational_Efficiency} title="Operational Efficiency" paragraph="Operational efficiency focuses on optimizing processes, reducing waste, and improving productivity within an organization. It enhances resource utilization, minimizes costs, and streamlines workflows to achieve better overall performance and profitabi" />
          <DiscoverCardCenter icon_image={Market_Research} title="Market Research and Analysis" paragraph="Market research and analysis involve gathering and interpreting data about market trends, customer preferences, and competitors. This information helps businesses make informed decisions, identify opportunities, and refine marketing strategies for success." />
          <DiscoverCardCenter icon_image={Management_Changes} title="Management Changes" paragraph="Management changes involve alterations in leadership or organizational structure, aiming to improve efficiency, address challenges, or implement new strategies. Effective communication and planning are essential for smooth transitions and employee adaptation." />
          <DiscoverCardCenter icon_image={Human_Resources} title="Human Resources Consulting" paragraph="Human resources consulting provides expertise in talent acquisition, employee development, performance management, and compliance. It helps organizations optimize their workforce, enhance employee engagement, and implement effective HR policies and practices." />
          <DiscoverCardCenter icon_image={Sales_and_Marketing} title="Sales and Marketing Consulting" paragraph="Sales and marketing consulting focuses on enhancing strategies to boost sales performance, optimize marketing campaigns, and improve customer engagement, ultimately driving revenue growth and strengthening brand presence in the market." />
          <DiscoverCardCenter icon_image={IT_Consulting} title="IT Consulting" paragraph="IT consulting provides expert guidance on technology solutions, cybersecurity, data management, and digital transformation. It helps organizations leverage technology to improve efficiency, enhance security, and achieve business objectives effectively." />
          <DiscoverCardCenter icon_image={Sustainability_Consulting} title="Sustainability Consulting" paragraph="Sustainability consulting helps organizations implement eco-friendly practices, reduce environmental impact, and promote social responsibility. It focuses on developing sustainable strategies that enhance efficiency while meeting regulatory and stakeholder expectations." />
          <DiscoverCardCenter icon_image={Customer_Experience} title="Customer Experience Consulting" paragraph="Customer experience consulting helps organizations improve interactions with customers by analyzing feedback, optimizing touchpoints, and implementing strategies to enhance satisfaction, loyalty, and overall engagement for long-term business success." />
          <DiscoverCardCenter icon_image={Startup_Consulting} title="Startup Consulting" paragraph="Startup consulting provides guidance to new businesses on developing viable business models, securing funding, navigating market entry, and establishing operational processes, ensuring a solid foundation for growth and success." />
          <DiscoverCardCenter icon_image={Training_and_Development} title="Training and Development" paragraph="Training and development involve creating and implementing programs to enhance employees' skills, knowledge, and performance. This fosters professional growth, increases productivity, and contributes to organizational success and employee satisfaction." />

        </div>

      </div>
      <div className='incover_portion pb-5'>
        <h1 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>Objectives of Business Consulting</h1>
        <div className='grid_portion m-auto align-items-center justify-content-center '>
          <UncoverCard image_uncover={Enhance_Business_Growth} title="Enhance Business Growth" paragraph="Develop growth strategies that include market expansion, product or service diversification, and increasing competitive advantage to drive business growth." />
          <UncoverCard image_uncover={Strategic_Problem_Solving} title="Strategic Problem Solving" paragraph="Provide expert insights and solutions to complex business challenges, helping companies overcome obstacles and make informed decisions." />
          <UncoverCard image_uncover={Optimize_Resource_Allocation} title="Optimize Resource Allocation" paragraph="Advise on optimal allocation of financial, human, and technological resources to maximize return on investment and improve overall performance." />
          <UncoverCard image_uncover={Drive_Innovation_and_Adaptability} title="Drive Innovation and Adaptability" paragraph="Help businesses adopt new technologies, innovative practices, and adapt to market trends, ensuring they stay competitive and future-ready." />
          <UncoverCard image_uncover={Change_Management_Support} title="Change Management Support" paragraph="Guide businesses through organizational changes, such as restructuring, mergers, or implementing new processes, ensuring smooth transitions and minimal disruptions." />
          {/* <UncoverCard image_uncover={""} title="" paragraph=""/> */}
        </div>

      </div>
      <div className='Page'>
        <h1 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>Why Choose BBC for Business Consulting Services ?</h1><div className="App">
          <div className="card-container container">
            <Goals logo_icon={Personalized_Solutions} title="Personalized Solutions" paragraph="BBC provides customized consulting services that target your particular business objectives and issues, guaranteeing successful tactics that meet your particular requirements." />
            <Goals logo_icon={Professionals_Experience} title="Professionals  Experience" paragraph="Our staff is made up of seasoned consultants with a range of industry experience, who can offer insightful advice and tried-and-true techniques to help your organization succeed." />
            <Goals logo_icon={Comprehensive_thinking} title="Comprehensive thinking" paragraph="We take a complete view of your firm, evaluating all aspects—operations, finance, marketing, and human resources—to build integrated solutions that support overall growth." />
            <Goals logo_icon={Data_Driven_Insights} title="Data-Driven Insights" paragraph="BBC makes sure strategies are supported by data and in line with current trends by using market research and advanced analytics to inform decision-making." />
            <Goals logo_icon={Ongoing_Support} title="Ongoing Support" paragraph="We provide continuous support throughout the consulting process, helping you implement strategies and make necessary adjustments for sustained improvement." />
            <Goals logo_icon={Commitment_to_Results} title="Commitment to Results" paragraph="We are committed to achieving quantifiable results and supporting your company in reaching its goals, establishing enduring partnerships based on success and trust." />
          </div>
        </div>
      </div>

      {/* <div style={{ background: "#d4dfe3", padding: "0 0 20px 0" }}>
        <div className='container'>
          <div className='d-flex gap-5 align-items-center pt-5 flex_page'>
            <div className='img-width hr_hero'>
            <Img src={custom} alt="ecomm website" className='imagehr'/>
            </div>
            <div className='text-start'>
              <h2 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>Strategic Planning</h2>
              <p className='font-18-res-16 weight-400'>Welcome to Bizz Buzz Creation, your partner in strategic planning for business success. In today's rapidly evolving market, having a clear and well-defined strategy is essential for achieving your business goals. Our strategic planning services are tailored to help you navigate complexity, seize opportunities, and chart a course for sustainable growth. Here's why our services are invaluable, how they can benefit you, and our objectives in providing them.</p>
            </div>
          </div>
          <h2 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>Why Choose BBC for Business Consulting Services ?</h2>
          <DiscoverCard title="Personalized Solutions" paragraph=" BBC provides customized consulting services that target your particular business objectives and issues, guaranteeing successful tactics that meet your particular requirements." />
          <DiscoverCard title="Professionals  Experience" paragraph="Our staff is made up of seasoned consultants with a range of industry experience, who can offer insightful advice and tried-and-true techniques to help your organization succeed." />
          <DiscoverCard title="Comprehensive thinking" paragraph="We take a complete view of your firm, evaluating all aspects—operations, finance, marketing, and human resources—to build integrated solutions that support overall growth." />
          <DiscoverCard title="Data-Driven Insights" paragraph="BBC makes sure strategies are supported by data and in line with current trends by using market research and advanced analytics to inform decision-making." />
          <DiscoverCard title="Ongoing Support" paragraph="We provide continuous support throughout the consulting process, helping you implement strategies and make necessary adjustments for sustained improvement." />
          <DiscoverCard title="Commitment to Results" paragraph=" We are committed to achieving quantifiable results and supporting your company in reaching its goals, establishing enduring partnerships based on success and trust."/> 
          <h2 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>How Our Strategic Planning services Can Help You ?</h2>
          <div className='grid_portion' style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)",  gap: "10px", textAlign: "center" }}>
            <div>
              <h3 className='font-20-res-16 weight-600'>Strategic Alignment and Market Analysis</h3>
              <p className='font-18-res-16 weight-400'>Our consulting services ensure your business strategy aligns with your vision, values, and objectives, fostering coherence across all levels of your organization. We develop a comprehensive roadmap to guide decision-making and resource allocation. Additionally, we conduct thorough market analysis to identify industry trends, opportunities, and threats, providing insights that enable you to make informed decisions and capitalize on emerging market dynamics.</p>
            </div>
            <div>
              <h3 className='font-20-res-16 weight-600'>Scenario Planning</h3>
              <p className='font-18-res-16 weight-400'>We help you anticipate and plan for various scenarios, including best-case, worst-case, and most likely outcomes. Our scenario planning approach enables you to prepare for uncertainty and mitigate risks effectively.</p>
            </div>
            <div>
              <h3 className='font-20-res-16 weight-600'>Change Management</h3>
              <p className='font-18-res-16 weight-400'>Implementing a new strategy often requires organizational change. We provide change management support to ensure smooth transitions, build buy-in from stakeholders, and foster a culture of innovation and adaptability.</p>
            </div>
            <div>
              <h3 className='font-20-res-16 weight-600'>Performance Measurement</h3>
              <p className='font-18-res-16 weight-400'>We establish key performance indicators (KPIs) and metrics to track progress against strategic objectives. Our performance measurement framework provides visibility into your business's performance and enables course correction as needed.</p>
            </div>
          </div>

          <h2 className='text-center mb-5 pt-5'>Objectives of Providing Strategic Planning Services</h2>
          <DiscoverCard title="Achieving Business Growth" paragraph="Our primary objective is to help you achieve sustainable business growth and profitability. We develop strategic plans that capitalize on your strengths, address weaknesses, and leverage market opportunities to drive success." />
          <DiscoverCard title="Enhancing Competitiveness" paragraph="We aim to enhance your competitive advantage by developing differentiated strategies that set you apart from competitors. Our goal is to position your business as a leader in your industry and capture market share." />
          <DiscoverCard title="Fostering Innovation" paragraph="Strategic planning fosters a culture of innovation within your organization, encouraging creative thinking and experimentation. We help you identify new opportunities for growth and develop innovative solutions to meet evolving customer needs." />
          <DiscoverCard title="Building Resilience" paragraph="We strive to build resilience into your business by proactively managing risks and preparing for uncertainties. Our strategic planning process enables you to anticipate challenges and adapt quickly to changing market conditions." />
          <DiscoverCard title="Creating Value" paragraph="Ultimately, our objective is to create value for your business and stakeholders. We measure our success by the tangible results we deliver, including revenue growth, cost savings, market share expansion, and increased shareholder value." />
          <DiscoverCard title="" paragraph="At Bizz Buzz Creation, we are committed to helping you achieve your business objectives through strategic planning. Our experienced consultants work collaboratively with you to develop tailored strategies that drive sustainable growth and success." />
          <DiscoverCard title="" paragraph="Contact us today to learn more about our strategic planning services and how we can help you unlock your business's full potential." />
        </div>
      </div>

      <div style={{ background: "#EEEEEE", padding: "0 0 20px 0" }}>
        <div className='container'>
          <div className='d-flex gap-5 align-items-center pt-5 flex_page'>
          <div className='img-width'>
            <Img src={custom2} alt="ecomm website" className='image' />
            </div>
            <div className='text-start'>
              <h2 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>Market Research and Analysis Services</h2>
              <p className='font-18-res-16 weight-400'>Welcome to Bizz Buzz Creation, your partner in comprehensive market research and analysis. In today's dynamic business environment, understanding market trends, consumer behavior, and competitive landscapes is essential for making informed decisions and staying ahead of the competition. Our market research and analysis services are designed to provide you with actionable insights and strategic recommendations to drive your business forward. Here's why our services are invaluable, how they can benefit you, and our objectives in providing them.</p>
            </div>
          </div>
          <h2 className='text-center mb-5 pt-5'>Why Choose BBC for Market Research and Analysis Services ?</h2>
          <DiscoverCard title="Informed Decision Making" paragraph="Our market research provides you with valuable insights to make informed decisions about product development, marketing strategies, and business expansion. By understanding market dynamics, you can mitigate risks and capitalize on opportunities effectively." />
          <DiscoverCard title="Identifying Growth Opportunities" paragraph="We help you identify untapped market segments, emerging trends, and niche opportunities for growth. Our analysis enables you to tailor your offerings to meet evolving customer needs and stay ahead of shifting market demands." />
          <DiscoverCard title="Competitive Intelligence" paragraph="Our research includes comprehensive competitive analysis to assess your competitors' strengths, weaknesses, and strategies. By benchmarking your performance against industry peers, you can identify areas for improvement and develop strategies to outperform the competition." />
          <DiscoverCard title="Risk Mitigation" paragraph="Market research helps you anticipate and mitigate potential risks, such as changing consumer preferences, regulatory shifts, or disruptive technologies. By staying ahead of market trends, you can proactively address threats to your business and maintain resilience." />
          <DiscoverCard title="Optimizing Marketing Strategies" paragraph="We provide insights into consumer preferences, buying behaviors, and media consumption habits to help you optimize your marketing strategies. By targeting the right audience with the right message, you can maximize the effectiveness of your marketing efforts and drive customer engagement." />

          <h2 className='text-center mb-5 pt-5'>How Our Market Research and Analysis Services Can Help You ?</h2>
          <div className='grid_portion' style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)",  gap: "10px", textAlign: "center" }}>
            <div>
              <h3 className='font-20-res-16 weight-600'>Customized Research Solutions</h3>
              <p className='font-18-res-16 weight-400'>We tailor our research methodologies to your specific needs, whether you're launching a new product, entering a new market, or evaluating market opportunities. Our customized solutions ensure that you receive relevant and actionable insights to support your business objectives.</p>
            </div>
            <div>
              <h3 className='font-20-res-16 weight-600'>Data-Driven Insights and Strategic Recommendations</h3>
              <p className='font-18-res-16 weight-400'>Our research is grounded in rigorous data collection, analysis, and interpretation, utilizing techniques such as surveys, interviews, focus groups, and secondary data analysis to deliver accurate insights into market trends and consumer behavior. We go beyond analysis to offer strategic recommendations that drive business growth, distilling complex findings into actionable insights and practical steps for enhancing your competitive position and market performance.</p>
            </div>
            <div>
              <h3 className='font-20-res-16 weight-600'>Continuous Monitoring</h3>
              <p className='font-18-res-16 weight-400'>Market dynamics are constantly evolving. We offer ongoing monitoring and tracking services to keep you updated on industry trends, competitor activities, and consumer preferences. Our continuous monitoring ensures that you remain agile and responsive to changes in the market landscape.</p>
            </div>
            <div>
              <h3 className='font-20-res-16 weight-600'>Value-driven Outcomes</h3>
              <p className='font-18-res-16 weight-400'>Our objective is to deliver value-driven outcomes that support your business objectives and drive sustainable growth. Whether it's increasing market share, improving customer satisfaction, or optimizing marketing ROI, we focus on delivering measurable results that impact your bottom line.</p>
            </div>
          </div>

          <h2 className='text-center mb-5 pt-5'>Objectives of Providing Market Research and Analysis Services</h2>
          <DiscoverCard title="Driving Business Growth" paragraph="Our primary objective is to help you identify and capitalize on growth opportunities in your target markets. We provide insights and recommendations that enable you to make strategic decisions that drive business expansion and profitability." />
          <DiscoverCard title="Enhancing Competitiveness" paragraph="We aim to enhance your competitive advantage by providing you with a deep understanding of your market environment and competitors. Our insights help you differentiate your offerings, identify areas for innovation, and stay ahead of industry trends." />
          <DiscoverCard title="Mitigating Risks" paragraph="We help you mitigate risks by providing early warnings of potential threats and opportunities. Our research enables you to anticipate changes in the market landscape, regulatory environment, and consumer preferences, allowing you to adapt your strategies accordingly." />
          <DiscoverCard title="Improving Decision Making" paragraph="Our market research and analysis services empower you to make data-driven decisions with confidence. By providing you with actionable insights and strategic recommendations, we support you in making informed choices that drive business success." />
          <DiscoverCard title="Fostering Innovation" paragraph="We foster innovation by identifying emerging trends, customer needs, and market gaps that present opportunities for innovation. Our research helps you develop new products, services, and business models that meet evolving market demands and position you as a leader in your industry." />
          <DiscoverCard title="" paragraph="At Bizz Buzz Creation, we are committed to helping you unlock the full potential of your business through insightful market research and analysis. Our dedicated team of experts is here to provide you with the strategic guidance and actionable insights you need to succeed in today's competitive marketplace." />
          <DiscoverCard title="" paragraph="Contact us today to learn more about our market research and analysis services and how we can help you achieve your business objectives." />
        </div>
      </div>

      <div style={{ background: "#d4dfe3", padding: "0 0 20px 0" }}>
        <div className='container'>
          <div className='d-flex gap-5 align-items-center pt-5 flex_page'>
          <div className='img-width'>
            <Img src={custom3} alt="ecomm website" className='image' />
            </div>
            <div className='text-start'>
              <h2 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>Marketing and Sales Strategy</h2>
              <p className='font-18-res-16 weight-400'>Welcome to Bizz Buzz Creation: Your Partner in Crafting Winning Marketing and Sales Strategies
At Bizz Buzz Creation, we understand that in today's competitive business landscape, having a robust marketing and sales strategy is crucial for success. That's why we're here to offer our expert services in crafting tailored strategies that propel your business forward.</p>
            </div>
          </div>
          <h2 className='text-center mb-5 pt-5'>Why Choose BBC for Marketing and Sales Strategy ?</h2>
          <DiscoverCard title="Customized Approach" paragraph="We don't believe in one-size-fits-all solutions. Our team works closely with you to understand your unique business needs, target audience, and industry landscape to develop a strategy that is tailored to your specific requirements." />
          <DiscoverCard title="Increased Revenue" paragraph="A well-crafted marketing and sales strategy can significantly boost your revenue streams. Whether it's through targeted advertising campaigns, optimized sales funnels, or strategic pricing strategies, we help you maximize your earning potential." />
          <DiscoverCard title="Enhanced Brand Visibility" paragraph="Building a strong brand presence is essential for standing out in today's crowded marketplace. Our services are designed to increase your brand visibility across various channels, ensuring that your business gets noticed by the right audience." />
          <DiscoverCard title="Improved Customer Engagement" paragraph="Engaging with your customers effectively is key to building long-lasting relationships and fostering loyalty. We help you create engaging content, develop personalized messaging, and implement effective communication strategies to keep your customers coming back for more." />

          <h2 className='text-center mb-5 pt-5'>How our Marketing and Sales Strategy Services Can Help You ?</h2>
          <div className='grid_portion' style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)",  gap: "10px", textAlign: "center" }}>
            <div>
              <h3 className='font-20-res-16 weight-600'>Strategic Planning</h3>
              <p className='font-18-res-16 weight-400'>We start by conducting a comprehensive analysis of your current marketing and sales efforts, identifying areas for improvement, and opportunities for growth. Based on our findings, we develop a strategic plan that aligns with your business objectives and targets.</p>
            </div>
            <div>
              <h3 className='font-20-res-16 weight-600'>Targeted Campaigns</h3>
              <p className='font-18-res-16 weight-400'>Our team leverages data-driven insights to create targeted marketing campaigns that reach your ideal customers at the right time and place. Whether it's through social media, email marketing, or search engine optimization, we ensure that your message resonates with your audience.</p>
            </div>
            <div>
              <h3 className='font-20-res-16 weight-600'>Sales Optimization</h3>
              <p className='font-18-res-16 weight-400'>Maximizing your sales potential requires more than just a great product or service. We help you optimize your sales processes, streamline your funnel, and implement effective sales tactics to convert leads into paying customers.</p>
            </div>
            <div>
              <h3 className='font-20-res-16 weight-600'>Continuous Monitoring and Optimization</h3>
              <p className='font-18-res-16 weight-400'>The key to success in marketing and sales is constant evolution. That's why we don't just set and forget. We continuously monitor the performance of your campaigns, analyze the data, and make necessary adjustments to ensure that you're always getting the best results.</p>
            </div>
          </div>

          <h2 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>Objectives of providing Marketing and Sales Strategy Service</h2>
          <div className='mb-5'>
          <DiscoverCard title="Drive Growth" paragraph="Our primary objective is to help your business grow. Whether you're looking to increase revenue, expand your customer base, or enter new markets, we're dedicated to helping you achieve your growth objectives." />
          <DiscoverCard title="Deliver Results" paragraph="We are committed to delivering tangible results that have a real impact on your bottom line. Our team works tirelessly to ensure that every strategy we implement generates measurable outcomes that drive success." />
          <DiscoverCard title="Build Long-Term Relationships" paragraph="At Bizz Buzz Creation, we believe in building long-term relationships with our clients. We strive to be more than just a service provider but a trusted partner that is invested in your success." />
          <br/>
          <DiscoverCard title="" paragraph="Prepared to take your showcasing and deals endeavors to a higher level? Reach us today to look further into how our administrations can help your business!" />
        </div>
      </div>
      </div> */}
    </>
  )
}

export default BusinessConsulting