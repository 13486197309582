import React from 'react';
import './CaseStudyCard.css';
import { MdOutlineFileDownload } from "react-icons/md";
import { Img } from 'react-image';

const CaseStudyCard = ({ image, title, description, download, bgHex }) => {
  return (
    <div className="case-study-card" style={{background: bgHex }}>
      <div className="image-container">
        <Img src={image} alt={title} />
      </div>
      <div className="content-containercase">
        <h3 className='weight-600 text-left'>{title}</h3>
        <p className='text-left weight-400'>{description}</p>
        <a href={download} target='_black' className='text-decoration-none w-fit'><button className='d-flex justify-content-center align-items-center gap-2 p-2 px-3 download-btn'><MdOutlineFileDownload size={20} />Download</button></a>
      </div>
    </div>
  );
};

export default CaseStudyCard;