import React from 'react';
import PropTypes from 'prop-types';
import './Carousel.css'

const ExampleCarouselImage = ({ imgUrl, text }) => {
  return (
    <div className='img-carousel-container'>
      <img
        className="d-block w-100"
        src={imgUrl}
        alt={text}
      />
    </div>
  );
};

ExampleCarouselImage.propTypes = {
  text: PropTypes.string.isRequired,
};

export default ExampleCarouselImage;
