import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client'; // Use ReactDOM from react-dom/client
import App from './App';

const rootElement = document.getElementById('root');

// Check if the page has pre-rendered content (SSR)
if (rootElement.hasChildNodes()) {
  // Use hydrateRoot for SSR in React 18
  const root = ReactDOM.hydrateRoot(rootElement, (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  ));
} else {
  // Use createRoot for normal client-side rendering in React 18
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}
