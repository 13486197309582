import React from 'react'
import "./About.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import CustmerCard from '../../Component/Custmercard/CustmerCard';
import expert from "../../Assets/expert.png"
import innovation from "../../Assets/innovation.png"
import proven from "../../Assets/proven.png"
import strategic from "../../Assets/stratge.png"
import improvement from "../../Assets/improvement.png"
import ethical from "../../Assets/ethical.png"
import client from "../../Assets/client.png"
import { Helmet } from 'react-helmet';
import ControlledCarousel from '../../Component/Carousel/ControlledCarousel';


const About = () => {
  return (
    <>
      <Helmet>
        <title>Creative Solutions for Modern Businesses at Bizz Buzz Creations</title>
        <meta
          name="description"
          content="Bizz Buzz Creations offers innovative and impactful solutions to help modern businesses grow, thrive, and stay ahead in a competitive market."
        />
        <meta
          name="keywords"
          content="web development services in prayagraj, best website development in prayagraj, SMM services in prayagraj, best SMM services in prayagraj, digital marketing services and agency in prayagraj, best digital marketing agency in prayagraj, digital marketing in prayagraj, cloud computing services in prayagraj, financial advisory services in prayagraj, BPO Outsourcing services in Prayagraj"
        />
        <meta name="author" content="BizzBuzzCreations" />
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-XVSSY0BR1E"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-XVSSY0BR1E');
        `}
        </script>
      </Helmet>
      <div className='about'>
        <div className='about_portion'>
          {/* <div className='video_header'>
          <video autoPlay muted loop id="myVideo">
            <source src={aboutVideo} type="video/mp4" />
            Your browser does not support HTML5 video.
          </video>
        </div> */}
          <ControlledCarousel />
        </div>
        <div className='creation_section'>
          <div className='container'>
            <h2 className='mt-5 mb-4 text-center weight-600'>Welcome to Bizz Buzz Creation.</h2>
            <p className='about-para w-80 m-auto mb-3 mt-2 weight-400'> A hub where innovation intertwines with excellence! For nearly a year, we've been devoted to empowering businesses with a comprehensive suite of services engineered to propel them towards success. With a team of seasoned experts steering our ship, we've been pioneering solutions that redefine industry standards and foster sustainable growth.</p>

            <p className='about-para w-80 m-auto mb-3 mt-2 weight-400'>At Bizz Buzz Creation, we grasp the dynamic nature of modern business, and our diverse range of services reflects just that. Whether you're a startup aiming to carve out your online presence or an established enterprise striving to ascend to new heights, we've got you covered.</p>
          </div>
        </div>
        <div className='custmer_portion container'>
          <h2 className='mt-5 mb-4 text-center weight-600'>Customer Delight</h2>
          <p className='about-para w-80 m-auto mb-3 mt-2 weight-400'>At Bizz Buzz Creation, we're not just service providers; we're partners in your journey towards success. With our unwavering commitment to excellence and innovation, we're here to help you thrive in an ever-evolving business landscape. Reach out to us today, and we should transform your vision into the real world!</p>
        </div>
        <div className='custmer_grid container mt-50'>
          <CustmerCard title={"Expert Team"} Imageurl={expert} />
          <CustmerCard title={"Innovative Solutions"} Imageurl={innovation} />
          <CustmerCard title={"Proven Track Record"} Imageurl={proven} />
          <CustmerCard title={"Strategic Partnerships"} Imageurl={strategic} />
          <CustmerCard title={"Ceaseless Improvement"} Imageurl={improvement} />
          <CustmerCard title={"Ethical Practice"} Imageurl={ethical} />
          <CustmerCard title={"Client-Centric Approach"} Imageurl={client} />

        </div>
        <h2 className='mt-5 pb-5 m-auto w-45 weight-600 text-center about-last-para'>Reimagining norms, <br /> while honoring boundaries.</h2>
      </div>
    </>
  )
}

export default About