// import logo from "./logo.svg";
// import "./App.css";
// import MainPage from "./Pages/MainPage/MainPage";
// import Footer from "./Component/footer/Footer";
// import { Route, Routes, Router } from "react-router-dom";
// import About from "./Pages/About/About";
// import Contact from "./Pages/contact/Contact";
// import CaseStudies from "./Pages/CaseStudy/CaseStudies";
// import BBCService from "./Pages/BBCService/BBCService";
// import Blog from "./Pages/Blog/Blog";
// import WebDevelopment from "./Pages/webdevelopement/WebDevelopment";
// import DigitalMarket from "./Pages/DigitalMarket/DigitalMarket";
// import HRPage from "./Pages/HumanResourse/HRPage";
// import TechSupport from "./Pages/TechSupport/TechSupport";
// import CloudComputing from "./Pages/CloudComputing/CloudComputing";
// import Financial from "./Pages/FinancialPage/Financial";
// import BusinessConsulting from "./Pages/BussinessConsulting/BussinessConsulting";
// import BusinessNetworking from "./Pages/BussinessNetworking/BussinessNetworking";
// import Navbar from "./Component/Navbar/Navbar";
// import OfficeSpace from "./Pages/OfficeSpace/OfficeSpace";
// import ITInfrastructure from "./Pages/ITmanagement/Itmanagement";
// import TermsandConditions from "./Pages/TermandCondition/TermAndCondition";
// import PrivacyPolicies from "./Pages/privacy/Privacy";
// import ScrollToTop from "./Component/Scroll/ScrollToTop";

// function App() {
//   return (
//     <div className="App">
//       <Navbar />
//       <Router>
//         <ScrollToTop />
//         <Routes>
//           <Route path="/" element={<MainPage />} />
//           <Route path="/about" element={<About />} />
//           <Route path="/contact" element={<Contact />} />
//           <Route path="/casestudy" element={<CaseStudies />} />
//           <Route path="/service" element={<BBCService />} />
//           <Route path="/blog" element={<Blog />} />
//           <Route path="/webdevelpment" element={<WebDevelopment />} />
//           <Route path="/DigitalMarket" element={<DigitalMarket />} />
//           <Route path="/HR" element={<HRPage />} />
//           <Route path="/techsupport" element={<TechSupport />} />
//           <Route path="/cloudComputing" element={<CloudComputing />} />
//           <Route path="/Financial" element={<Financial />} />
//           <Route path="/BussinessConsulting" element={<BusinessConsulting />} />
//           <Route path="/BussinessNetworking" element={<BusinessNetworking />} />
//           <Route path="/OfficeSpace" element={<OfficeSpace />} />
//           <Route path="/ITInfrastructure" element={<ITInfrastructure />} />
//           <Route path="/TermandCondition" element={<TermsandConditions />} />
//           <Route path="/PrivacyPolices" element={<PrivacyPolicies />} />
//         </Routes>
//       </Router>
//       <Footer />
//     </div>
//   );
// }

// export default App;

import logo from "./logo.svg";
import "./App.css";
import MainPage from "./Pages/MainPage/MainPage";
import Footer from "./Component/footer/Footer";
import { Route, Routes } from "react-router-dom"; // No need for BrowserRouter here
import About from "./Pages/About/About";
import Contact from "./Pages/contact/Contact";
import CaseStudies from "./Pages/CaseStudy/CaseStudies";
import BBCService from "./Pages/BBCService/BBCService";
import Blog from "./Pages/Blog/Blog";
import WebDevelopment from "./Pages/webdevelopement/WebDevelopment";
import DigitalMarket from "./Pages/DigitalMarket/DigitalMarket";
import HRPage from "./Pages/HumanResourse/HRPage";
import TechSupport from "./Pages/TechSupport/TechSupport";
import CloudComputing from "./Pages/CloudComputing/CloudComputing";
import Financial from "./Pages/FinancialPage/Financial";
import BusinessConsulting from "./Pages/BussinessConsulting/BussinessConsulting";
import BusinessNetworking from "./Pages/BussinessNetworking/BussinessNetworking";
import Navbar from "./Component/Navbar/Navbar";
import OfficeSpace from "./Pages/OfficeSpace/OfficeSpace";
import ITInfrastructure from "./Pages/ITmanagement/Itmanagement";
import TermsandConditions from "./Pages/TermandCondition/TermAndCondition";
import PrivacyPolicies from "./Pages/privacy/Privacy";
import ScrollToTop from "./Component/Scroll/ScrollToTop";
import { Helmet } from "react-helmet";
import Stripband from "./Component/stripband/Stripband";
import Careers from "./Pages/Careers/Careers";

function App() {
  return (
    <div className="App">
      <Stripband/>
      <Navbar />
      <Helmet>
        <title>BizzBuzz Creations | Innovative & Integrate Everyday</title>
        <meta
          name="description"
          content="BizzBuzz Creations | Innovative & Integrated Everyday - Expert in Web Development, Digital Marketing, Financial Advisory, IT Management, and Corporate Solutions."
        />
        <meta name="slug" content="bizzbuzzcreations/home/" />
        <meta
          name="keywords"
          content="Web development services in prayagraj, Web development in prayagraj, Best website development in prayagraj, Best web development services in allahabad, Web development in allahabad, SEO services in prayagraj, Best seo services in prayagraj, SMM services in prayagraj, Best Smm services in prayagraj, digital marketing services and agency in prayagraj, digital marketing services and agency in allahabad, best digital marketing agency in allahabad ,best digital marketing agency in prayagraj, digital marketing in allahabad, digital marketing in prayagraj, cloud solutions services in prayagraj, cloud technologies services in prayagraj , cloud computing services in prayagraj, cloud architecture services in prayagraj, cloud workloads services in prayagraj, cloud optimization services in prayagraj, cloud management services in prayagraj, cloud migration services in prayagraj, cloud telemetry services in prayagraj, cloud orchestration services in prayagraj, cloud infrastructure services in prayagraj, cloud instances services in prayagraj, financial advisory services, Debt Management and Comprehensive Financial Reviews, Wealth Building and Preservation advisory, Infrastructure management in India, Business Management in India, Security and Compliances management, Growth & Innovation management, corporate event management companies in prayagraj, top 10 event management company in india, management company in india, top 10 corporate travel management companies in india, BPO service solutions, BPO Outsourcing services in india, Outbound Call Centre services in india, Human outsourcing services in india"
        />
        <meta name="author" content="BizzBuzzCreations" />
      </Helmet>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/casestudy" element={<CaseStudies />} />
        <Route path="/service" element={<BBCService />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/webdevelpment" element={<WebDevelopment />} />
        <Route path="/DigitalMarket" element={<DigitalMarket />} />
        <Route path="/HR" element={<HRPage />} />
        <Route path="/techsupport" element={<TechSupport />} />
        <Route path="/cloudComputing" element={<CloudComputing />} />
        <Route path="/Financial" element={<Financial />} />
        <Route path="/BussinessConsulting" element={<BusinessConsulting />} />
        <Route path="/BussinessNetworking" element={<BusinessNetworking />} />
        <Route path="/OfficeSpace" element={<OfficeSpace />} />
        <Route path="/ITInfrastructure" element={<ITInfrastructure />} />
        <Route path="/TermandCondition" element={<TermsandConditions />} />
        <Route path="/PrivacyPolices" element={<PrivacyPolicies />} />
        <Route path="/careers" element={<Careers />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
