import React from "react";
import UncoverCard from "../../Component/UncoverCard/UncoverCard";
import custom from "../../Assets/FA2.png"
import webImage from "../../Assets/image_main/Financial advisory.jpg";
import FA3 from "../../Assets/FA3.png"
import DiscoverCard from "../../Component/DiscoverCard/DiscoverCard";
import f1 from "../../Assets/Goal Setting and achievement.jpg"
import f2 from "../../Assets/Wealth building and preservation.jpg"
import f3 from "../../Assets/Debt management and comprehensive Financial reviews.webp"
import f4 from "../../Assets/Education and empowerment.avif"
import DiscoverCardCenter from "../../Component/DiscoverCardCenter/DiscoverCardCenter";
import Goals from "../../Component/Goals/Goals";
import Investment_Management from "../../Assets/icon/investment management.jpg"
import Retirement_Planning from "../../Assets/icon/retirement planning.jpg"
import Tax_Planning from "../../Assets/icon/tax planning.jpg"
import Estate_Planning from "../../Assets/icon/estate planning.jpg"
import Risk_Management from "../../Assets/icon/risk management.jpg"
import Cash_Flow_Management from "../../Assets/icon/cash flow management.jpg"
import Debt_Management from "../../Assets/icon/debt management.jpg"
import Financial_Education from "../../Assets/icon/financial education.jpg"
import Business_Financial_Advisory from "../../Assets/icon/business financial advisory.jpg"
import Expertise_and_Experience from "../../Assets/icon/expertise and experience.jpg"
import Relevant_Solutions from "../../Assets/icon/relevant solution.jpg"
import Comprehensive_Services from "../../Assets/icon/comprehensive services.jpg"
import Transparent_Communication from "../../Assets/icon/transparent communication.jpg"
import Proactive_Support from "../../Assets/icon/proactive solutions.jpg"
import Commitment_to_Your_Success from "../../Assets/icon/commitment to your success.jpg"
import Maximize_Client_Wealth from "../../Assets/image/maximize client wealth.jpg"
import Achieve_Financial_Goals from "../../Assets/image/achieve financial goals.jpg"
import Mitigate_Financial_Risks from "../../Assets/image/mitigate financial risks.jpg"
import Optimize_Cash_Flow from "../../Assets/image/optimize cash flow and budgeting.jpg"
import Provide_Informed from "../../Assets/image/provide informed decision-making.jpg"
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";



function Financial() {
  return (

    <>
      <Helmet>
        <title>Supporting Financial Futures: Your Guide to Strategic Advisory Services</title>
        <meta
          name="description"
          content="Unlock your financial potential with our strategic advisory services, guiding you toward sustainable growth and informed investment decisions."
        />
        <meta
          name="keywords"
          content="web development services in prayagraj, best website development in prayagraj, SMM services in prayagraj, best SMM services in prayagraj, digital marketing services and agency in prayagraj, best digital marketing agency in prayagraj, digital marketing in prayagraj, cloud computing services in prayagraj, financial advisory services in prayagraj, BPO Outsourcing services in Prayagraj"
        />
        <meta name="author" content="BizzBuzzCreations" />
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-XVSSY0BR1E"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-XVSSY0BR1E');
        `}
        </script>
      </Helmet>
      <div className="webdevelpment_page d-flex align-items-center justify-content-center text-center flex_page">
        {/* <div className="image_portion_web img-width">
          <img src={webImage} alt="image" className="image_main" />
        </div> */}
        <div className="content_portion p-15">
          <h2 className="weight-600 mb-3">Financial Advisory Services</h2>
          <h5 className="weight-600">
            Welcome to Bizz Buzz Creation, your premier destination for expert
            financial advisory services.
          </h5>
          <p className="weight-400 mb-5">
            Financial advisory services help individuals and businesses manage their finances effectively, providing guidance on investments, retirement planning, tax strategies, estate planning, and risk management to achieve financial goals and security.
          </p>
          <button className="get_button"><Link className="text-decoration-none" to="/contact">Get Started</Link></button>
        </div>
      </div>
      <div className='web_portion'>
        <div className='head_portion'>
          <h1 className='font-20-res-16 weight-600 text-center mb-5'>Financial Advisory Services We Offer</h1>
        </div>
        <div className='grid_portion'>
          <DiscoverCardCenter icon_image={Investment_Management} title="Investment Management" paragraph="Investment management involves creating and overseeing investment portfolios tailored to clients' financial goals. It includes asset allocation, risk assessment, and continuous monitoring to optimize returns and mitigate risks." />
          <DiscoverCardCenter icon_image={Retirement_Planning} title="Retirement Planning" paragraph="Retirement planning involves creating a comprehensive strategy to secure financial stability in retirement, including savings, investments, and asset management, ensuring individuals can maintain their desired lifestyle post-employment." />
          <DiscoverCardCenter icon_image={Tax_Planning} title="Tax Planning" paragraph="Tax planning involves strategizing financial decisions to minimize tax liabilities while ensuring compliance with tax laws. It includes analyzing income, deductions, credits, and investments to optimize overall tax efficiency." />
          <DiscoverCardCenter icon_image={Estate_Planning} title="Estate Planning" paragraph="Estate planning involves preparing for the management and distribution of an individual's assets after their death, ensuring wishes are honored, minimizing taxes, and providing for loved ones through wills and trusts." />
          <DiscoverCardCenter icon_image={Risk_Management} title="Risk Management" paragraph="Risk management involves identifying, assessing, and prioritizing risks to minimize their impact on an organization. It includes strategies for mitigation, monitoring, and ensuring compliance with regulations and standards." />
          <DiscoverCardCenter icon_image={Cash_Flow_Management} title="Cash Flow Management" paragraph="Cash flow management involves monitoring and optimizing a business's cash inflows and outflows to ensure sufficient liquidity, support operational needs, and maintain financial stability for growth and sustainability." />
          <DiscoverCardCenter icon_image={Debt_Management} title="Debt Management" paragraph="Debt management involves strategies and practices to help individuals or businesses effectively manage and reduce their debt. This includes budgeting, negotiating terms, consolidating loans, and prioritizing repayments to achieve financial stability." />
          <DiscoverCardCenter icon_image={Financial_Education} title="Financial Education" paragraph="Financial education empowers individuals with knowledge about budgeting, saving, investing, and managing debt. It fosters informed decision-making, enhances financial literacy, and promotes long-term financial stability and security." />
          <DiscoverCardCenter icon_image={Business_Financial_Advisory} title="Business Financial Advisory" paragraph="Business financial advisory services provide expert guidance on financial planning, budgeting, investment strategies, and risk management, helping organizations optimize their financial performance, ensure compliance, and achieve sustainable growth." />
          {/* <DiscoverCardCenter title="" paragraph=""/>
        <DiscoverCardCenter title="" paragraph=""/>
        <DiscoverCardCenter title="" paragraph=""/> */}
        </div>

      </div>
      <div className='incover_portion pb-5'>
        <h1 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>Objectives for Financial Advisory</h1>
        <div className='grid_portion m-auto align-items-center justify-content-center '>
          <UncoverCard image_uncover={Maximize_Client_Wealth} title="Maximize Client Wealth" paragraph="Develop personalized strategies to help clients grow and preserve their wealth through effective investment, tax planning, and risk management." />
          <UncoverCard image_uncover={Achieve_Financial_Goals} title="Achieve Financial Goals" paragraph=" Assist clients in defining and achieving short-term and long-term financial goals, such as retirement planning, buying a home, or funding education." />
          <UncoverCard image_uncover={Mitigate_Financial_Risks} title="Mitigate Financial Risks" paragraph="Identify and manage potential risks through proper asset allocation, diversification, and insurance strategies, protecting clients from unforeseen financial challenges." />
          <UncoverCard image_uncover={Optimize_Cash_Flow} title="Optimize Cash Flow and Budgeting" paragraph="Help clients improve their financial health by creating structured budgets, optimizing cash flow, and ensuring efficient use of their resources." />
          <UncoverCard image_uncover={Provide_Informed} title="Provide Informed Decision-Making" paragraph="Empower clients to make informed financial decisions by offering expert insights, regular reviews of their financial position, and keeping them updated on market trends and regulations." />
          {/* <UncoverCard image_uncover={""} title="" paragraph=""/> */}
        </div>

      </div>
      <div className='Page'>
        <h1 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>Why Choose BBC for Financial Advisory Services?</h1><div className="App">
          <div className="card-container container">
            <Goals logo_icon={Expertise_and_Experience} title="Expertise and Experience" paragraph="BBC's expert financial advisors provide deep knowledge and experience, helping you make informed decisions that align with your business goals for optimal success." />
            <Goals logo_icon={Relevant_Solutions} title="Relevant Solutions" paragraph="We provide tailored financial strategies to meet your business's unique needs and challenges, ensuring long-term financial health, stability, and growth for your success." />
            <Goals logo_icon={Comprehensive_Services} title="Comprehensive Services" paragraph="Our holistic approach covers all aspects of financial advisory, including investment management, tax planning, and risk assessment, providing a one-stop solution for your financial needs." />
            <Goals logo_icon={Transparent_Communication} title="Transparent Communication" paragraph="We prioritize clear communication, keeping you informed and engaged throughout every step of the financial advisory process, ensuring transparency and your active involvement at all times." />
            <Goals logo_icon={Proactive_Support} title="Proactive Support" paragraph="BBC offers continuous support and monitoring, ensuring timely adjustments to strategies as market conditions or business developments evolve, keeping your goals on track." />
            <Goals logo_icon={Commitment_to_Your_Success} title="Commitment to Your Success" paragraph="Our advisors are dedicated to helping you reach financial goals, focusing on long-term success and sustainable growth for your business's continued prosperity." />
          </div>
        </div>
      </div>


      {/* <div style={{ background: "#d4dfe3", padding: "0 0 20px 0" }}>
        <div className="container">
          <div className="d-flex gap-5 align-items-center pt-5 flex_page">
            <div className="img-width hr_hero">
            <img src={custom} alt="ecomm website" className="imagehr"/>
            </div>
            <div className="text-start">
              <h2 className="font-32-res-22 weight-600  text-center mt-5 mb-50">Business Financial Advisory</h2>
              <p className="font-18-res-16 weight-400">
                Welcome to Bizz Buzz Creation, your trusted partner for
                comprehensive Business Financial Advisory Services. In today's
                dynamic business landscape, navigating the complexities of
                finance and maximizing your financial potential requires
                expertise and strategic insight. That's where we come in.
              </p>
            </div>
          </div>
          <h2 className="font-32-res-22 weight-600  text-center mt-5 mb-50">
            Why Choose BBC for Business Financial Advisory ?
          </h2>
          <DiscoverCard
            title="Expertise and Experience"
            paragraph="BBC’s team of seasoned financial advisors brings extensive knowledge and experience, ensuring informed decisions that align with your business goals."
          />
          <DiscoverCard
            title="Relevant Solutions"
            paragraph="We offer customized financial strategies that cater to the unique needs and challenges of your business, ensuring optimal financial health and growth."
          />
          <DiscoverCard
            title="Comprehensive Services"
            paragraph=" Our holistic approach covers all aspects of financial advisory, including investment management, tax planning, and risk assessment, providing a one-stop solution for your financial needs."
          />
          <DiscoverCard
            title="Transparent Communication"
            paragraph="We prioritize clear and open communication, ensuring you are well-informed and engaged in every step of the financial advisory process."
          />
          <DiscoverCard
            title="Proactive Support"
            paragraph="BBC provides ongoing support and monitoring, allowing for timely adjustments to strategies in response to market changes or business developments."
          />
          <DiscoverCard
          title="Commitment to Your Success"
          paragraph="Our advisors are dedicated to helping you achieve your financial objectives, focusing on long-term success and sustainability for your business."
          />

          <h2 className="font-32-res-22 weight-600  text-center mt-5 mb-50">
            How our Business Financial Advisory Can Help You ?
          </h2>
          <div
          className="grid_portion"
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: "10px",
              textAlign: "center",
            }}
          >
            <div>
              <h3 className='font-20-res-16 weight-600'>
                Optimize Financial Performance and Strategic Decision-Making
              </h3>
              <p className='font-18-res-16 weight-400'>
                By leveraging our expertise, you can optimize your financial
                performance, streamline operations, and enhance profitability.
                Our data-driven insights and strategic recommendations empower
                you to make informed decisions that drive sustainable growth and
                competitive advantage.
              </p>
            </div>
            <div>
              <h3 className='font-20-res-16 weight-600'>Enhanced Efficiency</h3>
              <p className='font-18-res-16 weight-400'>
                We identify inefficiencies in your financial processes and
                implement streamlined solutions to improve operational
                efficiency and reduce costs.
              </p>
            </div>
            <div>
              <h3 className='font-20-res-16 weight-600'>Risk Mitigation</h3>
              <p className='font-18-res-16 weight-400'>
                Our proactive approach to risk management helps you identify and
                mitigate potential risks, protecting your business from
                financial setbacks and ensuring continuity.
              </p>
            </div>
            <div>
              <h3 className='font-20-res-16 weight-600'>Peace of Mind</h3>
              <p className='font-18-res-16 weight-400'>
                With Bizz Buzz Creation by your side, you can focus on your core
                business activities with confidence, knowing that your financial
                affairs are in capable hands.
              </p>
            </div>
          </div>

          <h2 className="font-32-res-22 weight-600  text-center mt-5 mb-50">
            Goal of Providing Business Financial Advisory Services:
          </h2>
          <DiscoverCard
            title="Client Empowerment"
            paragraph="Our primary objective is to empower our clients with the knowledge, resources, and support they need to achieve their financial goals and realize their full potential."
          />
          <DiscoverCard
            title="Long-Term Partnerships"
            paragraph="We strive to build long-term partnerships based on trust, integrity, and mutual success. By understanding your unique needs and aspirations, we aim to become your trusted advisor throughout your business journey."
          />
          <DiscoverCard
            title="Value Creation"
            paragraph="We are committed to delivering tangible value to our clients by offering innovative solutions, actionable insights, and measurable results that drive growth and prosperity."
          />
          <DiscoverCard
            title="Continuous Improvement"
            paragraph="We continuously evolve and adapt our services to align with changing market dynamics, emerging trends, and evolving client needs. Our goal is to stay ahead of the curve and remain at the forefront of financial innovation."
          />
          <DiscoverCard
            title=""
            paragraph="At Bizz Buzz Creation, we are dedicated to helping you unlock your full financial potential and achieve lasting success. Contact us today to learn more about how our Business Financial Advisory Services can benefit your business."
          />
        </div>
      </div>

      <div style={{ background: "#EEEEEE", padding: "0 0 20px 0" }}>
        <div className="container">
          <div className="d-flex gap-5 align-items-center pt-5 flex_page">
          <div className="img-width ">
            <img src={FA3} alt="ecomm website" className="image" />
            </div>
            <div className="text-start">
              <h2 className="font-32-res-22 weight-600  text-center mt-5 mb-50">Risk Management</h2>
              <p className="font-18-res-16 weight-400">
                Welcome to Bizz Buzz Creation, your partner in navigating the
                complexities of risk management. In today's dynamic business
                environment, effective risk management is essential for
                mitigating threats and seizing opportunities. Our comprehensive
                risk management services are tailored to your specific needs,
                offering strategic guidance and proactive solutions to safeguard
                your business. Here’s why our services are indispensable, how
                they can benefit you, and our objectives in providing them.
              </p>
            </div>
          </div>
          <h2 className="font-32-res-22 weight-600  text-center mt-5 mb-50">
            Why Choose BBC for Risk Management Services ?
          </h2>
          <DiscoverCard
            title="Identification of Risks"
            paragraph="We conduct thorough risk assessments to identify potential threats to your business, including financial, operational, strategic, and regulatory risks. By understanding the full spectrum of risks, we can develop targeted mitigation strategies."
          />
          <DiscoverCard
            title="Proactive Risk Mitigation"
            paragraph="Our proactive approach to risk management enables us to anticipate and mitigate potential threats before they escalate into crises. We implement robust risk mitigation strategies to protect your business assets and reputation."
          />
          <DiscoverCard
            title="Enhanced Decision Making"
            paragraph="With our risk management services, you can make more informed and strategic decisions. We provide data-driven insights and analysis to help you evaluate risks and choose the best course of action for your business."
          />
          <DiscoverCard
            title="Compliance and Governance"
            paragraph="We ensure that your business complies with relevant laws, regulations, and industry standards. Our risk management services help you maintain regulatory compliance and adhere to best practices, reducing the risk of legal issues and penalties."
          />
        

          <h2 className="font-32-res-22 weight-600  text-center mt-5 mb-50">
            How Our Business Financial Advisory services Can Help You ?
          </h2>
          <div
            className="grid_portion"
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
         
              gap: "10px",
              textAlign: "center",
            }}
          >
            <div>
              <h3 className='font-20-res-16 weight-600'>Protection of Assets</h3>
              <p className='font-18-res-16 weight-400'>
                Our risk management services protect your business assets,
                including financial resources, intellectual property, and
                physical infrastructure. By identifying and mitigating risks, we
                safeguard your valuable assets from potential harm.
              </p>
            </div>
            <div>
              <h3 className='font-20-res-16 weight-600'>Minimization of Losses</h3>
              <p className='font-18-res-16 weight-400'>
                Effective risk management minimizes the likelihood and impact of
                losses on your business. We help you implement risk mitigation
                strategies that reduce financial losses, operational
                disruptions, and reputational damage.
              </p>
            </div>
            <div>
              <h3 className='font-20-res-16 weight-600'>Enhanced Stakeholder Confidence</h3>
              <p className='font-18-res-16 weight-400'>
                By demonstrating a proactive approach to risk management, you
                can enhance stakeholder confidence in your business. Our
                services help you build trust with customers, investors, and
                other stakeholders by showing your commitment to sound risk
                management practices.
              </p>
            </div>
            <div>
              <h3 className='font-20-res-16 weight-600'>Opportunity Identification and Continuous Improvement</h3>
              <p className='font-18-res-16 weight-400'>
                Risk management isn't just about avoiding threats; it's about
                seizing opportunities for growth, innovation, and competitive
                advantage while managing associated risks. We help you identify
                and capitalize on these opportunities. Our services evolve with
                your business, continuously monitoring and reassessing risks,
                and adapting strategies to changing circumstances and emerging
                threats to ensure ongoing protection and resilience.
              </p>
            </div>
          </div>

          <h2 className="font-32-res-22 weight-600  text-center mt-5 mb-50">
            Goal of Providing Risk Management Services
          </h2>
          <div className='mb-5'>
          <DiscoverCard
            title="Protecting Business Value"
            paragraph="Our primary objective is to protect the value of your business by minimizing risks and maximizing opportunities. We aim to preserve your business assets, reputation, and competitive position in the market."
          />
          <DiscoverCard
            title="Promoting Long-term Sustainability"
            paragraph="We strive to promote the long-term sustainability of your business by fostering a culture of risk awareness and proactive risk management. Our services help you build resilience and adaptability to navigate challenges and uncertainties."
          />
          <DiscoverCard
            title="Ensuring Business Continuity"
            paragraph="We aim to ensure the continuity of your business operations, even in the face of disruptions or crises. Our risk management services include robust business continuity planning to minimize downtime and maintain productivity."
          />
          <DiscoverCard
            title="Facilitating Growth and Innovation"
            paragraph="Risk management shouldn't stifle growth and innovation; it should enable them. Our services are designed to support your business growth initiatives while managing associated risks effectively."
          />
          <DiscoverCard
            title="Building Trust and Confidence"
            paragraph="Ultimately, our objective is to build trust and confidence in your business among stakeholders. By providing comprehensive risk management services, we demonstrate your commitment to responsible governance, risk mitigation, and ethical business practices."
          />
          <br/>
          <DiscoverCard
            title=""
            paragraph="At Bizz Buzz Creation, we understand the critical importance of risk management in today's uncertain business environment. Our dedicated team is here to provide you with the guidance and support you need to navigate risks effectively and achieve your business objectives."
          />
          <br/>
          <DiscoverCard
            title=""
            paragraph="Contact us today to learn more about our risk management services and how we can help protect and strengthen your business."
          />
        </div>
      </div>
      </div> */}
    </>
  );
}

export default Financial;
