import React from 'react'
import './Contact.css'
import ContactForm from '../../Component/Contactform/ContactForm'
import { Helmet } from 'react-helmet'


function Contact() {
  return (
    <>
      <Helmet>
        <title>Get in Touch with Bizz Buzz Creations (BBC)</title>
        <meta
          name="description"
          content="Reach out to Bizz Buzz Creations for inquiries, support, or collaborations. We’re here to assist you with your business needs."
        />
        <meta name="slug" content="contact-bizzbuzz-creations-prayagraj" />
        <meta
          name="keywords"
          content="web development services in prayagraj, best website development in prayagraj, SMM services in prayagraj, best SMM services in prayagraj, digital marketing services and agency in prayagraj, best digital marketing agency in prayagraj, digital marketing in prayagraj, cloud computing services in prayagraj, financial advisory services in prayagraj, BPO Outsourcing services in Prayagraj"
        />
        <meta name="author" content="BizzBuzzCreations" />
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-XVSSY0BR1E"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-XVSSY0BR1E');
        `}
        </script>
      </Helmet>
      <div className='contact-body'>
        <div className='map'>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3602.412587460502!2d81.83404617539034!3d25.457895077544126!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xcb3c0329996b1%3A0xced1e0729b594882!2sBizzBuzz%20Creations!5e0!3m2!1sen!2sin!4v1730875313146!5m2!1sen!2sin" width="100%" height="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
        <div className='p-contact-container'>
          <ContactForm />
        </div>
      </div>
    </>
  )
}

export default Contact