import React from 'react'
import './BBCCard.css'

function BBCCard({title, paragraph}) {
  return (
    <div className='bbc-card'>
      <h4 className='title text-center weight-600 wt-s pi-5'>{title}</h4>
      <p className='desc text-left font-family-text'>{paragraph}</p>
    </div>
  )
}

export default BBCCard