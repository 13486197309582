// import React, { useEffect } from 'react';
// import './Careers.css';

// const Careers = () => {
//   useEffect(() => {
//     // Select the iframe element after it is rendered
//     const iframe = document.getElementById('careersIframe');
    
//     // Check if the iframe is loaded
//     iframe.onload = function() {
//       // Access the content inside the iframe
//       const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
      
//       // Select the <a> tag inside the iframe
//       const link = iframeDocument.querySelector('a');
      
//       // If the <a> tag exists, hide it
//       if (link) {
//         link.style.display = 'none'; // Hide the <a> tag
//       }
//     };

//   }, []); // Empty dependency array means this runs once after the component mounts

//   return (
//     <>
//       <div className='horilla-container'>
//         <iframe 
//           className='horilla-iframe h-100' 
//           id="careersIframe" 
//           src="https://hrms.bizzbuzzcreations.com/recruitment/open-recruitments" 
//           width="100%" 
//           frameBorder="0"
//         ></iframe>
//       </div>
//     </>
//   );
// };

// export default Careers;


import React, { useEffect } from 'react';
import './Careers.css';

const Careers = () => {
  useEffect(() => {
    // Select the iframe element after it is rendered
    const iframe = document.getElementById('careersIframe');
    
    // Send a message to the iframe once it is loaded
    iframe.onload = function() {
      iframe.contentWindow.postMessage('hideLink', '*'); // Message to hide the <a> tag
    };
  }, []);

  return (
    <>
      <div className='horilla-container'>
        <iframe 
          className='horilla-iframe h-100' 
          id="careersIframe" 
          src="https://hrms.bizzbuzzcreations.com/recruitment/open-recruitments" 
          width="100%" 
          frameBorder="0"
        ></iframe>
      </div>
    </>
  );
};

export default Careers;
