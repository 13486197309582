import React from 'react'
import "../../Component/blockcard/blockcard.css"

const BlockCard = ({ className = '' ,tagline }) => {
  return (
    <div className={`blockcard ${className}`}>
        <h4 className='mb-0 text-center weight-600'>{tagline}</h4>
        </div>
  )
}

export default BlockCard