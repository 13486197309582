export const Linkedin = () => {
  return (
    <svg width="45" height="45" viewBox="0 0 21 20" color="black" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.6605 2.5C17.1023 2.5 17.5259 2.67559 17.8382 2.98816C18.1506 3.30072 18.3261 3.72464 18.3261 4.16667V15.8333C18.3261 16.2754 18.1506 16.6993 17.8382 17.0118C17.5259 17.3244 17.1023 17.5 16.6605 17.5H5.00194C4.56022 17.5 4.13659 17.3244 3.82424 17.0118C3.5119 16.6993 3.33643 16.2754 3.33643 15.8333V4.16667C3.33643 3.72464 3.5119 3.30072 3.82424 2.98816C4.13659 2.67559 4.56022 2.5 5.00194 2.5H16.6605ZM16.2442 15.4167V11C16.2442 10.2795 15.9581 9.5885 15.449 9.07903C14.9399 8.56955 14.2494 8.28333 13.5294 8.28333C12.8215 8.28333 11.9971 8.71667 11.5974 9.36667V8.44167H9.27398V15.4167H11.5974V11.3083C11.5974 10.6667 12.1137 10.1417 12.7549 10.1417C13.0641 10.1417 13.3607 10.2646 13.5793 10.4834C13.7979 10.7022 13.9208 10.9989 13.9208 11.3083V15.4167H16.2442ZM6.56752 7.13333C6.93857 7.13333 7.29442 6.98583 7.55679 6.72328C7.81916 6.46073 7.96656 6.10464 7.96656 5.73333C7.96656 4.95833 7.34199 4.325 6.56752 4.325C6.19427 4.325 5.8363 4.47338 5.57237 4.73749C5.30844 5.0016 5.16016 5.35982 5.16016 5.73333C5.16016 6.50833 5.79306 7.13333 6.56752 7.13333ZM7.72506 15.4167V8.44167H5.41832V15.4167H7.72506Z" fill="black"/>
</svg>
  );
};
export const Facebook = () => {
  return (
    <svg width="35" height="35" viewBox="0 0 16 16" color="black" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.63889 0.407227C3.41993 0.407227 0 3.82716 0 8.04612C0 11.8587 2.79354 15.0189 6.44569 15.5926V10.2538H4.50542V8.04612H6.44569V6.36327C6.44569 4.44896 7.58542 3.39174 9.3309 3.39174C10.1666 3.39174 11.0405 3.5407 11.0405 3.5407V5.41987H10.078C9.12847 5.41987 8.83285 6.00882 8.83285 6.61306V8.04612H10.9511L10.6127 10.2538H8.83285V15.5926C12.4842 15.0197 15.2778 11.8579 15.2778 8.04612C15.2778 3.82716 11.8578 0.407227 7.63889 0.407227Z" fill="black"/>
  </svg>

  );
};
export const Instagram = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="37" height="37" color="black" fill="currentColor" class="bi bi-instagram" viewBox="0 0 16 16">
  <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334"/>
</svg>
  
  );
};
export const Whatsapp = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill="currentColor"
      class="bi bi-whatsapp"
      viewBox="0 0 16 16"
    >
      <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232" />
    </svg>
  );
};
export const Twitter = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill="currentColor"
      class="bi bi-twitter-x"
      viewBox="0 0 16 16"
    >
      <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
    </svg>
  );
};
export const HumburgIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="30"
      fill="black"
      class="bi bi-list"
      viewBox="0 0 16 16"
    >
      <path
        fill-rule="evenodd"
        d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
      />
    </svg>
  );
};

export const CrossIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      fill="#000"
      class="bi bi-x"
      viewBox="0 0 16 16"
    >
      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
    </svg>
  );
};
export const PhoneIcon = () =>{
  return(
    <svg width="25" height="25" viewBox="0 0 29 67" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_571_365)">
    <path d="M16.4766 54.7223C16.4766 54.7223 16.6203 54.5785 16.9078 54.291C17.1954 54.0035 17.3391 53.456 17.3391 52.6485C17.3391 51.841 17.0516 51.1497 16.4766 50.5746C15.9015 49.9996 15.2102 49.7121 14.4027 49.7121C13.5952 49.7121 12.904 49.9996 12.3289 50.5746C11.7539 51.1497 11.4664 51.841 11.4664 52.6485C11.4664 53.456 11.7539 54.1472 12.3289 54.7223C12.904 55.2973 13.5952 55.5849 14.4027 55.5849C15.2102 55.5849 15.9015 55.2973 16.4766 54.7223ZM24.9737 46.7757V20.9355C24.9737 20.6174 24.8575 20.3421 24.625 20.1097C24.3926 19.8772 24.1173 19.761 23.7992 19.761H5.00632C4.68821 19.761 4.41292 19.8772 4.18046 20.1097C3.948 20.3421 3.83176 20.6174 3.83176 20.9355V46.7757C3.83176 47.0938 3.948 47.3691 4.18046 47.6016C4.41292 47.834 4.68821 47.9503 5.00632 47.9503H23.7992C24.1173 47.9503 24.3926 47.834 24.625 47.6016C24.8575 47.3691 24.9737 47.0938 24.9737 46.7757ZM17.3391 16.2373C17.7306 16.2373 17.9264 16.0416 17.9264 15.6501C17.9264 15.2585 17.7306 15.0628 17.3391 15.0628H11.4664C11.0748 15.0628 10.8791 15.2585 10.8791 15.6501C10.8791 16.0416 11.0748 16.2373 11.4664 16.2373H17.3391ZM28.4974 15.0628V52.6485C28.4974 53.9209 28.0324 55.022 27.1026 55.9519C26.1727 56.8818 25.0716 57.3467 23.7992 57.3467H5.00632C3.73388 57.3467 2.63274 56.8818 1.70289 55.9519C0.773033 55.022 0.308105 53.9209 0.308105 52.6485V15.0628C0.308105 13.7903 0.773033 12.6892 1.70289 11.7593C2.63274 10.8295 3.73388 10.3646 5.00632 10.3646H23.7992C25.0716 10.3646 26.1727 10.8295 27.1026 11.7593C28.0324 12.6892 28.4974 13.7903 28.4974 15.0628Z" fill="black"/>
    </g>
    <defs>
    <clipPath id="clip0_571_365">
    <rect width="28.2234" height="65.775" fill="white" transform="matrix(1 0 0 -1 0.292969 66.7441)"/>
    </clipPath>
    </defs>
    </svg>
    


  )
}
export const EmailIcon = () =>{
  return(
<svg width="25" height="25" viewBox="0 0 67 67" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_571_370)">
<path d="M66.3974 27.0276V56.1712C66.3974 57.7862 65.8227 59.1687 64.6731 60.3188C63.5235 61.4689 62.1416 62.0439 60.5273 62.0439H6.52244C4.90816 62.0439 3.52625 61.4689 2.37669 60.3188C1.22712 59.1687 0.652344 57.7862 0.652344 56.1712V27.0276C1.72853 28.2266 2.96369 29.2911 4.35784 30.2209C13.2119 36.2405 19.2899 40.4615 22.5918 42.8841C23.986 43.9118 25.1172 44.7132 25.9855 45.2882C26.8538 45.8633 28.0094 46.4505 29.4525 47.05C30.8956 47.6496 32.2408 47.9493 33.4882 47.9493H33.5249H33.5616C34.809 47.9493 36.1542 47.6496 37.5973 47.05C39.0403 46.4505 40.196 45.8633 41.0643 45.2882C41.9326 44.7132 43.0638 43.9118 44.458 42.8841C48.6159 39.8743 54.7062 35.6532 62.7286 30.2209C64.1228 29.2666 65.3457 28.2021 66.3974 27.0276ZM66.3974 16.2364C66.3974 18.1695 65.7982 20.017 64.5997 21.7788C63.4012 23.5406 61.9093 25.0455 60.1238 26.2935C50.9273 32.6801 45.2039 36.6565 42.9537 38.2225C42.7092 38.3938 42.1894 38.767 41.3945 39.342C40.5996 39.9171 39.9392 40.382 39.4133 40.7368C38.8875 41.0916 38.2515 41.4893 37.5056 41.9297C36.7596 42.3702 36.0564 42.7005 35.396 42.9208C34.7356 43.141 34.1241 43.2511 33.5616 43.2511H33.5249H33.4882C32.9257 43.2511 32.3142 43.141 31.6538 42.9208C30.9934 42.7005 30.2902 42.3702 29.5442 41.9297C28.7982 41.4893 28.1623 41.0916 27.6365 40.7368C27.1106 40.382 26.4502 39.9171 25.6553 39.342C24.8604 38.767 24.3406 38.3938 24.096 38.2225C21.8703 36.6565 18.6662 34.4236 14.4838 31.5239C10.3013 28.6243 7.7943 26.8808 6.9627 26.2935C5.44626 25.2658 4.01542 23.8526 2.67019 22.0541C1.32496 20.2556 0.652344 18.5855 0.652344 17.0439C0.652344 15.1352 1.15986 13.5447 2.1749 12.2723C3.18994 10.9998 4.63912 10.3636 6.52244 10.3636H60.5273C62.1172 10.3636 63.493 10.9387 64.6548 12.0888C65.8165 13.2388 66.3974 14.6214 66.3974 16.2364Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0_571_370">
<rect width="65.8049" height="65.775" fill="white" transform="matrix(1 0 0 -1 0.623047 66.7441)"/>
</clipPath>
</defs>
</svg>

  )
}
export const ErrowIcon = () =>{
  return(
    <svg width="20" height="20" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_571_375)">
    <path d="M36.5925 24.2533V32.532C36.5925 34.5845 35.8638 36.3394 34.4064 37.7968C32.949 39.2542 31.1941 39.9829 29.1416 39.9829H7.61689C5.56445 39.9829 3.80953 39.2542 2.35212 37.7968C0.894718 36.3394 0.166016 34.5845 0.166016 32.532V11.0073C0.166016 8.95486 0.894718 7.19994 2.35212 5.74253C3.80953 4.28513 5.56445 3.55643 7.61689 3.55643H25.8301C26.0716 3.55643 26.2699 3.63404 26.4252 3.78927C26.5804 3.94449 26.658 4.14284 26.658 4.3843V6.04005C26.658 6.28151 26.5804 6.47986 26.4252 6.63509C26.2699 6.79031 26.0716 6.86793 25.8301 6.86793H7.61689C6.47856 6.86793 5.50408 7.27324 4.69345 8.08387C3.88283 8.89449 3.47751 9.86897 3.47751 11.0073V32.532C3.47751 33.6704 3.88283 34.6448 4.69345 35.4555C5.50408 36.2661 6.47856 36.6714 7.61689 36.6714H29.1416C30.28 36.6714 31.2544 36.2661 32.0651 35.4555C32.8757 34.6448 33.281 33.6704 33.281 32.532V24.2533C33.281 24.0118 33.3586 23.8135 33.5138 23.6583C33.6691 23.503 33.8674 23.4254 34.1089 23.4254H35.7646C36.0061 23.4254 36.2044 23.503 36.3597 23.6583C36.5149 23.8135 36.5925 24.0118 36.5925 24.2533ZM46.527 1.90068V15.1467C46.527 15.5951 46.3631 15.9832 46.0354 16.3109C45.7077 16.6386 45.3197 16.8024 44.8712 16.8024C44.4228 16.8024 44.0347 16.6386 43.707 16.3109L39.1537 11.7576L22.2858 28.6255C22.1133 28.798 21.915 28.8842 21.6908 28.8842C21.4665 28.8842 21.2682 28.798 21.0957 28.6255L18.1464 25.6762C17.9739 25.5037 17.8877 25.3054 17.8877 25.0812C17.8877 24.857 17.9739 24.6586 18.1464 24.4861L35.0144 7.61819L30.461 3.06488C30.1333 2.73718 29.9695 2.34911 29.9695 1.90068C29.9695 1.45225 30.1333 1.06418 30.461 0.73648C30.7887 0.408779 31.1768 0.244929 31.6252 0.244929H44.8712C45.3197 0.244929 45.7077 0.408779 46.0354 0.73648C46.3631 1.06418 46.527 1.45225 46.527 1.90068Z" fill="white"/>
    </g>
    <defs>
    <clipPath id="clip0_571_375">
    <rect width="46.361" height="46.361" fill="white" transform="matrix(1 0 0 -1 0.168945 46.6079)"/>
    </clipPath>
    </defs>
    </svg>
    
    


  )
}
