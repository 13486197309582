// export default ContactForm
import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import './ContactForm.css';
import ReCAPTCHA from 'react-google-recaptcha';

function ContactForm() {
  // const [captchaVal, setCaptchaVal] = useState(null);
  const [submitted, setSubmitted] = useState(false);

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_npn6dpp', 'template_su00x3p', form.current, {
        publicKey: 'FdR2GwMya2A43s8rB',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          setSubmitted(true); // Set state to indicate form submission
          form.current.reset(); // Reset form fields
          // setCaptchaVal(null); // Reset reCAPTCHA
        },
        (error) => {
          console.log('FAILED...', error.text);
          // Handle error scenario if needed
        }
      );
  };

  return (
    <div className="contact-form">
      <p className='contact-heading text-start'>Seek clarity with every question,<br /> we offer answers for your journey.</p>
      <form ref={form} onSubmit={sendEmail}>
        <div className="input-fields">
          <label htmlFor="user_name"></label>
          <input type="text" name="user_name" placeholder="Name" required />
        </div>
        <div className="input-fields">
          <label htmlFor="user_email"></label>
          <input type="email" name="user_email" placeholder="Email" required />
        </div>
        <div className="input-fields">
          <label htmlFor="user_number"></label>
          <input type="number" name="user_number" placeholder="Phone Number" required />
        </div>
        <div className="input-fields">
          <label htmlFor="message"></label>
          <textarea name="message" placeholder='How can we help?' />
        </div>
        {/* <ReCAPTCHA
          className="reCaptcha-style mt-3"
          sitekey="6LduRfUpAAAAABpNzAvA-m3LI814pz11ZD48yIws"
          onChange={(val) => setCaptchaVal(val)}
        /> */}

        <button type="submit" value="Send" className="submit-btn"  >
          Send
        </button>
        {submitted && <p className="submit-message">Message submitted successfully!</p>}
      </form>
      <hr className='divider' />
      <div className='text-center'>
        <p>Business Enquiry<br />Give us a call now at +91-9151041414</p>
      </div>
    </div>
    // disabled={!captchaVal}
  );
}

export default ContactForm;