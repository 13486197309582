import React from 'react'
import "./Custmercard.css"
import { Img } from 'react-image'

const CustmerCard = ({title,Imageurl}) => {
  return (
    <div className='custmer_card'>
      <div className='custmer_image'>
        <Img src={Imageurl} alt='' className='img'/>
      </div>
      <h6 className='mt-2 text_custmer text-center weight-600 font-22-res-12 '>{title}</h6>
      {/* <button className='btn_custmer text-center m-auto d-flex'>Learn More</button> */}
    </div>
  )
}

export default CustmerCard