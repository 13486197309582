import React from 'react'
import "./bbc.css"

function BBCServiceCard({svgLink, header, paragraph}) {
  return (
    <div  className='bbcCard'>
      <div>
        <div className='card-icon'>{svgLink}</div>
        <h4 className='header weight-600'>{header}</h4>
      </div>
      <p style={{margin: "15px 0 0 0"}} className='weight-400'>{paragraph}</p>
    </div>
  )
}

export default BBCServiceCard