import React from 'react'
// import maintainImg from '../../assets/maintenance.png'
import custom from '../../Assets/CTS2.png'
import UncoverCard from '../../Component/UncoverCard/UncoverCard'
import webImage from "../../Assets/image_main/Comphrehensive Tech Support.jpg"
import DiscoverCard from '../../Component/DiscoverCard/DiscoverCard'
import cts1 from "../../Assets/Remote troobleshooting.jpg"
import cts2 from "../../Assets/Online Support and maintenance.jpg"
import cts3 from "../../Assets/System optimization and performance testing.avif"
import cts4 from "../../Assets/Backup and recovery.jpg"
import Continuous_Improvement from "../../Assets/image/continuous improvement & training.jpg"
import DiscoverCardCenter from '../../Component/DiscoverCardCenter/DiscoverCardCenter'
import Goals from '../../Component/Goals/Goals'
import Remote_Troubleshooting from "../../Assets/icon/Remote troubleshooting and support.jpg"
import Onsite_Support from "../../Assets/icon/onsite support and maintenance.jpg"
import System_Optimization from "../../Assets/icon/system optimization and performance tuning.jpg"
import Backup_and_Disaster from "../../Assets/icon/backup and disaster recovery planning.jpg"
import Expertise_Hardware from "../../Assets/icon/expertise in hardware maintenance.jpg"
import Proactive_Solutions from "../../Assets/icon/proactive solutions.jpg"
import Personalized_Support from "../../Assets/icon/personalized support plans.jpg"
import Fast_and_Reliable from "../../Assets/icon/fast and reliable service.jpg"
import Comprehensive_Coverage from "../../Assets/icon/comprehensive coverage.jpg"
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

// import ecomm from '../../assets/ecomm.png'

function TechSupport() {
  return (
    <>
      <Helmet>
        <title>24/7 Expert Tech Support: Comprehensive Solutions Anytime, Anywhere</title>
        <meta
          name="description"
          content="Get round-the-clock expert tech support! Our comprehensive solutions ensure your tech issues are resolved anytime, anywhere."
        />
        <meta
          name="keywords"
          content="Outsourcing services in India, Outsourcing services in Prayagraj, IT Software  services in india,IT software services in Prayagraj, IT Hardware services in India, IT Hardware services in Prayagraj" />
        <meta name="author" content="BizzBuzzCreations" />
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-XVSSY0BR1E"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-XVSSY0BR1E');
        `}
        </script>
      </Helmet>
      <div className='webdevelpment_page d-flex align-items-center justify-content-center text-center flex_page'>
        {/* <div className='image_portion_web img-width'>
        <img src={webImage} alt='image' className='image_main' />
      </div> */}
        <div className='content_portion p-15'>
          <h2 className='weight-600 mb-3'>Comprehensive Tech Support</h2>
          <h5 className='weight-500'>24/7 Comprehensive Tech Support: We're Here to Help!</h5>
          <p className='weight-400 mb-5'>For all of your tech issues, we offer professional assistance through our round-the-clock comprehensive tech support service. We make sure your devices function properly, from setup to troubleshooting, offering you peace of mind whenever and wherever you are.</p>
          <button className="get_button"><Link className="text-decoration-none" to="/contact">Get Started</Link></button>
        </div>
      </div>
      <div className='web_portion'>
        <div className='head_portion'>
          <h1 className='font-20-res-16 weight-600 text-center mb-5'>Comprehensive Tech Support Services We Offer</h1>
        </div>
        <div className='grid_portion'>
          <DiscoverCardCenter icon_image={Remote_Troubleshooting} title="Remote Troubleshooting and Support" paragraph="Remote troubleshooting and support offer instant assistance for technical issues via online tools. Our experts diagnose and resolve problems quickly, ensuring minimal downtime and maintaining your systems' optimal performance from anywhere." />
          <DiscoverCardCenter icon_image={Onsite_Support} title="Onsite Support and Maintenance" paragraph="Our onsite support and maintenance service offers hands-on assistance for your technology needs. We ensure optimal performance, troubleshoot issues, and provide regular maintenance to keep your systems running smoothly and efficiently." />
          <DiscoverCardCenter icon_image={System_Optimization} title="System Optimization and Performance Tuning" paragraph="Through increased speed and resource utilization, system optimization and performance tweaking improve the efficiency of your computer. We guarantee optimal performance and reliability by analyzing settings, removing delays, and putting best practices into effect." />
          <DiscoverCardCenter icon_image={Backup_and_Disaster} title="Backup and Disaster Recovery Planning" paragraph="Planning for backup and disaster recovery include developing methods to safeguard data and guarantee company continuity. To reduce downtime and data loss, regular backups, recovery techniques, and risk assessments are all part of it." />
        </div>

      </div>
      <div className='incover_portion pb-5'>
        <h1 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>Objectives for Comprehensive Tech support</h1>
        <div className='grid_portion m-auto align-items-center justify-content-center '>
          <UncoverCard image_uncover={cts1} title="Minimize Downtime" paragraph="Ensure quick and effective resolution of technical issues to reduce system or device downtime, keeping business operations running smoothly." />
          <UncoverCard image_uncover={cts2} title="Enhance Customer Satisfaction" paragraph="Deliver prompt, courteous, and knowledgeable support that improves customer experience, ensuring clients feel supported and valued." />
          <UncoverCard image_uncover={cts3} title="Preventative Maintenance" paragraph="Implement proactive monitoring and regular maintenance to identify and resolve potential issues before they impact operations, extending the lifespan of hardware and systems." />
          <UncoverCard image_uncover={cts4} title="Cost Efficiency" paragraph="Optimize support resources and solutions to offer cost-effective services, helping clients reduce expenses related to repairs, replacements, and productivity losses." />
          <UncoverCard image_uncover={Continuous_Improvement} title="Continuous Improvement & Training" paragraph="Keep tech support staff up to date with the latest technology, tools, and best practices, while continually improving support processes for better efficiency and effectiveness." />
        </div>

      </div>
      <div className='Page'>
        <h1 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>Why Choose BBC for Comprehensive Tech Support?</h1>
        <div className="App">
          <div className="card-container container">
            <Goals logo_icon={Expertise_Hardware} title="Expertise in Hardware Maintenance" paragraph="BBC brings specialized knowledge in maintaining a wide range of hardware systems, ensuring your equipment is in expert hands and operates at peak performance." />
            <Goals logo_icon={Proactive_Solutions} title="Proactive Solutions" paragraph="With a focus on preventative care, BBC identifies and resolves issues before they escalate, minimizing downtime and reducing the risk of major disruptions." />
            <Goals logo_icon={Personalized_Support} title="Personalized Support Plans" paragraph="BBC offers customized tech support packages to suit the unique needs of your business, providing flexibility and ensuring you only pay for the services you need." />
            <Goals logo_icon={Fast_and_Reliable} title="Fast and Reliable Service" paragraph="BBC is committed to providing quick response times and reliable solutions, ensuring minimal interruption to your business operations with efficient problem resolution." />
            <Goals logo_icon={Comprehensive_Coverage} title="Comprehensive Coverage" paragraph="From troubleshooting to regular maintenance and upgrades, BBC’s all-in-one tech support services cover every aspect of your hardware and IT infrastructure, offering a complete support solution." />
          </div>
        </div>
      </div>
      {/* <div style={{ background: "#EEEEEE", padding: "20px 0 20px 0" }}>
        <div className='container'>
          <h2 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>Goals of Providing Tech Support Services</h2>
          <DiscoverCard title="Minimize Downtime and Disruptions" paragraph="Our primary objective is to minimize downtime and disruptions to your business operations by providing fast, reliable, and proactive tech support services. We understand that every minute of downtime costs you money, so we work diligently to resolve issues quickly and efficiently." />
          <DiscoverCard title="Maximize Uptime and Reliability" paragraph="We aim to maximize uptime and reliability of your technology infrastructure by providing proactive monitoring, maintenance, and support services. Our goal is to ensure that your systems and networks are always available and performing at their best, enabling you to focus on your core business activities." />
          <DiscoverCard title="Enhance Security and Data Protection" paragraph="We prioritize security and data protection in all our tech support services. Our team implements robust security measures and best practices to safeguard your systems and networks against cyber threats, data breaches, and other security risks." />
          <DiscoverCard title="Improve Efficiency and Productivity" paragraph="By optimizing the performance of your technology infrastructure and providing training and support to your employees, we help improve efficiency and productivity across your organization. Our tech support services enable you to work smarter, not harder, and achieve better results with less effort." />
          <DiscoverCard title="Deliver Exceptional Customer Service" paragraph="We are committed to delivering exceptional customer service and support to our clients. Our objective is to exceed your expectations and provide a positive experience every time you interact with us. Whether you have a basic inquiry or a mind boggling specialized issue, we're here to help." />
          <DiscoverCard title="" paragraph="Partner with Bizz Buzz Creation for reliable and efficient tech support services that keep your business running smoothly and effectively. Our comprehensive solutions are designed to minimize downtime, maximize uptime, and ensure the reliability and performance of your technology infrastructure. Contact us today to learn more about how we can help you achieve your tech support objectives!" />
        </div>
      </div> */}

      {/* <div style={{ background: "#d4dfe3", padding: "0 0 20px 0" }}>
        <div className='container'>
          <div className='d-flex gap-5 align-items-center pt-5 flex_page'>
            <div className='img-width'>
              <img src={custom} alt="ecomm website" className='image' />
            </div>
            <div className='text-start'>
              <h2 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>Hardware Maintenance</h2>
              <p>In order to maintain the best possible functioning of technology systems, hardware maintenance entails routine inspections, repairs, and updates. By reducing downtime, extending the life of equipment, and improving overall efficiency, it supports smooth business operations.</p>
            </div>
          </div>
          <h2 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>Why Choose BBC for Hardware Maintenance ?</h2>
          <DiscoverCard title="Minimize Downtime" paragraph="Our hardware maintenance services are designed to minimize downtime by proactively identifying and addressing potential issues before they escalate into major problems. By keeping your hardware in optimal condition, we help ensure uninterrupted operation of your business-critical systems and applications." />
          <DiscoverCard title="Extend Equipment Lifespan" paragraph="Regular maintenance and proactive servicing can extend the lifespan of your hardware equipment, reducing the need for costly replacements and upgrades. Our technicians perform routine inspections, cleanings, and tune-ups to keep your hardware functioning efficiently for longer." />
          <DiscoverCard title="Reduce Repair Costs" paragraph="Investing in preventive maintenance can help you avoid costly repairs and emergency service calls. Our hardware maintenance plans include regular inspections and preventive maintenance tasks that help identify and address minor issues before they develop into major problems, saving you time and money in the long run." />
          <DiscoverCard title="Optimize Performance" paragraph="Well-maintained hardware equipment operates more efficiently, delivering better performance and reliability. Our hardware maintenance services help optimize the performance of your servers, workstations, networking devices, and other hardware components, ensuring they operate at peak efficiency." />
          <DiscoverCard title="Peace of Mind" paragraph="With Bizz Buzz Creation's hardware maintenance services, you can have peace of mind knowing that your technology infrastructure is in good hands. Our experienced technicians are available to provide prompt assistance and support whenever you need it, giving you the confidence to focus on your core business activities." />

          <h2 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>How our Hardware Maintenance services help you?</h2>
          <div className='grid_portion' style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", placeItems: "center", gap: "10px", textAlign: "center" }}>
            <div>
              <h3 className='font-20-res-16 weight-600'>Minimized Downtime</h3>
              <p className='font-18-res-16 weight-400'>Our proactive maintenance decreases the likelihood of unforeseen failures, ensuring your systems remain operational and productive.</p>
            </div>
            <div>
              <h3 className='font-20-res-16 weight-600'>Improved Performance</h3>
              <p className='font-18-res-16 weight-400'>By optimizing hardware performance through routine maintenance and updates, you can make the most of your technological expenditures.</p>
            </div>
            <div>
              <h3 className='font-20-res-16 weight-600'>Savings</h3>
              <p className='font-18-res-16 weight-400'>By identifying problems early, preventive maintenance can lower repair costs and prolong the life of your hardware.</p>
            </div>
            <div>
              <h3 className='font-20-res-16 weight-600'>Expert Support</h3>
              <p className='font-18-res-16 weight-400'>You can concentrate on your main business while we handle any hardware problems that may emerge with prompt, dependable assistance from our skilled specialists.</p>
            </div>
            <div>
              <h3 className='font-20-res-16 weight-600'>Customized Solutions</h3>
              <p className='font-18-res-16 weight-400'> We tailor our maintenance plans to meet your specific needs, ensuring the best possible support for your technology infrastructure.</p>
            </div>
            <div>
              <h3 className='font-20-res-16 weight-600'>Increased Security</h3>
              <p className='font-18-res-16 weight-400'>Regular updates and maintenance help protect your systems from vulnerabilities, ensuring your data remains secure.</p>
            </div>
          </div>

          <h2 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>Objectives for Comprehensive Tech support</h2>
          <DiscoverCard title="Minimize Downtime" paragraph="Ensure quick and effective resolution of technical issues to reduce system or device downtime, keeping business operations running smoothly." />
          <DiscoverCard title="Enhance Customer Satisfaction" paragraph="Deliver prompt, courteous, and knowledgeable support that improves customer experience, ensuring clients feel supported and valued." />
          <DiscoverCard title="Preventative Maintenance" paragraph="Implement proactive monitoring and regular maintenance to identify and resolve potential issues before they impact operations, extending the lifespan of hardware and systems." />
          <DiscoverCard title="Cost Efficiency" paragraph="Optimize support resources and solutions to offer cost-effective services, helping clients reduce expenses related to repairs, replacements, and productivity losses." />
          <DiscoverCard title="Continuous Improvement & Training" paragraph="Keep tech support staff up to date with the latest technology, tools, and best practices, while continually improving support processes for better efficiency and effectiveness." />
          
        </div>
      </div> */}

      {/* <div style={{ background: "#EEEEEE", padding: "0 0 20px 0" }}>
        <div className='container'>
          <div className='d-flex gap-5 align-items-center pt-5 flex_page'>
          <div className='img-width'>
            <img src={custom} alt="ecomm website" className='image' />
            </div>
            <div className='text-start'>
              <h2 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>Software Support</h2>
              <h4>Welcome to Bizz Buzz Creation's Software Support Service!</h4>
              <p className='font-18-res-16 weight-400'>At Bizz Buzz Creation, we understand the critical role that software plays in modern business operations. From streamlining processes to enhancing productivity, software is the backbone of any successful enterprise. However, we also recognize that maintaining and troubleshooting software can be a daunting task, especially for businesses without dedicated IT departments. That's where our Software Support Service comes in:</p>
            </div>
          </div>
          <h2 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>Why Choose BBC for Comprehensive Tech Support?</h2>
          <DiscoverCard title="Expertise in Hardware Maintenance" paragraph="BBC brings specialized knowledge in maintaining a wide range of hardware systems, ensuring your equipment is in expert hands and operates at peak performance." />
          <DiscoverCard title="Proactive Solutions" paragraph="With a focus on preventative care, BBC identifies and resolves issues before they escalate, minimizing downtime and reducing the risk of major disruptions." />
          <DiscoverCard title="Personalized Support Plans" paragraph="BBC offers customized tech support packages to suit the unique needs of your business, providing flexibility and ensuring you only pay for the services you need." />
          <DiscoverCard title="Fast and Reliable Service" paragraph="BBC is committed to providing quick response times and reliable solutions, ensuring minimal interruption to your business operations with efficient problem resolution." />
          <DiscoverCard title="Comprehensive Coverage" paragraph="From troubleshooting to regular maintenance and upgrades, BBC’s all-in-one tech support services cover every aspect of your hardware and IT infrastructure, offering a complete support solution."/> 
          <h2 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>How our Software Support Services can help you ?</h2>
          <div className='grid_portion' style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", placeItems: "center", gap: "10px", textAlign: "center" }}>
            <div>
              <h3 className='font-20-res-16 weight-600'>24/7 Support</h3>
              <p className='font-18-res-16 weight-400'>Software issues can arise at any time, often when you least expect them. That's why we offer round-the-clock support to ensure that your business operations remain uninterrupted, day or night.</p>
            </div>
            <div>
              <h3 className='font-20-res-16 weight-600'>Proactive Monitoring</h3>
              <p className='font-18-res-16 weight-400' >Our proactive monitoring services help identify and address potential issues before they escalate into major problems. By staying one step ahead, we minimize downtime and maximize efficiency.</p>
            </div>
            <div>
              <h3 className='font-20-res-16 weight-600'>Regular Updates and Maintenance</h3>
              <p className='font-18-res-16 weight-400'>Software is constantly evolving, with new updates and patches released regularly. Our team will ensure that your software is always up-to-date and running smoothly, minimizing security risks and optimizing performance.</p>
            </div>
            <div>
              <h3 className='font-20-res-16 weight-600'>Tailored Training Programs</h3>
              <p className='font-18-res-16 weight-400'>To maximize the effectiveness of your software, we offer customized training programs for your team. This ensures that your staff can utilize the software efficiently, boosting productivity and enhancing overall performance.</p>
            </div>
          </div>

          <h2 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>Goals of Providing Software Service</h2>
          <DiscoverCard title="Customer Satisfaction" paragraph="Our primary objective is to ensure the satisfaction of our clients by providing prompt, reliable, and high-quality software support services. Your prosperity is our prosperity, and we are focused on doing an amazing job to live up to your assumptions." />
          <DiscoverCard title="Long-Term Partnerships" paragraph="We strive to build long-term partnerships with our clients based on trust, transparency, and mutual respect. By understanding your business goals and challenges, we can tailor our services to help you achieve sustainable growth and success." />
          <DiscoverCard title="Continuous Improvement" paragraph="In the ever-changing world of technology, continuous improvement is essential. We are dedicated to staying at the forefront of industry trends and best practices, continually refining and enhancing our services to better serve our clients." />
          <DiscoverCard title="" paragraph="Experience the peace of mind that comes with knowing your software support needs are in capable hands. Contact Bizz Buzz Creation today to learn more about how we can help take your business to the next level!" />
        </div>
      </div> */}

      {/* <div style={{ background: "#d4dfe3", padding: "0 0 20px 0" }}>
        <div className='container'>
          <div className='d-flex gap-5 align-items-center pt-5 flex_page'>
          <div className='img-width'>
            <img src={custom} alt="ecomm website" className='image' />
            </div>
            <div className='text-start'>
              <h2 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>Network Management Services</h2>
              <h4>Welcome to Bizz Buzz Creation, your trusted partner for comprehensive network management services.</h4>
              <p className='font-18-res-16 weight-400'>Welcome to Bizz Buzz Creation, We understand that a well-managed network is essential for supporting your business operations and enabling seamless communication and collaboration. That's why our expert team is dedicated to providing proactive, reliable, and cost-effective network management solutions to help you maximize uptime and minimize downtime.</p>
            </div>
          </div>
          <h2 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>Why Bizz Buzz Creations Stands Out ?</h2>
          <DiscoverCard title="Proactive Monitoring and Maintenance" paragraph="Our network management services include proactive monitoring and maintenance to ensure that your network infrastructure is always running smoothly and efficiently. We monitor for performance issues, security threats, and other potential issues and take proactive measures to address them before they impact your business." />
          <DiscoverCard title="Optimized Network Performance" paragraph="We understand that every business is unique, with its own set of software requirements and challenges. That's why we take a personalized approach to software support, tailoring our services to meet your specific needs and objectives.We optimize the performance of your network infrastructure to ensure that it meets the demands of your business operations. Our team analyzes network traffic patterns, identifies bottlenecks, and implements performance enhancements to maximize network throughput and minimize latency." />
          <DiscoverCard title="Enhanced Security and Compliance" paragraph="We prioritize security and compliance in all our network management services. We implement robust security measures, such as firewalls, intrusion detection systems, and access controls, to protect your network from cyber threats and ensure compliance with industry regulations and standards." />
          <DiscoverCard title="Improved Reliability and Uptime" paragraph="By proactively monitoring and maintaining your network infrastructure, we help improve reliability and uptime, ensuring that your business-critical systems and applications are always available when you need them. We want to limit free time and interruptions to your business tasks." />
          <DiscoverCard title="Cost Savings and Efficiency" paragraph="Our network management services help you save time and money by reducing the need for costly repairs, emergency service calls, and downtime. By proactively managing your network infrastructure, we help you avoid costly network outages and optimize network resources for maximum efficiency." />

          <h2 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>How Bizz Buzz Creation's Network Management Services Can Help You</h2>
          <div className='grid_portion' style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", placeItems: "center", gap: "10px", textAlign: "center" }}>
            <div>
              <h3 className='font-20-res-16 weight-600'>Proactive Network Monitoring and Resolution</h3>
              <p className='font-18-res-16 weight-400'>We offer comprehensive network monitoring services to track the health, performance, and security of your network infrastructure. Our advanced tools detect potential issues early, allowing us to address them proactively before they escalate into major problems. In case of any anomalies, our team swiftly identifies and resolves the root causes, minimizing downtime and ensuring uninterrupted business operations.</p>
            </div>
            <div>
              <h3 className='font-20-res-16 weight-600'>Performance Optimization and Tuning</h3>
              <p className='font-18-res-16 weight-400'>We optimize the performance of your network infrastructure by analyzing network traffic patterns, identifying performance bottlenecks, and implementing performance tuning measures. Our goal is to maximize network throughput, minimize latency, and ensure smooth and efficient communication and collaboration across your organization.</p>
            </div>
            <div>
              <h3 className='font-20-res-16 weight-600'>Security Management and Compliance</h3>
              <p className='font-18-res-16 weight-400'>We manage and maintain the security of your network infrastructure to protect against cyber threats and ensure compliance with industry regulations and standards. We implement robust security measures, such as firewalls, intrusion detection systems, and access controls, to safeguard your network and data from unauthorized access and malicious attacks.</p>
            </div>
            <div>
              <h3 className='font-20-res-16 weight-600'>Regular Maintenance and Updates</h3>
              <p className='font-18-res-16 weight-400'>We perform regular maintenance and updates to keep your network infrastructure up-to-date with the latest software patches, firmware upgrades, and security fixes. Our team ensures that your network equipment and software are always running on the latest version to mitigate security risks and ensure optimal performance.</p>
            </div>
          </div>

          <h2 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>Goal of Providing Network Management Services</h2>
          <DiscoverCard title="Maximize Network Reliability and Availability" paragraph="Our primary objective is to maximize the reliability and availability of your network infrastructure through proactive monitoring, maintenance, and support. By ensuring that your network is always up and running, we help minimize downtime and disruptions to your business operations." />
          <DiscoverCard title="Optimize Network Performance and Efficiency" paragraph="We aim to optimize the performance and efficiency of your network infrastructure by identifying and addressing performance bottlenecks, optimizing network resources, and implementing performance tuning measures. Our goal is to ensure that your network operates at peak performance to support your business operations effectively." />
          <DiscoverCard title="Enhance Network Security and Compliance" paragraph="We prioritize security and compliance in all our network management services. Our objective is to protect your network from cyber threats and ensure compliance with industry regulations and standards, such as GDPR, HIPAA, and PCI DSS. We implement robust security measures and best practices to safeguard your network and data from unauthorized access and malicious attacks." />
          <DiscoverCard title="Reduce Total Cost of Ownership" paragraph="We aim to reduce the total cost of ownership of your network infrastructure by minimizing downtime, optimizing performance, and reducing the need for costly repairs and emergency service calls. Our network management services help you get the most out of your network investment while maximizing cost savings and efficiency." />
          <DiscoverCard title="Deliver Exceptional Customer Satisfaction" paragraph="We strive to deliver exceptional customer satisfaction by providing reliable, responsive, and high-quality network management services that meet your needs and exceed your expectations. Our objective is to be your trusted partner for all your network management needs, providing ongoing support and assistance to help you achieve your business goals." />
          <DiscoverCard title="" paragraph="Partner with Bizz Buzz Creation for reliable and efficient network management services that keep your network infrastructure running smoothly and effectively. Our comprehensive solutions are designed to optimize performance, enhance security, and maximize reliability, enabling you to focus on your core business activities with confidence. Contact us today to learn more about how we can help you achieve your network management objectives!" />
        </div>
      </div> */}

      {/* <div style={{ background: "#EEEEEE", padding: "0 0 20px 0" }}>
        <div className='container'>
          <div className='d-flex gap-5 align-items-center pt-5 flex_page'>
          <div className='img-width'>
            <img src={custom} alt="ecomm website" className='image' />
            </div>
            <div className='text-start'>
              <h2 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>Remote Technical Support</h2>
              <p className='font-18-res-16 weight-400'>Welcome to Bizz Buzz Creation, your go-to destination for fast, reliable, and secure remote technical support services. Our expert team is dedicated to resolving your IT issues promptly and efficiently, ensuring that your business remains operational and productive.</p>
            </div>
          </div>
          <h2 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>Why Choose BBC for Remote Technical Support ?</h2>
          <DiscoverCard title="Prompt Issue Resolution" paragraph="Get your IT issues addressed promptly without the need for onsite visits, minimizing downtime and disruption to your business operations." />
          <DiscoverCard title="Cost-Effective Solution" paragraph="Save time and money with our cost-effective remote support services, which eliminate the need for costly onsite visits and travel expenses." />
          <DiscoverCard title="Convenient and Flexible" paragraph="Access IT assistance from anywhere, at any time, without disrupting your workflow or schedule, offering convenience and flexibility for your business needs." />
          <DiscoverCard title="Enhanced Security and Privacy" paragraph="Rest assured that your data and IT assets are safe and secure with our encrypted connections and strict security protocols." />
          <DiscoverCard title="Expertise and Experience" paragraph="Benefit from our team's extensive expertise and experience in resolving a wide range of IT issues quickly and effectively." />

          <h2 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>How our Remote Technical Support Services Can Help You ?</h2>
          <div className='grid_portion' style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", placeItems: "center", gap: "10px", textAlign: "center" }}>
            <div>
              <h3 className='font-20-res-16 weight-600'>Diagnosis and Troubleshooting</h3>
              <p className='font-18-res-16 weight-400'>Identify and resolve IT issues remotely, including software glitches, network connectivity issues, and hardware malfunctions, ensuring minimal disruption to your business operations.</p>
            </div>
            <div>
              <h3 className='font-20-res-16 weight-600'>Software Configuration and Updates</h3>
              <p className='font-18-res-16 weight-400'>Get assistance with software configuration, installation, and updates to keep your applications running smoothly and securely.</p>
            </div>
            <div>
              <h3 className='font-20-res-16 weight-600'>Network Optimization and Troubleshooting</h3>
              <p className='font-18-res-16 weight-400'>Optimize your network infrastructure for maximum performance and reliability, addressing slow internet speeds, intermittent connectivity issues, and network congestion.</p>
            </div>
            <div>
              <h3 className='font-20-res-16 weight-600'>Comprehensive Hardware and Security Support</h3>
              <p className='font-18-res-16 weight-400'>Resolve hardware issues remotely, including malfunctions, failures, and performance problems, while also assessing and addressing security vulnerabilities in your IT infrastructure. Our services minimize downtime and protect against cyber threats and attacks, ensuring smooth and secure business operations.</p>
            </div>
          </div>

          <h2 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>Goal of Providing Remote Technical Support Services</h2>
          <DiscoverCard title="Minimize Downtime and Disruption" paragraph="Ensure that your business remains operational and productive by resolving IT issues promptly and efficiently, minimizing downtime and disruption." />
          <DiscoverCard title="Maximize Productivity and Efficiency" paragraph="Empower your team to overcome IT challenges quickly and efficiently, enabling them to focus on core business activities without interruption." />
          <DiscoverCard title="Enhance Security and Compliance" paragraph="Protect your data and IT assets from cyber threats and attacks, ensuring compliance with industry regulations and standards." />
          <DiscoverCard title="Deliver Exceptional Customer Satisfaction" paragraph="Provide reliable, responsive, and high-quality support services that meet and exceed your expectations, building long-term relationships based on trust and reliability." />
          <DiscoverCard title="" paragraph="Partner with Bizz Buzz Creation for reliable and efficient remote technical support services that keep your business running smoothly. Contact us today to learn more about how we can help you achieve your IT support objectives!" />
        </div>
      </div> */}

      {/* <div style={{ background: "#d4dfe3", padding: "0 0 20px 0" }}>
        <div className='container'>
          <div className='d-flex gap-5 align-items-center pt-5 flex_page'>
          <div className='img-width'>
            <img src={custom} alt="ecomm website" className='image' />
            </div>
            <div className='text-start'>
              <h2 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>Data Backup and Recovery Services</h2>
              <p className='font-18-res-16 weight-400'>Welcome to Bizz Buzz Creation, We understand the importance of protecting your valuable data and ensuring business continuity in the face of unexpected disasters or data loss events. That's why our expert team is dedicated to providing reliable, secure, and efficient data backup and recovery solutions to safeguard your critical information and minimize downtime.</p>
            </div>
          </div>
          <h2 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>Why Choose BBC for Bizz Buzz Creation's Data Backup and Recovery Services ?</h2>
          <DiscoverCard title="Data Protection and Security" paragraph="Our data backup and recovery services provide comprehensive protection for your valuable data, ensuring that it is securely backed up and stored in offsite locations to protect against data loss due to disasters, hardware failures, or cyber attacks." />
          <DiscoverCard title="Business Continuity and Disaster Recovery" paragraph="We help ensure business continuity by implementing robust data backup and recovery solutions that enable quick and efficient recovery of your critical business data in the event of a disaster or data loss event, minimizing downtime and disruption to your business operations." />
          <DiscoverCard title="Reduced Risk of Data Loss" paragraph="By regularly backing up your data and implementing effective recovery strategies, we help reduce the risk of data loss and mitigate the potential impact of data-related incidents on your business, ensuring that your data remains safe, accessible, and protected at all times." />
          <DiscoverCard title="Compliance and Regulatory Requirements" paragraph="Our data backup and recovery services help you meet compliance and regulatory requirements by ensuring that your data is securely backed up, stored, and retained according to industry-explicit guidelines and norms, like GDPR, HIPAA, and PCI DSS." />
          <DiscoverCard title="Peace of Mind" paragraph="With Bizz Buzz Creation's data backup and recovery services, you can have peace of mind knowing that your critical business data is protected and that you have a reliable plan in place to recover it quickly and efficiently in the event of a data loss event or disaster." />

          <h2 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>How our Data Backup and Recovery Services Can Help You ?</h2>
          <div className='grid_portion' style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", placeItems: "center", gap: "10px", textAlign: "center" }}>
            <div>
              <h3 className='font-20-res-16 weight-600'>Customized Backup Solutions</h3>
              <p className='font-18-res-16 weight-400'>We offer customized data backup solutions tailored to your specific needs, requirements, and budget, ensuring that your data is backed up and stored securely according to your preferences and priorities.</p>
            </div>
            <div>
              <h3 className='font-20-res-16 weight-600'>Automated Backup and Scheduled Maintenance</h3>
              <p className='font-18-res-16 weight-400'>Our data backup solutions include automated backup processes and scheduled maintenance tasks to ensure that your data is backed up regularly and consistently, without requiring manual intervention or oversight.</p>
            </div>
            <div>
              <h3 className='font-20-res-16 weight-600'>Offsite Data Storage and Redundancy</h3>
              <p className='font-18-res-16 weight-400'>We store your backup data in offsite locations and implement redundancy measures to ensure that your data is protected against physical disasters, such as fires, floods, or theft, ensuring that you can recover your data quickly and efficiently in any situation.</p>
            </div>
            <div>
              <h3 className='font-20-res-16 weight-600'>Fast and Efficient Data Recovery and Continuous Monitoring</h3>
              <p className='font-18-res-16 weight-400'>In the event of data loss or disaster, our team provides fast data recovery services to restore your critical business data, minimizing downtime and disruption. We also continuously monitor and maintain your backup systems to ensure secure data storage and smooth, efficient backup processes at all times.</p>
            </div>
          </div>

          <h2 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>Goal of Providing Data Backup and Recovery Services</h2>
          <div className='mb-5'>
          <DiscoverCard title="Ensure Data Protection and Security" paragraph="Our primary objective is to ensure the protection and security of your valuable data by implementing robust data backup and recovery solutions that safeguard your data against data loss, theft, or cyber attacks." />
          <DiscoverCard title="Minimize Downtime and Disruption" paragraph="We aim to minimize downtime and disruption to your business operations by providing fast and efficient data recovery services that enable you to recover your critical business data quickly and resume normal operations without delay." />
          <DiscoverCard title="Meet Compliance and Regulatory Requirements" paragraph="We help you meet compliance and regulatory requirements by ensuring that your data backup and recovery processes adhere to industry-specific regulations and standards, such as GDPR, HIPAA, and PCI DSS." />
          <DiscoverCard title="Provide Peace of Mind" paragraph="We strive to provide you with peace of mind knowing that your critical business data is protected and that you have a reliable plan in place to recover it quickly and efficiently in the event of a data loss event or disaster." />
          <DiscoverCard title="Deliver Exceptional Customer Satisfaction" paragraph="We are committed to delivering exceptional customer satisfaction by providing reliable, responsive, and high-quality data backup and recovery services that meet and exceed your expectations, ensuring that your data is protected and your business operations remain uninterrupted." />
          <br/>
          <DiscoverCard title="" paragraph="Partner with Bizz Buzz Creation for reliable and efficient data backup and recovery services that safeguard your critical business data and ensure business continuity. Contact us today to learn more about how we can help you achieve your data backup and recovery objectives!" />
        </div>
        </div>
      </div> */}
    </>
  )
}

export default TechSupport