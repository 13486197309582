import React from "react";
import { Facebook, Instagram, Linkedin, Twitter, Whatsapp } from "../SVG/svg";
import "./Footer.css"
import logo from "../../Assets/BBC Logo.png"
import { NavLink } from "react-router-dom";
import { Img } from "react-image";

const Footer = () => {
  return (
    <div className="footer-portion">
      {/* <h1 className="text-center font-30-res-20 weight-700">
        Growing Businesses since 2021
      </h1> */}
      <div className="d-flex justify-content-between footer-content">
        <div className="detail_portion w-30 text-left">
          <div className="logo_footer">
            <Img src={logo} alt="logo" className="logofooter" />
          </div>
          <p className="font-15-res-13 color-grey mt-25 footer-para">
            BizzBuzz Creations - 43/33, Tej Bahdur Sapru Rd, near Jain
            Electronic, Agnipath Colony, Civil Lines, Prayagraj, Uttar Pradesh -
            211001 · +91-8115585285
          </p>
          <div className="d-flex gap-5 mt-40 align-items-center">
              <NavLink to="https://www.linkedin.com/company/your-digital-instructor/"><Linkedin/></NavLink>
              <NavLink to="https://www.instagram.com/bizzbuzzcreations/?utm_source=ig_web_button_share_sheet"><Instagram/></NavLink>
              <NavLink to="https://www.facebook.com/profile.php?id=61560311200690&mibextid=ZbWKwL"><Facebook/></NavLink>
          </div>
        </div>
        <div className="table_portion d-flex align-items-start footer-lists">
          <div className="item1">
            <ul className="footer_list font-18-res-16">
              <NavLink to="/" className="footer_list_css"><li className="footer_tabs weight-500">Home</li></NavLink>
              <NavLink to="/about" className="footer_list_css"><li className="footer_tabs weight-500">About Us</li></NavLink>
              {/* <NavLink to="/blog" className="footer_list_css"><li className="footer_tabs weight-500">Blog</li></NavLink> */}
              <NavLink to="/service" className="footer_list_css"><li className="footer_tabs weight-500">Services</li></NavLink>
            </ul>
          </div>
          <div className="item2">
          <ul className="footer_list font-18-res-16">
          <NavLink to="/casestudy" className="footer_list_css"><li className="footer_tabs weight-500">Case Studies</li></NavLink>
          <NavLink to="/contact" className="footer_list_css"><li className="footer_tabs weight-500">Contact Us</li></NavLink>
          <NavLink to="/careers" className="footer_list_css"><li className="footer_tabs weight-500">Careers</li></NavLink>
            </ul>
          </div>
          <div className="item3">
          <ul className="footer_list font-18-res-16">
          <NavLink to="/TermandCondition" className="footer_list_css"><li className="footer_tabs weight-500">Terms and Condition</li></NavLink>
          <NavLink to="/PrivacyPolices" className="footer_list_css"><li className="footer_tabs weight-500">Privacy Policies</li></NavLink>
            </ul>
          </div>
        </div>
      </div>
      <p className="text-center mb-0 mt-2 text-secondary">&copy; BizzBuzz Creations. All Rights Reserved</p>
    </div>
  );
};

export default Footer;
