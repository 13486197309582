import React from 'react'
import "./UncoverCard.css"
import { Img } from 'react-image'

const UncoverCard = ({image_uncover,title,paragraph}) => {
  return (
    <div className=''>
    <div className='white_board'>
        <Img src={image_uncover} alt='' className='image_uncover'/>  
    </div>
    <h1 className='font-20-res-16 weight-600 text-center mt-2'>{title}</h1>
    <p className='font-18-res-16 weight-400 text-center'>{paragraph}</p>
    </div>
  )
}

export default UncoverCard