import React from "react";
import "./DigitalMarket.css";
import webImage from "../../Assets/image_main/Digital Marketing.jpg";
import DiscoverCard from "../../Component/DiscoverCard/DiscoverCard";
import ecomm from "../../Assets/ecomm.png";
import custom from "../../Assets/custom.png";
import UncoverCard from "../../Component/UncoverCard/UncoverCard";
import maintainImg from "../../Assets/maintenance.png";
import DiscoverCardCenter from "../../Component/DiscoverCardCenter/DiscoverCardCenter";
import Goals from "../../Component/Goals/Goals";
import SEO from "../../Assets/icon/SEO.jpg"
import SMM from "../../Assets/icon/SMM.jpg"
import PPC from "../../Assets/icon/PPC.jpg"
import Google_Ads from "../../Assets/icon/google ads.jpg"
import Email_Marketing from "../../Assets/icon/Email Marketing.jpg"
import Comprehensive_Approach from "../../Assets/icon/comprehensive approach.jpg"
import Data_Driven_Campaigns from "../../Assets/icon/data-driven campaign.jpg"
import Customized_Solutions from "../../Assets/icon/customized solution.jpg"
import Proven_Expertise from "../../Assets/icon/proven expertise.jpg"
import Focus_on_Measurable_Results from "../../Assets/icon/focus on measurable result.jpg"
import Continuous_Optimization from "../../Assets/icon/continuous optimization.jpg"
import Brand_Awareness from "../../Assets/image/brand awareness.jpg"
import Increase_Website_Traffic from "../../Assets/image/increase website traffic.jpg"
import Create_quality_prospects from "../../Assets/image/create quality prospects.jpg"
import Customer_involvement from "../../Assets/image/customer involvement.jpg"
import Conversion_Rates from "../../Assets/image/conversion rates.jpg"
import Measure_and_Analyze_Performance from "../../Assets/image/measure and analyze performance.jpg"
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
const DigitalMarket = () => {
  return (
    <>
      <Helmet>
        <title>Digital Marketing Services: SEO, SMM, PPC, Google Ads, Email Marketing</title>
        <meta
          name="description"
          content="Boost your online presence with top Digital Marketing Services: SEO, SMM, PPC, Google Ads & Email Marketing to drive targeted results."
        />
        <meta
          name="keywords"
          content="SEO services in Prayagraj, Best SEO services in Prayagraj, SMM services in Prayagraj, Best SMM services in Prayagraj, digital marketing services and agency in Prayagraj, digital marketing services and agency in Allahabad, best digital marketing agency in Allahabad, best digital marketing agency in Prayagraj, digital marketing in Allahabad, digital marketing in Prayagraj"
        />
        <meta name="author" content="BizzBuzzCreations" />
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-XVSSY0BR1E"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-XVSSY0BR1E');
        `}
        </script>
      </Helmet>
      <div className="webdevelpment_page d-flex align-items-center justify-content-center text-center flex_page">
        {/* <div className="image_portion_web img-width">
          <img src={webImage} alt="image" className="image_main" />
        </div> */}
        <div className="content_portion  p-15">
          <h2 className="weight-600 mb-3">
            DIGITAL MARKETING SOLUTIONS
          </h2>
          <h5 className="weight-500">
            Achieve Online Success with Our Digital Marketing Services
          </h5>
          <p className="weight-400 mb-5 mx-auto service-head-third">
            Achieve online success with our comprehensive digital marketing
            services, including SEO, SMM, PPC, and Google Ads. We create
            tailored strategies to enhance visibility, drive traffic, and boost
            conversions for your business.
          </p>
          <button className="get_button"><Link className="text-decoration-none" to="/contact">Get Started</Link></button>
        </div>
      </div>
      <div className="web_portion">
        <div className="head_portion">
          <h1 className="font-20-res-16 weight-600 text-center mb-5">
            Discover Why Bizz Buzz Creations Stands Out
          </h1>
        </div>
        <div className="grid_portion">
          <DiscoverCardCenter
            icon_image={SEO}
            title="SEO"
            paragraph="SEO (Search Engine Optimization) enhances website visibility on search engines. It involves keyword research, on-page optimization, link building, and content creation, driving organic traffic and improving user experience to increase conversions."
          />
          <DiscoverCardCenter
            icon_image={SMM}
            title="SMM"
            paragraph="Social Media Marketing (SMM) involves using social platforms to promote products and services. It enhances brand awareness, engages audiences, drives website traffic, and engages customer relationships through targeted content and advertising strategies."
          />
          <DiscoverCardCenter
            icon_image={PPC}
            title="PPC"
            paragraph="Pay-Per-Click (PPC) advertising is a digital marketing strategy where advertisers pay for each click on their ads. It boosts visibility, drives targeted traffic, and offers measurable results through various platforms."
          />
          <DiscoverCardCenter
            icon_image={Google_Ads}
            title="Google Ads"
            paragraph="Google Ads is a powerful online advertising platform that allows businesses to create targeted ads. It drives traffic through search results and display networks, maximizing visibility and increasing potential customer engagement."
          />
          <DiscoverCardCenter
            icon_image={Email_Marketing}
            title="Email Marketing"
            paragraph="Email marketing is a strategy where businesses send targeted emails to engage customers, promote products, and build relationships. It boosts brand awareness, drives conversions, and provides measurable results through analytics."

          />
        </div>
      </div>
      <div className='incover_portion pb-5'>
        <h1 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>Objectives of Our Digital Marketing Services</h1>
        <div className='grid_portion m-auto align-items-center justify-content-center '>
          <UncoverCard image_uncover={Brand_Awareness} title="Brand Awareness" paragraph="To draw in and keep the interest of a wider audience, make your brand visible and recognizable across a variety of social platforms." />
          <UncoverCard image_uncover={Increase_Website_Traffic} title="Increase Website Traffic" paragraph="To increase prospective leads and conversions, use paid advertising, content marketing, and SEO techniques to drive traffic to your website." />
          <UncoverCard image_uncover={Create_quality_prospects} title="Create quality prospects" paragraph="Run focused campaigns to draw in quality prospects, guaranteeing increased conversion rates and optimizing return on investment." />
          <UncoverCard image_uncover={Customer_involvement} title="Customer involvement" paragraph="Promote deep connections with your audience by using social media involvement, specific content, and efficient communication techniques." />
          <UncoverCard image_uncover={Conversion_Rates} title="Conversion Rates" paragraph="To convert website visitors into devoted clients, enhance user experience and apply conversion rate optimization strategies." />
          <UncoverCard image_uncover={Measure_and_Analyze_Performance} title="Measure and Analyze Performance" paragraph="Make data-driven decisions for ongoing strategy development and improvement by tracking and evaluating campaign performance with the use of analytics tools." />
        </div>

      </div>
      <div className='Page'>
        <h1 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>Why Choose BBC for Digital Marketing?</h1><div className="App">
          <div className="card-container container">
            <Goals logo_icon={Comprehensive_Approach} title="Comprehensive Approach" paragraph="BBC offers a comprehensive digital marketing strategy that integrates SEO, social media, email marketing, and PPC to create a unified brand presence across multiple platforms." />
            <Goals logo_icon={Data_Driven_Campaigns} title="Data-Driven Campaigns" paragraph="BBC leverages advanced analytics and performance tracking to create data-driven marketing campaigns, ensuring your marketing spend generates the highest ROI." />
            <Goals logo_icon={Customized_Solutions} title="Customized Solutions" paragraph="Understanding that every business has unique goals, BBC creates tailored digital marketing strategies that align with your specific needs and target audience." />
            <Goals logo_icon={Proven_Expertise} title="Proven Expertise" paragraph="With a team experienced in the latest digital marketing tools and trends, BBC stays ahead of the curve to implement cutting-edge techniques that drive engagement and conversions." />
            <Goals logo_icon={Focus_on_Measurable_Results} title="Focus on Measurable Results" paragraph="BBC prioritizes results that matter—whether it’s boosting traffic, improving conversion rates, or enhancing brand awareness, they provide regular reporting and clear metrics to track your success." />
            <Goals logo_icon={Continuous_Optimization} title="Continuous Optimization" paragraph="BBC ensures that your digital marketing efforts are consistently monitored and optimized based on performance, adapting strategies as needed to maximize impact." />
          </div>
        </div>
      </div>

      {/* <div className="discover_portion ">
        <h1 className="font-32-res-22 weight-600  text-center mt-5 mb-50">
          Goals of Bizz Buzz Creations
        </h1>
        <p className="font-18-res-16 weight-400 mb-40 mt-60">
          At Bizz Buzz Creation, our primary objective is to empower businesses
          to succeed in the digital world. Whether you're looking to increase
          brand awareness, generate leads, or drive sales, we are committed to
          delivering innovative digital marketing solutions that drive tangible
          results and fuel growth
        </p>
        <p className="font-18-res-16 weight-400 mt-30">
          Prepared to take your computerized presence to a higher level? Contact
          us today to learn more about our digital marketing services and how we
          can help your business thrive online
        </p>
      </div>
      <div style={{ background: "#EEEEEE", padding: "0 0 20px 0" }}>
        <div className="container">
          <div className="d-flex gap-5 align-items-center pt-5 flex_page">
            <div className="img-width">
              <img src={custom} alt="ecomm website" className="image" />
            </div>
            <div className="text-start">
              <h2 className="font-32-res-22 weight-600  text-center mt-5 mb-50">
                Search Engine Optimization
              </h2>
              <h4>
                Boost Your Business with Bizz Buzz Creation's Premier Service
              </h4>
              <p>
                At Bizz Buzz Creation, we're dedicated to helping your business
                thrive in the digital landscape. Our comprehensive suite of
                services is designed to elevate your online presence, drive
                traffic to your website, and ultimately increase your bottom
                line. Here's how partnering with us can benefit your business:
              </p>
            </div>
          </div>
          <h2 className="font-32-res-22 weight-600  text-center mt-5 mb-50">
          Why Choose BBC for Digital Marketing?
          </h2>
          <DiscoverCard
            title="Comprehensive Approach:"
            paragraph="BBC offers a comprehensive digital marketing strategy that integrates SEO, social media, email marketing, and PPC to create a unified brand presence across multiple platforms."
          />
          <DiscoverCard
            title="Data-Driven Campaigns:"
            paragraph="BBC leverages advanced analytics and performance tracking to create data-driven marketing campaigns, ensuring your marketing spend generates the highest ROI."
          />
          <DiscoverCard
            title="Customized Solutions:"
            paragraph=" Understanding that every business has unique goals, BBC creates tailored digital marketing strategies that align with your specific needs and target audience."
          />
          <DiscoverCard
            title="Proven Expertise:"
            paragraph="With a team experienced in the latest digital marketing tools and trends, BBC stays ahead of the curve to implement cutting-edge techniques that drive engagement and conversions."
          />
          <DiscoverCard
            title="Focus on Measurable Results: "
            paragraph=" BBC prioritizes results that matter—whether it’s boosting traffic, improving conversion rates, or enhancing brand awareness, they provide regular reporting and clear metrics to track your success."
          />
          <DiscoverCard 
          title="Continuous Optimization:"
          paragraph="BBC ensures that your digital marketing efforts are consistently monitored and optimized based on performance, adapting strategies as needed to maximize impact."
          />

          <h2 className="font-32-res-22 weight-600  text-center mt-5 mb-50">
            How BBC can help you ?
          </h2>
          <div
            className="grid_portion"
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: "10px",
              textAlign: "center",
            }}
          >
            <div>
              <h3 className="font-20-res-16 weight-600">Drive Growth</h3>
              <p className="font-18-res-16 weight-400 mb-5">
                By increasing your online visibility and attracting qualified
                leads, we help fuel the growth of your business, enabling you to
                reach new heights of success.
              </p>
            </div>
            <div>
              <h3 className="font-20-res-16 weight-600">
                Build Brand Authority
              </h3>
              <p className="font-18-res-16 weight-400 mb-5">
                Our strategic approach to digital marketing not only generates
                leads but also enhances your brand's reputation and authority in
                your industry, fostering trust and credibility among your target
                audience.
              </p>
            </div>
            <div>
              <h3 className="font-20-res-16 weight-600">Maximize ROI</h3>
              <p className="font-18-res-16 weight-400 mb-5">
                With our cost-effective solutions and focus on delivering
                tangible results, you can maximize your return on investment and
                allocate your marketing budget more efficientl.
              </p>
            </div>
            <div>
              <h3 className="font-20-res-16 weight-600">
                Stay Ahead of the Competition
              </h3>
              <p className="font-18-res-16 weight-400 mb-5">
                In today's competitive landscape, staying ahead of the curve is
                essential. With Bizz Buzz Creation as your partner, you'll
                benefit from cutting-edge strategies and industry insights that
                give you a competitive edge.
              </p>
            </div>
          </div>

          <h2 className="font-32-res-22 weight-600  text-center mt-5 mb-50">
          Objectives of Our Digital Marketing Services
          </h2>
          <DiscoverCard
            title="Brand Awareness:"
            paragraph="To draw in and keep the interest of a wider audience, make your brand visible and recognizable across a variety of social platforms."
          />
          <DiscoverCard
            title="Increase Website Traffic:"
            paragraph="To increase prospective leads and conversions, use paid advertising, content marketing, and SEO techniques to drive traffic to your website."
          />
          <DiscoverCard
            title="Create quality prospects:"
            paragraph="Run focused campaigns to draw in quality prospects, guaranteeing increased conversion rates and optimizing return on investment."
          />
          <DiscoverCard
            title="Customer involvement:"
            paragraph="Promote deep connections with your audience by using social media involvement, specific content, and efficient communication techniques."
          />
          <DiscoverCard
            title="Conversion Rates:"
            paragraph="To convert website visitors into devoted clients, enhance user experience and apply conversion rate optimization strategies."
          />
          <DiscoverCard 
          title="Measure and Analyze Performance:"
          paragraph="Make data-driven decisions for ongoing strategy development and improvement by tracking and evaluating campaign performance with the use of analytics tools."
          />
        </div>
      </div>

      <div style={{ background: "#d4dfe3", padding: "0 0 20px 0" }}>
        <div className="container">
          <div className="d-flex gap-5 align-items-center pt-5 flex_page">
            <div className="img-width">
              <img src={ecomm} alt="search website" className="image" />
            </div>
            <div className="text-start">
              <h2 className="font-32-res-22 weight-600  text-center mt-5 mb-50">
                Social Media Management
              </h2>
              <p>
                Welcome to Bizz Buzz Creation, where we specialize in providing
                top-notch Social Media Management services tailored to meet the
                unique needs of your business. In today's digital age, a strong
                social media presence is essential for building brand awareness,
                engaging with customers, and driving growth. Our expert team is
                here to manage your social media platforms, creating compelling
                content and strategies that resonate with your audience.
              </p>
            </div>
          </div>
          <h2 className="font-32-res-22 weight-600  text-center mt-5 mb-50">
            Why Choose Bizz Buzz Creation for Social Media Management? Let's
            Dive In!
          </h2>
          <DiscoverCard
            title="Increased Brand Awareness"
            paragraph="Our strategies ensure your brand is consistently visible across multiple social media platforms, helping you reach a wider audience and establish a strong online presence."
          />
          <DiscoverCard
            title="Enhanced Customer Engagement"
            paragraph="We create and curate engaging content that encourages interaction, fostering a community around your brand and building strong customer relationships. We enhance your site's speed, lessening skip rates and further developing client commitment.. Our services free up your time, allowing you to focus on other important aspects of your business while we handle your social media needs."
          />
          <DiscoverCard
            title="Consistent Posting Schedule"
            paragraph="Regular posting is key to maintaining an active social media presence. We ensure your accounts are consistently updated with fresh, relevant content to keep your audience engaged."
          />
          <DiscoverCard
            title="Professional Content Creation"
            paragraph="Our team of skilled content creators produces high-quality visuals, videos, and written content that reflect your brand's identity and captivate your audience."
          />

          <h2 className="font-32-res-22 weight-600  text-center mt-5 mb-50">
            How our Website Maintenance and Support Service can help you?
          </h2>
          <div
            className="grid_portion"
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: "10px",
              textAlign: "center",
            }}
          >
            <div>
              <h3 className="font-20-res-16 weight-600">
                Customized Social Media Strategy
              </h3>
              <p className="font-18-res-16 weight-400 mb-5">
                We develop a tailored social media strategy that aligns with
                your business goals, target audience, and brand voice, ensuring
                your content resonates with your followers.
              </p>
            </div>
            <div>
              <h3 className="font-20-res-16 weight-600">
                Content Planning and Creation
              </h3>
              <p className="font-18-res-16 weight-400 mb-5">
                Our team plans, creates, and schedules posts that are not only
                visually appealing but also strategically designed to engage
                your audience and promote your brand effectively.
              </p>
            </div>
            <div>
              <h3 className="font-20-res-16 weight-600">
                Reputation Management
              </h3>
              <p className="font-18-res-16 weight-400 mb-5">
                We monitor and manage your online reputation, addressing any
                negative feedback professionally and highlighting positive
                customer experiences to build trust and credibility.
              </p>
            </div>
            <div>
              <h3 className="font-20-res-16 weight-600">
                Audience Analysis and Engagement
              </h3>
              <p className="font-18-res-16 weight-400 mb-5">
                We analyze your audience demographics and behavior to create
                content that appeals to them. Additionally, we actively engage
                with your followers, responding to comments and messages to
                build a strong online community.
              </p>
            </div>
            <div>
              <h3 className="font-20-res-16 weight-600">
                Performance Tracking and Reporting
              </h3>
              <p className="font-18-res-16 weight-400 mb-5">
                We monitor the performance of your social media campaigns,
                providing detailed reports on key metrics such as engagement,
                reach, and follower growth. This helps us refine our strategies
                and ensure continuous improvement.
              </p>
            </div>
            <div>
              <h3 className="font-20-res-16 weight-600">
                Social Media Advertising
              </h3>
              <p className="font-18-res-16 weight-400 mb-5">
                We manage targeted social media advertising campaigns that
                increase your reach, drive traffic to your website, and generate
                leads, all while maximizing your return on investment.
              </p>
            </div>
          </div>

          <h2 className="font-32-res-22 weight-600  text-center mt-5 mb-50">
            Objectives of Providing Social Media Management services
          </h2>
          <DiscoverCard
            title="Build a Strong Online Presence"
            paragraph="Our primary goal is to establish and maintain a strong online presence for your brand, ensuring you are visible and active on the social media platforms that matter most to your audience.."
          />
          <DiscoverCard
            title="Increase Brand Loyalty and Trust"
            paragraph="By engaging with your audience and providing valuable content, we aim to build loyalty and trust, turning followers into loyal customers and brand advocates."
          />
          <DiscoverCard
            title="Drive Traffic and Conversions"
            paragraph="Our strategies are designed to not only engage your audience but also drive traffic to your website and convert social media interactions into tangible business results."
          />
          <DiscoverCard
            title="Enhance Customer Satisfaction"
            paragraph="By actively managing your social media interactions, we ensure timely and professional responses to customer inquiries and feedback, enhancing overall customer satisfaction."
          />
          <DiscoverCard
            title="Adapt and Innovate"
            paragraph="Social media trends and algorithms are constantly evolving. We stay up-to-date with the latest changes and innovations, adapting our strategies to keep your brand ahead of the curve."
          />
          <DiscoverCard
            title=" "
            paragraph="Partner with Bizz Buzz Creation to elevate your social media presence and achieve your business goals. Our expert Social Media Management services are designed to create meaningful connections with your audience and drive your brand’s success. Contact us today to learn more about how we can help you harness the power of social media!."
          />
        </div>
      </div>

      <div style={{ background: "#EEEEEE", padding: "0 0 20px 0" }}>
        <div className="container">
          <div className="d-flex gap-5 align-items-center pt-5 flex_page">
            <div className="img-width">
              <img src={maintainImg} alt="social website" className="image" />
            </div>
            <div className="text-start">
              <h2 className="font-32-res-22 weight-600  text-center mt-5 mb-50">
                Social Media Advertising
              </h2>
              <p>
                Welcome to Bizz Buzz Creation, your premier partner for dynamic
                and effective Social Media Advertising. In an era where social
                media is a primary platform for consumer engagement, our expert
                services are designed to help you leverage this powerful tool to
                its fullest potential. We create and manage targeted advertising
                campaigns that drive brand awareness, engagement, and
                conversions, ensuring your business stands out in the crowded
                digital landscape.
              </p>
            </div>
          </div>
          <h2 className="font-32-res-22 weight-600  text-center mt-5 mb-50">
            Advantages of using our Social Media Advertising services
          </h2>
          <DiscoverCard
            title="Precise Targeting"
            paragraph="Our advanced targeting capabilities allow us to reach your ideal audience based on demographics, interests, behaviors, and location. This ensures your ads are seen by the people most likely to engage with your brand and become customers."
          />
          <DiscoverCard
            title="Cost-Effective Marketing"
            paragraph="Social media advertising offers excellent ROI by allowing you to set and control your budget. We optimize your ad spend to ensure maximum impact and cost-efficiency."
          />
          <DiscoverCard
            title="Enhanced Brand Visibility"
            paragraph="Through strategic ad placements and creative design, we increase your brand’s visibility on platforms like Facebook, Instagram, Twitter, LinkedIn, and more, putting your business in front of a broader audience."
          />
          <DiscoverCard
            title="Real-Time Analytics"
            paragraph="We provide comprehensive analytics and performance tracking, giving you insights into how your ads are performing. . This information driven approach takes into consideration nonstop improvement and improved results."
          />
          <DiscoverCard
            title="Creative Content"
            paragraph="Our team of creative professionals designs compelling ad content that captures attention and drives action.From eye-getting visuals to influential duplicate, we guarantee your promotions stick out"
          />
          <h2 className="font-32-res-22 weight-600  text-center mt-5 mb-50">
            How our Social Media Advertising Services Can Help You
          </h2>
          <div
            className="grid_portion"
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: "10px",
              textAlign: "center",
            }}
          >
            <div>
              <h3 className="font-20-res-16 weight-600">Custom Ad Campaigns</h3>
              <p className="font-18-res-16 weight-400 mb-5">
                We develop tailored ad campaigns that align with your business
                goals, whether you aim to increase brand awareness, drive
                website traffic, generate leads, or boost sales.
              </p>
            </div>
            <div>
              <h3 className="font-20-res-16 weight-600">
                Audience Research and Segmentation
              </h3>
              <p className="font-18-res-16 weight-400 mb-5">
                We conduct thorough research to understand your target audience
                and segment them effectively. This considers more customized and
                pertinent promotion encounters
              </p>
            </div>
            <div>
              <h3 className="font-20-res-16 weight-600">A/B Testing</h3>
              <p className="font-18-res-16 weight-400 mb-5">
                To maximize ad performance, we implement A/B testing of various
                ad elements such as headlines, images, and calls to action. This
                helps identify the most effective combinations for your
                campaign.
              </p>
            </div>
            <div>
              <h3 className="font-20-res-16 weight-600">
                Engagement Strategies
              </h3>
              <p className="font-18-res-16 weight-400 mb-5">
                {" "}
                Beyond clicks and impressions, we focus on driving meaningful
                engagement with your audience. Our ads are designed to encourage
                interactions, shares, and comments, fostering a deeper
                connection with your brand.
              </p>
            </div>
            <div>
              <h3 className="font-20-res-16 weight-600">Budget Management</h3>
              <p className="font-18-res-16 weight-400 mb-5">
                We manage your advertising budget efficiently, ensuring that
                every dollar is spent wisely. Our goal is to achieve the highest
                possible return on your investment.
              </p>
            </div>
            <div>
              <h3 className="font-20-res-16 weight-600">
                Ongoing Optimization
              </h3>
              <p className="font-18-res-16 weight-400 mb-5">
                Advertising in the digital space is not a set-and-forget
                process. We continually monitor and adjust your campaigns to
                improve performance and adapt to changing trends and algorithms.
              </p>
            </div>
          </div>

          <h2 className="font-32-res-22 weight-600  text-center mt-5 mb-50">
            Objectives of Providing Website Maintenance and Support Service
          </h2>
          <DiscoverCard
            title="Increase Brand Awareness"
            paragraph="Our primary goal is to increase your brand’s visibility and recognition. By reaching a larger and more targeted audience, we help build awareness and familiarity with your brand."
          />
          <DiscoverCard
            title="Drive Quality Traffic"
            paragraph="We aim to drive high-quality traffic to your website or landing pages, ensuring visitors are genuinely interested in your products or services and more likely to convert."
          />
          <DiscoverCard
            title="Boost Engagement"
            paragraph="Through engaging and interactive ads, we seek to foster a strong connection with your audience, encouraging them to interact with your brand and become loyal followers."
          />
          <DiscoverCard
            title="Generate Leads and Sales"
            paragraph="Our focused strategies are designed to convert social media users into leads and customers. By targeting users at different stages of the buying cycle, we help drive tangible business results."
          />
          <DiscoverCard
            title="Enhance ROI"
            paragraph="Our data-driven approach ensures that your advertising budget is used efficiently, delivering the highest possible return on investment through continuous monitoring and optimization."
          />
          <DiscoverCard
            title=" "
            paragraph="Partner with Bizz Buzz Creation to harness the power of social media advertising and achieve your business objectives. Our expert services are designed to create impactful campaigns that drive growth and success. Contact us today to learn more about how we can elevate your social media advertising efforts!."
          />
        </div>
      </div>

      <div style={{ background: "#d4dfe3", padding: "20px 0 20px 0" }}>
        <div className="container">
          <div className="d-flex gap-5 align-items-center pt-5 flex_page">
            <div className="img-width">
              <img
                src={maintainImg}
                alt="website maintenance"
                className="image"
              />
            </div>
            <div className="text-start">
              <h2 className="font-32-res-22 weight-600  text-center mt-5 mb-50">
                Pay-Per-Click (PPC) Marketing
              </h2>
              <p>
                Welcome to Bizz Buzz Creation, your trusted partner for
                effective and results-driven Pay-Per-Click (PPC) marketing
                services. In the fast-paced digital marketplace, PPC advertising
                is a powerful tool that can deliver immediate traffic and leads
                to your business. Our team of experts is dedicated to creating
                and managing PPC campaigns that maximize your return on
                investment and drive your business growth.
              </p>
            </div>
          </div>
          <h2 className="font-32-res-22 weight-600  text-center mt-5 mb-50">
            Advantages of using our PPC Marketing Services
          </h2>
          <DiscoverCard
            title="Immediate Results"
            paragraph="Unlike organic search strategies that can take time to show results, PPC campaigns generate immediate visibility and traffic. This quick impact is ideal for businesses looking to achieve rapid growth."
          />
          <DiscoverCard
            title="Targeted Advertising"
            paragraph="PPC allows for precise targeting based on keywords, demographics, location, and even time of day. This ensures your ads are seen by the most relevant audience, increasing the chances of conversion."
          />
          <DiscoverCard
            title="Cost Control"
            paragraph="With PPC, you have unlimited authority over your spending plan.You set the maximum amount you're willing to pay for a click, and you only pay when someone clicks on your ad. This ensures your advertising spend is efficient and effective."
          />
          <DiscoverCard
            title="Quantifiable Execution"
            paragraph="PPC gives point by point examination and revealing, permitting you to follow the presentation of your missions continuously. This information driven approach empowers nonstop improvement for improved results."
          />
          <DiscoverCard
            title="Brand Visibility"
            paragraph="PPC ads appear at the top of search engine results pages (SERPs) and across various online platforms, significantly boosting your brand’s visibility and recognition."
          />

          <h2 className="font-32-res-22 weight-600  text-center mt-5 mb-50">
            How our PPC Marketing Services Can Help You
          </h2>
          <div
            className="grid_portion"
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: "10px",
              textAlign: "center",
            }}
          >
            <div>
              <h3 className="font-20-res-16 weight-600">
                Customized Campaigns
              </h3>
              <p className="font-18-res-16 weight-400 mb-5">
                We design PPC campaigns tailored to your specific business
                goals, whether it's increasing website traffic, generating
                leads, or boosting sales. Our personalized approach ensures your
                ads align with your objectives.
              </p>
            </div>
            <div>
              <h3 className="font-20-res-16 weight-600">
                Landing Page Optimization
              </h3>
              <p className="font-18-res-16 weight-400 mb-5">
                To increase conversion rates, we ensure your landing pages are
                optimized for user experience and relevance to the ad content.
                This seamless transition from ad to landing page enhances user
                satisfaction and conversion likelihood.
              </p>
            </div>
            <div>
              <h3 className="font-20-res-16 weight-600">
                Keyword Research and Selection
              </h3>
              <p className="font-18-res-16 weight-400 mb-5">
                Our team conducts thorough keyword research to identify the most
                relevant and high-performing keywords for your business. This
                ensures your ads target the right audience and drive qualified
                traffic.
              </p>
            </div>
            <div>
              <h3 className="font-20-res-16 weight-600">
                Performance Tracking and Reporting
              </h3>
              <p className="font-18-res-16 weight-400 mb-5">
                We provide comprehensive reports on your campaign performance,
                including metrics such as click-through rates (CTR), conversion
                rates, and cost-per-click (CPC). This transparency allows you to
                see the value of your investment clearly.
              </p>
            </div>
            <div>
              <h3 className="font-20-res-16 weight-600">Bid Management</h3>
              <p className="font-18-res-16 weight-400 mb-5">
                We use advanced bid management strategies to ensure you get the
                best possible return on your investment. Our goal is to maximize
                your ad spend efficiency and achieve the highest possible ROI.
              </p>
            </div>
          </div>

          <h2 className="text-center mb-5 pt-5">
            Objectives of Providing Website Maintenance and Support Service
          </h2>
          <div className="mb-5">
            <DiscoverCard
              title="Boost Traffic and Conversions"
              paragraph="Our primary goal is to drive targeted traffic to your website and convert that traffic into leads or sales. By focusing on high-intent keywords and optimizing ad performance, we aim to increase your conversion rates."
            />
            <DiscoverCard
              title="Maximize ROI"
              paragraph="We strive to deliver the highest possible return on your PPC investment. Through careful management and optimization of your campaigns, we ensure your ad spend is used efficiently and effectively."
            />
            <DiscoverCard
              title="Increase Brand Awareness"
              paragraph="By placing your ads at the top of search results and across various online platforms, we help increase your brand’s visibility and recognition among potential customers."
            />
            <DiscoverCard
              title="Provide Actionable Insights"
              paragraph="Our detailed reporting provides valuable insights into your campaign’s performance, helping you make informed decisions about your marketing strategies and future investments."
            />
            <DiscoverCard
              title="Enhance Competitive Edge"
              paragraph="PPC advertising allows you to compete effectively in your market by ensuring your ads appear alongside or above those of your competitors. This visibility helps you capture more market share and grow your business."
            />
            <br />
            <DiscoverCard
              title=""
              paragraph="Partner with Bizz Buzz Creation to harness the power of PPC marketing and achieve your business goals. Our expert services are designed to drive targeted traffic, increase conversions, and maximize your ROI. Contact us today to learn more about how we can elevate your PPC advertising efforts!"
            />
          </div>
        </div>
      </div> */}
    </>
  );
};

export default DigitalMarket;
