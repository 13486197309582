import React from 'react'
import "./Goals.css"
import { Img } from 'react-image'

const  Goals = ({title,paragraph,logo_icon}) => {
  return (
    <div className='block_res'>
        <div className='icon_portion'>
            <Img src={logo_icon} alt='logo' className='image_goals'/>
        </div>
        <div className='content_portion'>
            <h1 className='font-20-res-16 weight-600'>{title}</h1>
            <p className='font-18-res-16 weight-400'>{paragraph}</p>
        </div>
    </div>
  )
}

export default Goals