import React from 'react';
import './TestimonialCard.css'; // Import CSS file for styling
import { FaQuoteRight } from "react-icons/fa6";
import { Img } from 'react-image';

const TestimonialCard = ({ imgUrl, name, desc }) => {
  return (
    <div className="testimonial-card">
      <div className="testimonial-img-container">
        <Img src={imgUrl}  className="testimonial-img" />
      </div>
      <div className="testimonial-content">
        <span><FaQuoteRight /></span>
        <p className="testimonial-name">{desc}</p>
        <h5 className='weight-500'>~{name}</h5>
      </div>
    </div>
  );
}

export default TestimonialCard;