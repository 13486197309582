import React from 'react'
import "./DiscoverCardCenter.css"
import { Img } from 'react-image'
const DiscoverCardCenter = ({title,paragraph,icon_image}) => {
  return (
    <div className='common_card'>
        <div className='common_icon'>
          <Img src={icon_image} className='image_icon' alt='icon'/>
        </div>
        <h1 className='font-20-res-16 weight-600'>{title}</h1>
        <p className='font-18-res-16 weight-400 mt-2'>{paragraph}</p>
    </div>
  )
}

export default DiscoverCardCenter