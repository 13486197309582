import React from 'react'
import ecomm from '../../Assets/ecomm.png';
import search from '../../Assets/undraw_working_re_ddwy 1.png'
import social from '../../Assets/custom.png'
import UncoverCard from '../../Component/UncoverCard/UncoverCard';
import webImage from "../../Assets/image_main/Office Space Consultant.jpg"
import DiscoverCard from '../../Component/DiscoverCard/DiscoverCard';
import officeimge1 from "../../Assets/image/enhance brand image.jpg"
import officeimge2 from "../../Assets/image/achieve business goals.jpg"
import officeimge3 from "../../Assets/image/engage participants.jpg"
import officeimge4 from "../../Assets/image/ensure seanmless execution.jpg"
import officeimge5 from "../../Assets/image/provide tailored experience.jpg"
import officeimge6 from "../../Assets/image/facilitate knowledge sharing.jpg"
import officeimge7 from "../../Assets/image/gather valuable feedback.jpg"
import officeimge8 from "../../Assets/image/maximize ROI.jpg"
import DiscoverCardCenter from '../../Component/DiscoverCardCenter/DiscoverCardCenter';
import Goals from '../../Component/Goals/Goals';
import Event_Planning from "../../Assets/icon/Event planning and strategy.jpg"
import Venue_Selection from "../../Assets/icon/venue selection and management.jpg"
import Budget_Management from "../../Assets/icon/budget Management.jpg"
import Vendor_Coordination from "../../Assets/icon/vendor coordination.jpg"
import Registration_and_Attendee from "../../Assets/icon//registration and attendee management.jpg"
import Event_Promotion from "../../Assets/icon/event promotion and marketing.jpg"
import Experienced_Professionals from "../../Assets/icon/experienced professionsal.jpg"
import Personalized_Solutions from "../../Assets/icon/personalized solution.jpg"
import Comprehensive_Planning from "../../Assets/icon/comprehansive planning.jpg"
import Strong_Vendor from "../../Assets/icon/strong vendor relationships.jpg"
import Attention_to_Detail from "../../Assets/icon/attention to details.jpg"
import Focus_on_Engagement from "../../Assets/icon/focus on engagement.jpg"
import Post_Event_Support from "../../Assets/icon/post-event support.jpg"
import Commitment_to_Success from "../../Assets/icon/commitment to success.jpg"
import Program_development from "../../Assets/icon/program development.jpg"
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

function OfficeSpace() {
  return (
    <>
      <Helmet>
        <title>Corporate Event Management | Strategic Corporate Management</title>
        <meta
          name="description"
          content="With our strategic event management services, you can turn business meetings into memorable events with perfect execution."
        />
        <meta
          name="keywords"
          content="web development services in prayagraj, best website development in prayagraj, SMM services in prayagraj, best SMM services in prayagraj, digital marketing services and agency in prayagraj, best digital marketing agency in prayagraj, digital marketing in prayagraj, cloud computing services in prayagraj, financial advisory services in prayagraj, BPO Outsourcing services in Prayagraj"
        />
        <meta name="author" content="BizzBuzzCreations" />
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-XVSSY0BR1E"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-XVSSY0BR1E');
        `}
        </script>
      </Helmet>
      <div className='webdevelpment_page d-flex align-items-center justify-content-center text-center flex_page'>
        {/* <div className='image_portion_web img-width'>
        <img src={webImage} alt='image' className='image_main' />
      </div> */}
        <div className='content_portion p-15'>
          <h2 className='weight-600 mb-3'>Corporate Event Management</h2>
          <h5 className='weight-600'>Welcome to Bizz Buzz Creation - Your companion in Corporate Event Management</h5>
          <p className='weight-400 mb-5'>Corporate event management involves planning, organizing, and executing business events, such as conferences, meetings, and workshops, ensuring a seamless experience that aligns with corporate objectives and enhances brand reputation</p>
          <button className="get_button"><Link className="text-decoration-none" to="/contact">Get Started</Link></button>
        </div>
      </div>
      <div className='web_portion'>
        <div className='head_portion'>
          <h1 className='font-20-res-16 weight-600 text-center mb-5'>Corporate Event Management Services We Offer</h1>
        </div>
        <div className='grid_portion'>
          <DiscoverCardCenter icon_image={Event_Planning} title="Event Planning and Strategy" paragraph="Event planning and strategy involve defining objectives, themes, and logistics for an event, creating comprehensive plans to ensure successful execution, align with goals, and deliver memorable experiences for attendees." />
          <DiscoverCardCenter icon_image={Venue_Selection} title="Venue Selection and Management" paragraph="Venue selection and management involve identifying, evaluating, and securing appropriate locations for events, negotiating contracts, and overseeing logistics to ensure a suitable environment that meets specific event requirements and goals." />
          <DiscoverCardCenter icon_image={Budget_Management} title="Budget Management" paragraph="Budget management involves planning, monitoring, and controlling financial resources to ensure that spending aligns with organizational goals. It optimizes resource allocation, minimizes costs, and enhances overall financial performance" />
          <DiscoverCardCenter icon_image={Vendor_Coordination} title="Vendor Coordination" paragraph="Vendor coordination involves managing relationships with suppliers and service providers to ensure timely delivery of goods and services, overseeing contracts, and facilitating communication to meet project requirements and objectives." />
          <DiscoverCardCenter icon_image={Registration_and_Attendee} title="Registration and Attendee Management" paragraph="Registration and attendee management involves creating systems for event sign-ups, managing guest lists, providing communication and support, and ensuring a seamless check-in process to enhance the overall event experience." />
          <DiscoverCardCenter icon_image={Event_Promotion} title="Event Promotion and Marketing" paragraph="Event promotion and marketing involve strategizing and implementing campaigns to create awareness and attract attendees to an event, utilizing channels like social media, email, advertising, and partnerships to maximize reach." />
          <DiscoverCardCenter icon_image={Program_development} title="Program Development" paragraph="Program development involves designing and implementing structured activities or courses tailored to specific objectives, enhancing knowledge and skills while promoting engagement and collaboration among participants in various settings." />
          {/* <DiscoverCardCenter title="" paragraph=""/>
        <DiscoverCardCenter title="" paragraph=""/>
        <DiscoverCardCenter title="" paragraph=""/>
        <DiscoverCardCenter title="" paragraph=""/>
        <DiscoverCardCenter title="" paragraph=""/> */}
        </div>

      </div>
      <div className='incover_portion pb-5'>
        <h1 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>Objectives of Providing Services</h1>
        <div className='grid_portion m-auto align-items-center justify-content-center '>
          <UncoverCard image_uncover={officeimge1} title="Enhance Brand Image" paragraph="Create memorable and professional events that reflect the company’s values and enhance its reputation in the industry." />
          <UncoverCard image_uncover={officeimge2} title="Achieve Business Goals" paragraph="Align events with organizational objectives, such as product launches, networking opportunities, and team-building activities, to drive business success." />
          <UncoverCard image_uncover={officeimge3} title="Engage Participants" paragraph="Promote meaningful interactions among attendees, encouraging networking and collaboration to strengthen professional relationships and partnerships." />
          <UncoverCard image_uncover={officeimge4} title="Ensure Seamless Execution" paragraph="Deliver meticulously planned events that run smoothly, minimizing disruptions and maximizing participant satisfaction through effective logistics and management." />
          <UncoverCard image_uncover={officeimge5} title="Provide Tailored Experiences" paragraph="Customize events to meet the unique needs and preferences of clients and attendees, ensuring relevance and engagement for all participants." />
          <UncoverCard image_uncover={officeimge6} title="Facilitate Knowledge Sharing" paragraph="Create platforms for sharing information, insights, and best practices through workshops, presentations, and discussions, promoting professional development and growth." />
          <UncoverCard image_uncover={officeimge7} title="Gather Valuable Feedback" paragraph="Collect participant feedback to evaluate event success and identify areas for improvement, ensuring continuous enhancement of future events." />
          <UncoverCard image_uncover={officeimge8} title="Maximize ROI" paragraph="Ensure efficient budget management and resource allocation to deliver high-quality events that provide significant returns on investment for the organization." />
        </div>

      </div>
      <div className='Page'>
        <h1 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>Why Choose BBC for Corporate Event Management Services ?</h1><div className="App">
          <div className="card-container container">
            <Goals logo_icon={Experienced_Professionals} title="Experienced Professionals" paragraph="BBC boasts a team of seasoned event managers with extensive experience in organizing a wide range of corporate events, ensuring high standards of execution." />
            <Goals logo_icon={Personalized_Solutions} title="Personalized Solutions" paragraph="We understand that every organization has unique needs, so we customize our event management services to align with your specific objectives and preferences." />
            <Goals logo_icon={Comprehensive_Planning} title="Comprehensive Planning" paragraph="From concept development to post-event evaluation, BBC offers end-to-end services that cover every aspect of event management, ensuring a seamless experience." />
            <Goals logo_icon={Strong_Vendor} title="Strong Vendor Relationships" paragraph="Through our established relationships with trusted vendors for catering, audiovisuals, and logistics, we are able to secure high-quality resources at competitive prices, ensuring excellent service and value for your event." />
            <Goals logo_icon={Attention_to_Detail} title="Attention to Detail" paragraph="We take great pride in our meticulous approach to planning and execution, ensuring that every detail is carefully considered and nothing is overlooked, leading to a seamless and flawless event experience for all attendees." />
            <Goals logo_icon={Focus_on_Engagement} title="Focus on Engagement" paragraph="BBC specializes in creating engaging and interactive events that involve participant involvement and networking, enhancing the overall impact of your corporate gathering." />
            <Goals logo_icon={Post_Event_Support} title="Post-Event Support" paragraph="We provide thorough post-event analysis and feedback collection, helping you assess the event's success and identify opportunities for improvement in future events." />
            <Goals logo_icon={Commitment_to_Success} title="Commitment to Success" paragraph="Our dedicated team is focused on achieving your event goals, working collaboratively with you to ensure every detail aligns with your vision and objectives, creating a successful and memorable event experience." />
          </div>
        </div>
      </div>
      {/* <div style={{ background: "#d4dfe3", padding: "0 0 20px 0" }}>
        <div className='container'>
          <div className='d-flex gap-5 align-items-center pt-5 flex_page'>
            <div className='img-width'>
            <img src={ecomm} alt="ecomm website" className='image'/>
            </div>
            <div className='text-start'>
              <h2 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>SpacePlanning and Design</h2>
              <p className='font-18-res-16 weight-400'>Welcome to Bizz Buzz Creation, your premier destination for expert space planning and design services. We understand that the layout and design of your workspace can significantly impact productivity, collaboration, and employee satisfaction. Our team of experienced designers is dedicated to creating functional, aesthetically pleasing environments that reflect your brand identity and support your business goals:</p>
            </div>
          </div>
          <h2 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>Why Choose BBC for Corporate Event Management Services ?</h2>
          <DiscoverCard title="Experienced Professionals" paragraph="BBC boasts a team of seasoned event managers with extensive experience in organizing a wide range of corporate events, ensuring high standards of execution." />
          <DiscoverCard title="Personalized Solutions" paragraph="We understand that every organization has unique needs, so we customize our event management services to align with your specific objectives and preferences." />
          <DiscoverCard title="Comprehensive Planning" paragraph="From concept development to post-event evaluation, BBC offers end-to-end services that cover every aspect of event management, ensuring a seamless experience." />
          <DiscoverCard title="Strong Vendor Relationships" paragraph="Our established relationships with reliable vendors for catering, audiovisuals, and logistics allow us to secure the best resources at competitive prices." />
          <DiscoverCard title="Attention to Detail" paragraph=" We pride ourselves on meticulous planning and execution, ensuring that no detail is overlooked, resulting in a flawless event experience for all attendees." />
          <DiscoverCard title="Focus on Engagement" paragraph=" BBC specializes in creating engaging and interactive events that involve participant involvement and networking, enhancing the overall impact of your corporate gathering."/>
          <DiscoverCard title="Post-Event Support" paragraph="We provide thorough post-event analysis and feedback collection, helping you assess the event's success and identify opportunities for improvement in future events."/>
          <DiscoverCard title="Commitment to Success" paragraph="Our dedicated team is committed to achieving your event goals, working closely with you to ensure that the event aligns with your vision and objectives."/>
          <h2 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>How our Space Planning and Design services can help you ?</h2>
          <div className='grid_portion' style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)",  gap: "10px", textAlign: "center" }}>
            <div>
              <h3 className='font-20-res-16 weight-600'>Needs Assessment</h3>
              <p className='font-18-res-16 weight-400'>We start by understanding your business objectives, workflow processes, and space requirements through thorough consultations and assessments.</p>
            </div>
            <div>
              <h3 className='font-20-res-16 weight-600'>3D Visualization</h3>
              <p className='font-18-res-16 weight-400'> We provide realistic 3D renderings and visualizations of our design concepts, allowing you to envision the final result and make informed decisions before implementation.</p>
            </div>
            <div>
              <h3 className='font-20-res-16 weight-600'>Space Analysis</h3>
              <p className='font-18-res-16 weight-400'> Our team conducts a detailed analysis of your existing space to identify opportunities for improvement and optimization. We consider factors such as traffic flow, departmental interactions, and future growth projections.</p>
            </div>
            <div>
              <h3 className='font-20-res-16 weight-600'>Project Management</h3>
              <p className='font-18-res-16 weight-400'>From concept development to final implementation, we oversee every aspect of the design process to ensure seamless execution and timely delivery. Our project management expertise minimizes disruptions to your business operations and ensures quality control throughout the project lifecycle.</p>
            </div>
            <div>
              <h3 className='font-20-res-16 weight-600'>Customized Design Solutions</h3>
              <p className='font-18-res-16 weight-400'>Based on your needs and preferences, we develop customized design concepts that align with your brand identity and functional requirements. Our designs prioritize flexibility, sustainability, and user comfort..</p>
            </div> 
            <div>
              <h3 className='font-20-res-16 weight-600'>Sustainability Integration</h3>
              <p className='font-18-res-16 weight-400'>We incorporate sustainable design principles and practices into our designs, such as energy-efficient lighting, eco-friendly materials, and waste reduction strategies. This not only reduces environmental impact but also lowers operating costs in the long run.</p>
            </div>
          </div>

          <h2 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>Objectives of Providing Space Planning and Design Service</h2>
          <DiscoverCard title="Enhance Operational Efficiency" paragraph="Our primary goal is to create workspaces that optimize workflow efficiency, communication, and collaboration among your team members, ultimately driving operational excellence." />
          <DiscoverCard title="Support Business Growth" paragraph="We aim to design spaces that are flexible and scalable, allowing your workspace to adapt to changing business needs and accommodate future growth.." />
          <DiscoverCard title="Promote Employee Well-Being" paragraph="By prioritizing user comfort, ergonomics, and wellness in our designs, we create environments that promote employee health, satisfaction, and productivity." />
          <DiscoverCard title="Elevate Brand Image" paragraph=" Our designs reflect your brand identity and values, creating a positive and memorable impression on clients, visitors, and employees, and reinforcing your brand image." />
          <DiscoverCard title="Ensure Cost-Effective Solutions" paragraph="We strive to deliver cost-effective design solutions that maximize the value of your investment and provide long-term benefits in terms of functionality, aesthetics, and sustainability." />
          <DiscoverCard title="" paragraph="Partner with Bizz Buzz Creation to transform your workspace into a functional, inspiring, and productive environment that supports your business goals and reflects your brand identity. Our space planning and design services are designed to exceed your expectations and elevate your workplace experience. Contact us today to learn more about how we can help you unlock the full potential of your space!"/>
        </div>
        </div>
        <div style={{ background: "#EEEEEE", padding: "0 0 20px 0" }}>
        <div className='container'>
          <div className='d-flex gap-5 align-items-center pt-5 flex_page'>
          <div className='img-width'>
            <img src={search} alt="search website" className='image'/>
            </div>
            <div className='text-start'>
              <h2 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>Interior Design</h2>
              <p  className='font-18-res-16 weight-400'>Welcome to Bizz Buzz Creation, where we specialize in delivering exceptional interior design services tailored to transform your space into a reflection of your style, functionality, and personality. Our team of experienced designers is dedicated to creating inspiring interiors that enhance your living or working environment and elevate your overall experience..</p>
            </div>
          </div>
          <h2 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>Why Choose Our Interior Design? Let's Break It Down!</h2>
          <DiscoverCard title="Personalized Design Solutions" paragraph="We understand that every space is unique, and so are your preferences and requirements. Our interior design services are tailored to your specific needs, ensuring that the final result reflects your personality and vision." />
          <DiscoverCard title="Professional Expertis" paragraph=" With years of experience in the industry, our designers bring a wealth of knowledge and expertise to every project. From space planning to color schemes, furniture selection, and decor, we provide professional guidance and advice to help you make informed decisions.." />
          <DiscoverCard title="Creative Vision" paragraph="Our designers have a keen eye for design and a passion for creativity. We infuse each project with fresh ideas and innovative solutions, ensuring your space stands out and leaves a lasting impression." />
          <DiscoverCard title="Enhanced Functionality" paragraph=" Improved Usefulness: Usefulness is a critical part of inside plan. We prioritize functionality and practicality in our designs, ensuring that your space not only looks beautiful but also serves its intended purpose efficiently." />
          <DiscoverCard title="Attention to Detail" paragraph="From the overall layout to the smallest decorative accents, we pay attention to every detail to ensure a cohesive and harmonious design. Our meticulous approach ensures that every element of your space contributes to the overall aesthetic appeal." />
          <h2 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>How our Social Media Advertising Services Can Help You?</h2>
          <div className='grid_portion' style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)",  gap: "10px", textAlign: "center" }}>
            <div>
              <h3 className='font-20-res-16 weight-600'>Initial Consultation</h3>
              <p className='font-18-res-16 weight-400'>We start by understanding your needs, preferences, and budget through an initial consultation. This helps us gain insight into your vision for the space and allows us to tailor our design approach accordingly.</p>
            </div>
            <div>
              <h3 className='font-20-res-16 weight-600'>Material and Finish Selection</h3>
              <p className='font-18-res-16 weight-400'>We assist you in selecting materials, finishes, and furnishings that complement your design vision and meet your aesthetic and practical requirements. Our extensive network of suppliers ensures access to high-quality products and materials..</p>
            </div>
             <div>
              <h3 className='font-20-res-16 weight-600'>Concept Development</h3>
              <p className='font-18-res-16 weight-400'>Based on the information gathered during the consultation, we develop initial design concepts and proposals. We present these concepts to you for feedback and refinement before moving forward with the design process.</p>
            </div>
            <div>
              <h3 className='font-20-res-16 weight-600'>Project Management</h3>
              <p className='font-18-res-16 weight-400'>From procurement to installation, we oversee every aspect of the project to ensure seamless execution and timely delivery. Our project management expertise minimizes disruptions and ensures quality control throughout the process..</p>
            </div>
            <div>
              <h3 className='font-20-res-16 weight-600'>Space Planning and Layout</h3>
              <p className='font-18-res-16 weight-400'> Our designers carefully plan the layout of your space to optimize functionality and flow. We consider factors such as traffic patterns, furniture placement, and spatial proportions to create a balanced and harmonious environment.</p>
            </div>
          </div>

          <h2 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>Objectives of Providing Website Maintenance and Support Service</h2>
          <DiscoverCard title="Create Inspiring Spaces" paragraph="Our primary goal is to create interior spaces that inspire, uplift, and enrich the lives of our clients. Whether it's a residential home, office, retail space, or hospitality venue, we aim to design environments that evoke a sense of comfort, beauty, and joy." />
          <DiscoverCard title="Enhance Quality of Life" paragraph="A well-designed interior can have a profound impact on your quality of life. We strive to design spaces that promote well-being, productivity, and happiness, allowing you to live, work, and play to the fullest." />
          <DiscoverCard title="Optimize Space Utilization" paragraph="We aim to maximize the potential of your space by optimizing layout, functionality, and efficiency. Our designs are tailored to make the most of available space, whether it's a small apartment or a large commercial building." />
          <DiscoverCard title="Reflect Client's Personality and Style" paragraph="Your space should be a reflection of who you are and what you love. We work closely with our clients to understand their unique tastes, preferences, and lifestyle, ensuring that the final design reflects their personality and style." />
          <DiscoverCard title="Achieve Client Satisfaction" paragraph="Ultimately, our goal is to exceed our clients' expectations and deliver designs that surpass their wildest dreams. We measure our success by the satisfaction and happiness of our clients, and we strive to achieve excellence in everything we do.." />        
          <DiscoverCard title=" " paragraph="Partner with Bizz Buzz Creation to transform your space into a masterpiece of design and functionality. Our interior design services are designed to bring your vision to life and create spaces that inspire and delight. Contact us today to learn more about how we can help you elevate your space!." />
         
        </div>
        </div>
        <div style={{ background: "#d4dfe3", padding: "0 0 20px 0" }}>
        <div className='container'>
          <div className='d-flex gap-5 align-items-center pt-5 flex_page'>
          <div className='img-width'>
            <img src={social} alt="social website" className='image'/>
            </div>
            <div className='text-start'>
              <h2 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>Technology Integration</h2>
              <p className='font-18-res-16 weight-400'>Welcome to Bizz Buzz Creation, your premier destination for seamless and effective technology integration solutions. In today's fast-paced digital landscape, businesses need to harness the power of technology to stay competitive and achieve their strategic goals. Our expert team specializes in integrating cutting-edge technologies into your existing infrastructure, empowering you to streamline processes, enhance productivity, and drive innovation..</p>
            </div>
          </div>
          <h2 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>Advantages of using our  Technology Integration</h2>
          <DiscoverCard title="Enhanced Efficiency" paragraph="Our technology integration services streamline your business processes, automating repetitive tasks and reducing manual errors. This enhanced efficiency allows you to accomplish more in less time, leading to increased productivity and cost savings." />
          <DiscoverCard title="Improved Collaboration" paragraph="By integrating collaborative tools and communication platforms, we facilitate seamless collaboration among your team members, regardless of their location. This fosters teamwork, creativity, and knowledge sharing, leading to better outcomes for your business." />
          <DiscoverCard title="Scalability and Flexibility" paragraph="Our technology solutions are designed to scale with your business and adapt to changing needs. Whether you're experiencing rapid growth or expanding into new markets, we ensure that your technology infrastructure can support your evolving requirements.." />
          <DiscoverCard title="Better Decision-Making" paragraph="With access to real-time data and analytics, you can make informed decisions quickly and confidently. Our technology integration solutions provide valuable insights into your business performance, customer behavior, and market trends, empowering you to stay ahead of the competition.." />
         <DiscoverCard title="Enhanced Customer Experience" paragraph="By integrating customer relationship management (CRM) systems and marketing automation tools, we help you deliver personalized experiences that delight your customers. This leads to increased customer satisfaction, loyalty, and retention."/>
          <h2 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>How our Website Maintenance and Support Service can help you?</h2>
          <div className='grid_portion' style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)",  gap: "10px", textAlign: "center" }}>
            <div>
              <h3 className='font-20-res-16 weight-600'>Comprehensive Assessment</h3>
              <p className='font-18-res-16 weight-400'>We begin by conducting a thorough assessment of your current technology infrastructure and business processes. This helps us identify areas for improvement and opportunities for integration.</p>
            </div>
            <div>
              <h3 className='font-20-res-16 weight-600'>Seamless Implementation</h3>
              <p className='font-18-res-16 weight-400'>Our experienced team manages the entire implementation process from start to finish, ensuring a smooth transition and minimal disruption to your business operations. We work closely with your team to ensure that the integration process is completed on time and within budget.</p>
            </div>
             <div>
              <h3 className='font-20-res-16 weight-600'>Customized Solution</h3>
              <p className='font-18-res-16 weight-400'>Based on our assessment and your unique business requirements, we develop customized technology integration solutions tailored to your specific needs. Whether it's integrating new software applications, upgrading hardware, or migrating to the cloud, we have the expertise to deliver.</p>
            </div>
            <div>
              <h3 className='font-20-res-16 weight-600'>Training and Support</h3>
              <p className='font-18-res-16 weight-400'>We provide comprehensive training and support to ensure that your team is fully equipped to leverage the new technology effectively. Our goal is to empower your employees to maximize the value of the integrated solutions and drive business success.</p>
            </div>
          </div>

          <h2 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>Objectives of Providing Website Maintenance and Support Service</h2>
          <div className='mb-5'>
          <DiscoverCard title="Drive Business Growth" paragraph="Our primary objective is to help you drive business growth and success through the effective integration of technology. By leveraging the latest advancements in technology, we enable you to innovate, differentiate, and stay ahead of the competition." />
          <DiscoverCard title="Enhance Operational Efficiency" paragraph="We aim to optimize your business processes and workflows to improve operational efficiency and productivity. Our technology integration solutions enable you to accomplish more with less effort, allowing you to focus on strategic initiatives and business growth." />
          <DiscoverCard title="Improve Customer Experience" paragraph=" By integrating customer-centric technologies, we help you deliver exceptional experiences that delight your customers and build loyalty. This leads to increased customer satisfaction, retention, and advocacy, driving long-term business success.." />
          <DiscoverCard title="Ensure Security and Compliance" paragraph="We prioritize security and compliance in all our technology integration efforts. By implementing robust security measures and ensuring compliance with industry regulations, we help protect your data and mitigate risk." />
          <DiscoverCard title="Deliver Value and ROI" paragraph="Ultimately, our goal is to deliver measurable value and a strong return on investment for your technology initiatives. We measure our success by the impact our solutions have on your business outcomes, whether it's increased revenue, reduced costs, or improved customer satisfaction." />        
          <br/>
          <DiscoverCard title=" " paragraph="Partner with Bizz Buzz Creation to unlock the full potential of technology and transform your business for success. Our technology integration services are designed to empower you to achieve your business objectives and thrive in today's digital world. Contact us today to learn more about how we can help you drive innovation, efficiency, and growth!." />
         
        </div>
        </div>
        </div> */}
    </>
  )
}

export default OfficeSpace