import React, { useState } from 'react'
import "./Navbar.css"
import logo_image from '../../Assets/BBC Logo.png'
import { Link, NavLink } from 'react-router-dom'
import { CrossIcon, HumburgIcon } from '../SVG/svg'
import { AiFillHome } from "react-icons/ai";
import { Img } from 'react-image'

const Nav = () => {

 const [showMenu, setShowMenu] = useState(false);

 const toggleMenu = () => {
  setShowMenu(!showMenu);
 };

 const handleClose =()=>{
  setShowMenu(false)
 }

  return (
  <div className='nav'>
    <div className='logo_portion'>
        <div className='logo'>
           <Link to="/" alt=""> <Img src={logo_image} alt='BizzBuzzCreations' className='logo_image'/></Link>
        </div>
    </div>
    <div className='menu' onClick={toggleMenu}>
      {/* <p>menu</p> */}
      {!showMenu ? (<HumburgIcon/>) : (<CrossIcon />)}
    </div>
    <div className={`page_portion ${showMenu ? 'show' : ''}`} onClick={handleClose}>
        <div className='nav-container'>
          <NavLink to="/" className='nav_content_css' alt="home"><p className='link_heading weight-500'><AiFillHome size={22} /></p></NavLink>
          <NavLink to="/service" className='nav_content_css' alt="service"><p className='link_heading weight-500'>Services</p></NavLink>
          <NavLink to="/casestudy" className='nav_content_css' alt="casestudy"><p className='link_heading weight-500'>Case Studies</p></NavLink>
          <NavLink to="/about" className='nav_content_css' alt="about"><p className='link_heading weight-500'>About Us</p></NavLink>
          <NavLink to="/contact" className='nav_content_css'alt="contact"><p className='link_heading weight-500'>Contact Us</p></NavLink>
          <NavLink to="/careers" className='nav_content_css'alt="contact"><p className='link_heading weight-500'>Careers</p></NavLink>
        </div>
    </div>
  </div>
  )
}

export default Nav