import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import './ServiceCard.css'
import { Img } from 'react-image';

function ServiceCard({imgUrl, serviceNewColor, service}) {
  return (
    <div className='card-container'>
      <div className='card bg-white rounded'>
        <Img className='service-img' src={imgUrl} alt="services" />
        <button className='readmore-btn  weight-400 font-18-res-14 '>Read More</button>
      </div>
      <h4 className='text-white mt-20 weight-600 text-center'><span className='serviceColor'>{serviceNewColor}</span> {service}</h4>
    </div>
  )
}

export default ServiceCard