import React from "react";
import "./CaseStudies.css";
import CaseStudyCard from "../../Component/CaseStudyCard/CaseStudyCard";
import testi1 from "../../Assets/fibarnetNew.png";
import testi2 from "../../Assets/mmdfNew.png";
import testi3 from "../../Assets/avondale.png";
import testi4 from "../../Assets/UKCE.png";
import { Helmet } from "react-helmet";

const CaseStudies = () => {
  return (
    <>
      <Helmet>
        <title>Explore Success Stories and Client Case Studies</title>
        <meta
          name="description"
          content="Discover how Bizz Buzz Creations has helped clients achieve success through innovative solutions and impactful strategies in our case studies."
        />
        <meta
          name="keywords"
          content="web development services in prayagraj, best website development in prayagraj, SMM services in prayagraj, best SMM services in prayagraj, digital marketing services and agency in prayagraj, best digital marketing agency in prayagraj, digital marketing in prayagraj, cloud computing services in prayagraj, financial advisory services in prayagraj, BPO Outsourcing services in Prayagraj"
        />
        <meta name="author" content="BizzBuzzCreations" />
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-XVSSY0BR1E"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-XVSSY0BR1E');
        `}
        </script>
      </Helmet>
      <div className="casestudiesparent bg-primary-light">
        <h2 className="text-center weight-700 mb-40">
          Transforming Visions into Reality: Our Success Stories
        </h2>
        <div className="case-studies">
          <CaseStudyCard
            image={testi2}
            title="Empowering Make Me Debt Free with Comprehensive Digital Solutions"
            description="Make Me Debt Free, a leading UK debt solutions provider, partnered with BizzBuzz Creations to enhance their digital presence. As a digital marketing agency in Prayagraj, BizzBuzz revamped their website for improved user experience and implemented a robust digital marketing strategy, including SEO, content marketing, and targeted PPC campaigns. These efforts increased online visibility, improved search rankings, and generated qualified leads, positioning Make Me Debt Free as an industry leader. The collaboration empowered them to expand reach, attract more clients, and deliver effective debt solutions, fostering business growth and client financial freedom."
            download="https://drive.google.com/file/d/1JReXBY15w83liX8B5pDzPCoiGUvm4nS6/view"
            bgHex="#EBD59F"
          />
          <CaseStudyCard
            image={testi3}
            title="Empowering Avondale Finance with BizzBuzz Creations Solutions"
            description="Avondale Finance, a premier financial services provider, teamed up with BizzBuzz Creations, a renowned website design and development firm, to transform its digital presence. Through our digital transformation consulting, we enhanced Avondale Finance's web visibility, streamlined its operations, and strengthened its data security measures. Learn how our digital marketing agency in Prayagraj can boost your business with customized web solutions, strategic digital marketing, advanced cloud computing options, and dependable technical support."
            download="https://drive.google.com/file/d/1KVTvrN4GWwGwlsf-F2o70Evi0ht2z7HV/view"
            bgHex="#F5CFD3"
          />
          <CaseStudyCard
            image={testi4}
            title="Revolutionizing UK Claims Expert Services with Web Design and Digital Marketing Solutions"
            description="Make Me Debt Free, a leading UK debt solutions provider, partnered with BizzBuzz Creations to enhance their digital presence. As a digital marketing agency in Prayagraj, BizzBuzz revamped their website for improved user experience and implemented a robust digital marketing strategy, including SEO, content marketing, and targeted PPC campaigns. These efforts increased online visibility, improved search rankings, and generated qualified leads, positioning Make Me Debt Free as an industry leader. The collaboration empowered them to expand reach, attract more clients, and deliver effective debt solutions, fostering business growth and client financial freedom."
            download="https://drive.google.com/file/d/1oYerbeAXw4zWf1aFJ0n1co4Qc46aRulI/view"
            bgHex="#BEEEB1"
          />
          <CaseStudyCard
            image={testi1}
            title="Elevating Connectivity with BizzBuzz Creations’ Integration for ITS Fibernet"
            description="ITS Fibernet, a leader in internet services, partnered with BizzBuzz Creations to improve their digital presence and operational efficiency. BizzBuzz redesigned their website for a better user experience and implemented data-driven digital marketing strategies, including SEO and targeted ads. Our IT services optimized their infrastructure, enhancing performance, security, and scalability. This collaboration led to increased online visibility, higher user engagement, improved efficiency, and seamless team communication, positioning ITS Fibernet for long-term success in the digital landscape."
            download="https://drive.google.com/file/d/18nD5GIhet0jzTcjI8ilfKCKPvbtMyHcs/view"
            bgHex="#BBD2EB"
          />
        </div>
      </div>
    </>
  );
};

export default CaseStudies;