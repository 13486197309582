import React from 'react'
import maintainImg from '../../Assets/Service4.4.svg'
import custom from '../../Assets/Service4.2.svg'
import ecomm from '../../Assets/Service4.3.svg'
import UncoverCard from '../../Component/UncoverCard/UncoverCard'
import DiscoverCard from '../../Component/DiscoverCard/DiscoverCard'
import webImage from '../../Assets/image_main/Human Resources.jpg'
import hr1 from "../../Assets/Customized Solutions.jpg"
import hr2 from "../../Assets/Cost Saving.jpg"
import hr3 from "../../Assets/Risk mitigation.jpg"
import hr4 from "../../Assets/Strategic Partnership.jpg"
import DiscoverCardCenter from '../../Component/DiscoverCardCenter/DiscoverCardCenter'
import Goals from '../../Component/Goals/Goals'
import Customer_Support from "../../Assets/icon/customer support.jpg"
import Technical_Support from "../../Assets/icon/technical support.jpg"
import Data_Entry from "../../Assets/icon/data entry and management.jpg"
import Human_Resources from "../../Assets/icon/human resource outsourcing.jpg"
import Outbound_Sales from "../../Assets/icon/outbound sales.jpg"
import E_commerce from "../../Assets/icon/e-commerce support.jpg"
import Content_Moderation from "../../Assets/icon/content moderation.jpg"
import Cost_Efficiency from "../../Assets/icon/cost efficiency.jpg"
import Expertise_and_Experience from "../../Assets/icon/expertise and experience.jpg"
import Scalability from "../../Assets/icon/scalability.jpg"
import Focus_on_Core_Competencies from "../../Assets/icon/foxus on core competencies.jpg"
import Quality_Assurance from "../../Assets/icon/quality assurance.jpg"
import Expert_Team from "../../Assets/image/Expert Team.jpg"
import Omni_Channel from "../../Assets/image/Omni-channel Support.jpg"
import Personalized_Solutions from "../../Assets/image/Personalized Solutions.jpg"
import Focus_on_Customer from "../../Assets/image/focus on customer experience.jpg"
import Advanced_Technology from "../../Assets/image/advanced technology.jpg"
import Continuous_Improvement from "../../Assets/image/continuous improvement.jpg"
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'





function HRPage() {
  return (
    <>
      <Helmet>
        <title>BPO Services | E-commerce, Customer & Technical Support | Data Entry Management | Outbound Sales</title>
        <meta
          name="description"
          content="Enhance your business with our BPO services, offering e-commerce support, customer care, technical assistance, data entry, and outbound sales solutions."
        />
        <meta
          name="keywords"
          content="web development services in prayagraj, best website development in prayagraj, SMM services in prayagraj, best SMM services in prayagraj, digital marketing services and agency in prayagraj, best digital marketing agency in prayagraj, digital marketing in prayagraj, cloud computing services in prayagraj, financial advisory services in prayagraj, BPO Outsourcing services in Prayagraj"
        />
        <meta name="author" content="BizzBuzzCreations" />
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-XVSSY0BR1E"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-XVSSY0BR1E');
        `}
        </script>
      </Helmet>
      <div className="webdevelpment_page d-flex align-items-center justify-content-center text-center flex_page">
        {/* <div className="image_portion_web img-width hr_hero">
          <img src={webImage} alt="image" className="image_main" />
        </div> */}
        <div className="content_portion p-15">
          <h2 className="weight-600 mb-3">
            BPO (Business Process Outsourcing)
          </h2>
          <h5 className="weight-500">
            Welcome to Bizz Buzz Creation - Your trusted partner for BPO (Business Process Outsourcing)
          </h5>
          <p className="weight-400 mb-5">
            Business Process Outsourcing (BPO) involves contracting third-party service providers to handle non-core business functions, such as customer service, payroll, and data entry, enhancing efficiency and allowing organizations to focus on core activities.
          </p>
          <button className="get_button"><Link className="text-decoration-none" to="/contact">Get Started</Link></button>
        </div>
      </div>
      <div className='web_portion'>
        <div className='head_portion'>
          <h1 className='font-20-res-16 weight-600 text-center mb-5'>BPO(Business Process Outsourcing) Services We Offer</h1>
        </div>
        <div className='grid_portion'>
          <DiscoverCardCenter icon_image={Customer_Support} title="Customer Support" paragraph="Customer support involves assisting customers with inquiries, issues, and product-related questions through various channels like phone, email, and chat, enhancing satisfaction and loyalty while resolving problems efficiently." />
          <DiscoverCardCenter icon_image={Technical_Support} title="Technical Support" paragraph="Technical support involves assisting customers with troubleshooting and resolving issues related to products or services, providing guidance through various channels, such as phone, chat, and email, to ensure user satisfaction." />
          <DiscoverCardCenter icon_image={Data_Entry} title="Data Entry and Management" paragraph="Data entry and management involve accurately inputting, organizing, and maintaining data in databases or systems, ensuring data integrity, accessibility, and security to support informed decision-making and operational efficiency." />
          <DiscoverCardCenter icon_image={Human_Resources} title="Human Resources Outsourcing (HRO)" paragraph="Human Resources Outsourcing (HRO) involves delegating HR functions, such as recruitment, payroll processing, employee benefits management, and compliance, to third-party service providers, enhancing efficiency and allowing organizations to focus on core operations." />
          <DiscoverCardCenter icon_image={Outbound_Sales} title="Outbound Sales" paragraph="Outbound sales involve proactive outreach to potential customers through methods like cold calling, email campaigns, and social media engagement, aiming to generate leads, close deals, and boost revenue growth." />
          <DiscoverCardCenter icon_image={E_commerce} title="E-commerce Support" paragraph="E-commerce support involves managing online retail operations, including customer inquiries, order processing, product listings, inventory management, and technical assistance, ensuring a seamless shopping experience and enhancing customer satisfaction." />
          <DiscoverCardCenter icon_image={Content_Moderation} title="Content Moderation" paragraph="Content moderation involves monitoring, reviewing, and managing user-generated content on platforms to ensure compliance with community guidelines, promote a safe environment, and protect brand reputation from harmful or inappropriate material." />
          {/* <DiscoverCardCenter title="" paragraph=""/>
        <DiscoverCardCenter title="" paragraph=""/>
        <DiscoverCardCenter title="" paragraph=""/>
        <DiscoverCardCenter title="" paragraph=""/>
        <DiscoverCardCenter title="" paragraph=""/> */}
        </div>

      </div>
      <div className='incover_portion pb-5'>
        <h1 className='font-32-res-22 weight-600  text-center mt-5 mb-50'> Objectives of  Customer Communication Partner </h1>
        <div className='grid_portion m-auto align-items-center justify-content-center '>
          <UncoverCard image_uncover={Expert_Team} title="Expert Team" paragraph=" Our experienced professionals specialize in customer communication, employing best practices and strategies to enhance engagement and satisfaction." />
          <UncoverCard image_uncover={Omni_Channel} title="Omni-Channel Support" paragraph="We offer comprehensive communication solutions across various channels, including phone, email, chat, and social media, ensuring consistent and accessible support for your customers." />
          <UncoverCard image_uncover={Personalized_Solutions} title="Personalized Solutions" paragraph="We understand that every business is unique, so we customize our communication strategies to align with your specific goals and target audience" />
          <UncoverCard image_uncover={Focus_on_Customer} title="Focus on Customer Experience" paragraph="Our priority is to deliver exceptional customer experiences, fostering loyalty and retention through effective communication and timely responses." />
          <UncoverCard image_uncover={Advanced_Technology} title="Advanced Technology" paragraph="BizzBuzz utilizes cutting-edge tools and software to streamline communication processes, improve efficiency, and enhance data management." />
          <UncoverCard image_uncover={Continuous_Improvement} title="Continuous Improvement" paragraph="We regularly analyze performance metrics and gather customer feedback to refine our strategies, ensuring ongoing enhancement of communication effectiveness and customer satisfaction." />
        </div>

      </div>
      <div className='Page'>
        <h1 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>Why Choose BBC for Business Process Outsourcing (BPO) Services ?</h1><div className="App">
          <div className="card-container container">
            <Goals logo_icon={Cost_Efficiency} title="Cost Efficiency" paragraph="BBC provides competitive pricing, helping businesses lower operational costs without compromising on high-quality service delivery, ensuring efficiency and value for your investment." />
            <Goals logo_icon={Expertise_and_Experience} title="Expertise and Experience" paragraph="Our team consists of skilled professionals with extensive experience across various industries, ensuring reliable and efficient management of your business processes." />
            <Goals logo_icon={Scalability} title="Scalability" paragraph="BBC offers scalable BPO solutions, enabling your business to adapt quickly to changing market conditions and demands, ensuring flexibility and efficiency as your needs evolve." />
            <Goals logo_icon={Focus_on_Core_Competencies} title="Focus on Core Competencies" paragraph="Outsource non-core functions to BBC, allowing you to focus on key business activities, driving growth and innovation in your core areas for maximum impact and efficiency." />
            <Goals logo_icon={Quality_Assurance} title="Quality Assurance" paragraph="We enforce strict quality control measures to uphold high service standards, ensuring consistent performance and maximizing customer satisfaction at every stage of delivery." />
            <Goals logo_icon={Advanced_Technology} title="Advanced Technology" paragraph="BBC leverages the latest technologies and tools to enhance process efficiency, improve data management, and provide innovative solutions tailored to your business requirements." />
          </div>
        </div>
      </div>

      {/* <div style={{ background: "#d4dfe3", padding: "0 0 20px 0" }}>
        <div className='container'>
          <div className='d-flex gap-5 align-items-center pt-5 flex_page'>
            <div className='img-width hr_hero'>
            <img src={custom} alt="ecomm website" className='imagehr' />
            </div>
            <div className='text-start'>
              <h2 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>Recruitment and Staffing</h2>
              <p className='font-18-res-16 weight-400 mt-5'>Welcome to Bizz Buzz Creation, your trusted partner for comprehensive recruitment and staffing solutions.We comprehend that finding the right ability is pivotal for the progress of your association.. Whether you're looking to fill temporary positions, expand your team, or hire top-notch talent for key roles, our expert recruiters are here to help you find the perfect fit.</p>
            </div>
          </div>
          <h2 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>Why Choose BBC for Business Process Outsourcing (BPO) Services ?</h2>
          <DiscoverCard title="Cost Efficiency" paragraph="BBC offers competitive pricing structures, enabling businesses to reduce operational costs while maintaining high-quality service delivery." />
          <DiscoverCard title="Expertise and Experience" paragraph="Our team consists of skilled professionals with extensive experience across various industries, ensuring reliable and efficient management of your business processes." />
          <DiscoverCard title="Scalability" paragraph="BBC provides flexible BPO solutions that can scale with your business needs, allowing you to adapt quickly to changing market conditions and demands." />
          <DiscoverCard title="Focus on Core Competencies" paragraph="By outsourcing non-core functions to BBC, you can concentrate on your primary business activities, driving growth and innovation in your core areas." />
          <DiscoverCard title="Quality Assurance" paragraph="We implement rigorous quality control measures to maintain high service standards, ensuring consistent performance and customer satisfaction." />
          <DiscoverCard title="Advanced Technology" paragraph="BBC leverages the latest technologies and tools to enhance process efficiency, improve data management, and provide innovative solutions tailored to your business requirements."/>

          <h2 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>How our Recruitment and Staffing services can help you ?</h2>
          <div className='grid_portion' style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)",  gap: "10px", textAlign: "center" }}>
            <div>
              <h3 className='font-20-res-16 weight-600'>Customized Recruitment Solutions</h3>
              <p className='font-18-res-16 weight-400 mb-5'>We tailor our recruitment solutions to your specific needs, whether you need to fill a single position or recruit for multiple roles across different departments. Our customized approach ensures that we deliver the right candidates who meet your exact requirements.</p>
            </div>
            <div>
              <h3 className='font-20-res-16 weight-600'>Streamlined Hiring Process</h3>
              <p className='font-18-res-16 weight-400 mb-5'>We manage the entire hiring process from start to finish, including candidate screening, interviews, reference checks, and job offers. Our streamlined process ensures a smooth and efficient hiring experience for both you and the candidates.</p>
            </div>
            <div>
              <h3 className='font-20-res-16 weight-600'>Strategic Talent Acquisition</h3>
              <p className='font-18-res-16 weight-400 mb-5'>We partner with you to understand your business objectives, company culture, and hiring goals. This allows us to develop a strategic talent acquisition strategy that aligns with your overall business strategy and drives organizational success.</p>
            </div>
            <div>
              <h3 className='font-20-res-16 weight-600'>Post-Placement Support</h3>
              <p className='font-18-res-16 weight-400 mb-5'>Our commitment to customer satisfaction doesn't end with the hiring process. We provide post-placement support to ensure a successful transition for the new hire and ongoing communication to address any concerns or issues that may arise.</p>
            </div>
            <div>
              <h3 className='font-20-res-16 weight-600'>Efficient Candidate Sourcing</h3>
              <p className='font-18-res-16 weight-400 mb-5'>We leverage a variety of sourcing methods, including job boards, social media, networking events, and our extensive candidate database, to attract top talent. Our proactive approach to candidate sourcing ensures that we find the best candidates for your open positions</p>
            </div>
          </div>

          <h2 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>Objectives of Providing Space Planning and Design Service</h2>
          <DiscoverCard title="Drive Organizational Growth" paragraph="Our primary objective is to help you build a high-performing team that drives organizational growth and success. By sourcing top talent and filling critical roles, we enable you to achieve your business objectives and stay ahead of the competition." />
          <DiscoverCard title="Reduce Time-to-Hire" paragraph="We aim to reduce time-to-hire and minimize the time and effort spent on the recruitment process. Our efficient sourcing methods and streamlined hiring process enable us to find qualified candidates quickly, allowing you to fill open positions faster and maintain business continuity." />
          <DiscoverCard title="Ensure Quality and Fit" paragraph="We strive to match candidates not only based on their skills and qualifications but also on their fit with your organizational culture and values. Our objective is to find candidates who not only have the right expertise but also align with your company's mission, vision, and goals." />
          <DiscoverCard title="Provide Cost-Effective Solutions" paragraph="We aim to provide cost-effective recruitment solutions that deliver maximum value for your investment. By outsourcing your recruitment needs to Bizz Buzz Creation, you can reduce recruitment costs, minimize hiring risks, and optimize your hiring budget." />
          <DiscoverCard title="Deliver Exceptional Customer Service" paragraph="We are committed to providing exceptional customer service and support throughout the recruitment process. Our objective is to exceed your expectations and ensure a positive experience for both you and the candidates we place." />
          <DiscoverCard title="" paragraph="Partner with Bizz Buzz Creation to streamline your recruitment process, attract top talent, and build a high-performing team. Our recruitment and staffing services are designed to help you achieve your hiring goals and drive organizational success. Contact us today to learn more about how we can help you find the perfect fit for your team!" />
        </div>
      </div>

      <div style={{ background: "#EEEEEE", padding: "0 0 20px 0" }}>
        <div className='container'>
          <div className='d-flex gap-5 align-items-center pt-5 flex_page'>
            <div className='img-width hr_hero'>
            <img src={ecomm} alt="social website" className='imagehr' />
            </div>
            <div className='text-start'>
              <h2 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>Onboarding and Orientatio</h2>
              <p className='font-18-res-16 weight-400 mt-5'>Welcome to Bizz Buzz Creation, where we specialize in providing comprehensive onboarding and orientation services to help your new hires hit the ground running and thrive in your organization. We understand that effective onboarding is essential for employee retention, engagement, and productivity. That's why our expert team is dedicated to delivering a seamless and memorable onboarding experience for your employees.</p>
            </div>
          </div>
          <h2 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>Why Choose Our Onboarding and Orientation? Let's Break It Down!</h2>
          <DiscoverCard title="Enhanced Employee Engagement" paragraph="Our onboarding and orientation services are designed to engage and empower your new hires from day one. By providing them with the information, resources, and support they need to succeed, we help them feel valued, motivated, and excited about their new role." />
          <DiscoverCard title="Faster Time-to-Productivity" paragraph="Our structured onboarding process accelerates the time it takes for new hires to become fully productive in their roles. By providing them with clear expectations, training, and support, we help them ramp up quickly and contribute to your organization's success sooner." />
          <DiscoverCard title="Improved Retention Rates" paragraph="Effective onboarding is key to retaining top talent and reducing employee turnover. Our onboarding and orientation services help new hires feel welcomed, supported, and connected to your organization, increasing their likelihood of staying with your company long-term." />
          <DiscoverCard title="Consistent Onboarding Experience" paragraph="We ensure a consistent onboarding experience for all new hires, regardless of their role, department, or location. Our standardized onboarding process ensures that every employee receives the same level of support and information, fostering a sense of fairness and equity." />
          <DiscoverCard title="Positive Employer Branding" paragraph="A positive onboarding experience reflects well on your employer brand and helps attract top talent to your organization. By demonstrating your commitment to employee development and success, you create a favorable impression that resonates with prospective candidates." />
          <h2 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>How our Onboarding and Orientation Services Can Help You</h2>
          <div className='grid_portion' style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", placeItems: "center", gap: "50px", textAlign: "center" }}>
            <div>
              <h3 className='font-20-res-16 weight-600'>Customized Onboarding Plans</h3>
              <p className='font-18-res-16 weight-400 mb-5'>We work closely with your HR team to develop customized onboarding plans tailored to your organization's culture, values, and objectives. Whether you're hiring for entry-level positions or executive roles, we ensure that the onboarding process aligns with your unique needs and requirements.</p>
            </div>
            <div>
              <h3 className='font-20-res-16 weight-600'>Assigned Mentors or Buddies</h3>
              <p className='font-18-res-16 weight-400 mb-5'>We pair new hires with experienced employees who can serve as mentors or buddies during the onboarding process. These mentors provide guidance, support, and encouragement, helping new employees acclimate to their new environment and feel part of the team.</p>
            </div>
            <div>
              <h3 className='font-20-res-16 weight-600'>Comprehensive Orientation Materials</h3>
              <p className='font-18-res-16 weight-400 mb-5'>We provide new hires with comprehensive orientation materials, including employee handbooks, training manuals, and company policies. These resources help new employees understand your organization's mission, values, and expectations from day one.</p>
            </div>
            <div>
              <h3 className='font-20-res-16 weight-600'>Feedback and Follow-Up</h3>
              <p className='font-18-res-16 weight-400 mb-5'>We solicit feedback from new hires throughout the onboarding process to ensure that their needs and concerns are addressed promptly. We also conduct follow-up sessions after the initial onboarding period to check in with new employees, answer any questions they may have, and gather feedback on their experience.</p>
            </div>
            <div>
              <h3 className='font-20-res-16 weight-600'>Interactive Training Sessions</h3>
              <p className='font-18-res-16 weight-400 mb-5'>Our onboarding process includes interactive training sessions to help new hires learn about their roles, responsibilities, and the tools and systems they'll be using. We offer both in-person and virtual training options to accommodate different learning styles and preferences.</p>
            </div>
          </div>

          <h2 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>Objectives of Providing Onboarding and Orientation Services</h2>
          <DiscoverCard title="Ensure Successful Integration" paragraph="Our primary objective is to ensure the successful integration of new hires into your organization. By providing them with the tools, resources, and support they need to succeed, we help them become productive members of your team faster and more effectively." />
          <DiscoverCard title="Enhance Employee Satisfaction" paragraph="We aim to enhance employee satisfaction and engagement by providing new hires with a positive onboarding experience. A well-executed onboarding process demonstrates your commitment to employee development and sets the stage for a long and successful career with your organization." />
          <DiscoverCard title="Position Your Organization as an Employer of Choice" paragraph="A positive onboarding experience positions your organization as an employer of choice and helps attract top talent to your organization. By demonstrating your commitment to employee development and success, you create a compelling employer brand that sets you apart from the competition." />
          <DiscoverCard title="Drive Employee Performance and Retention" paragraph="Our onboarding and orientation services are designed to drive employee performance and retention by equipping new hires with the knowledge, skills, and resources they need to excel in their roles. By reducing turnover and increasing employee tenure, we help you build a more stable and productive workforce." />
          <DiscoverCard title="Support Organizational Culture and Values" paragraph="We help new hires understand and embrace your organization's culture, values, and mission. By aligning new employees with your company's core principles and beliefs, we foster a sense of belonging and commitment that strengthens your organizational culture." />
          <DiscoverCard title=" " paragraph="Partner with Bizz Buzz Creation to create an exceptional onboarding experience for your new hires and set them up for success from day one. Our onboarding and orientation services are designed to help you attract, retain, and develop top talent and drive organizational success. Contact us today to learn more about how we can support your onboarding efforts!" />
        </div>
      </div>

      <div style={{ background: "#d4dfe3", padding: "0 0 20px 0" }}>
        <div className='container'>
          <div className='d-flex gap-5 align-items-center pt-5 flex_page '>
            <div className='img-width hr_hero'>
            <img src={maintainImg} alt="ecomm website" className="imagehr" />
            </div>
            <div className='text-start'>
              <h2 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>Training and Development</h2>
              <p className='font-18-res-16 weight-400'>Welcome to Bizz Buzz Creation, your premier destination for comprehensive training and development solutions designed to empower your employees and drive organizational success. We understand that investing in employee training and development is essential for enhancing skills, boosting performance, and fostering a culture of continuous learning. That's why our expert team is dedicated to delivering high-impact training programs that inspire growth, innovation, and excellence.</p>
            </div> 
          </div>
          <h2 className='text-center mb-5 pt-5'>Advantages of using our  Technology Integration</h2>
          <DiscoverCard title="Enhanced Employee Skills" paragraph="Our training and development programs are designed to enhance employee skills and competencies, equipping them with the knowledge and capabilities they need to excel in their roles. From technical skills to soft skills and leadership development, we offer a wide range of training programs to meet your organization's needs." />
          <DiscoverCard title="Improved Employee Performance" paragraph="By investing in employee training and development, you can improve employee performance and productivity. Our tailored training programs help employees sharpen their skills, overcome challenges, and achieve their full potential, leading to better outcomes for your organization." />
          <DiscoverCard title="Expanded Representative Commitment" paragraph="Connected with workers are more roused, useful, and focused on their work.. Our training and development services help boost employee engagement by providing opportunities for growth, learning, and advancement within your organization." />
          <DiscoverCard title="Retention of Top Talent" paragraph="Employees are more likely to stay with organizations that invest in their professional development and career growth. By offering comprehensive training and development opportunities, you can attract and retain top talent, reducing turnover and preserving institutional knowledge." />
          <DiscoverCard title="Alignment with Organizational Goals" paragraph="Our training and development programs are aligned with your organization's goals, objectives, and values. We work closely with you to identify key areas for improvement and develop customized training solutions that address your specific needs and priorities." />

          <h2 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>How our Website Training and Development Service can help you</h2>
          <div className='grid_portion' style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)",  gap: "10px", textAlign: "center" }}>
            <div>
              <h3 className='font-20-res-16 weight-600'>Customized Training Programs</h3>
              <p className='font-18-res-16 weight-400 mb-5'>We work collaboratively with your organization to develop customized training programs tailored to your unique needs and objectives. Whether you're looking to onboard new employees, upskill existing staff, or develop leadership talent, we have the expertise to deliver impactful training solutions.</p>
            </div>
            <div>
              <h3 className='font-20-res-16 weight-600'>Expert Facilitators and Instructor</h3>
              <p className='font-18-res-16 weight-400 mb-5'>Our training programs are led by experienced facilitators and instructors who are subject matter experts in their respective fields. They bring real-world experience and practical insights to the training room, making the learning experience relevant, engaging, and impactful.</p>
            </div>
            <div>
              <h3 className='font-20-res-16 weight-600'>Ongoing Support and Follow-Up</h3>
              <p className='font-18-res-16 weight-400 mb-5'>Our commitment to your success doesn't end when the training session is over. We provide ongoing support and follow-up to ensure that participants are able to apply what they've learned in their day-to-day work. We offer coaching, mentoring, and resources to reinforce learning and facilitate continuous improvement.</p>
            </div>
            <div>
              <h3 className='font-20-res-16 weight-600'>Interactive Learning Experiences</h3>
              <p className='font-18-res-16 weight-400 mb-5'>We believe in interactive learning experiences that engage participants and promote active participation. Our training programs incorporate a variety of instructional techniques, including group discussions, case studies, role-playing exercises, and hands-on activities, to stimulate learning and retention.</p>
            </div>
            <div>
              <h3 className='font-20-res-16 weight-600'>Flexible Delivery Methods</h3>
              <p className='font-18-res-16 weight-400 mb-5'>We offer flexible delivery methods for our training programs, including in-person workshops, virtual training sessions, e-learning modules, and blended learning approaches. This flexibility allows us to accommodate diverse learning styles and preferences while maximizing accessibility and engagement.</p>
            </div>
          </div>

          <h2 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>Objectives of Providing Training and Development Services</h2>
          <div className='mb-5'>
          <DiscoverCard title="Enhance Employee Performance and Productivity" paragraph="Our primary objective is to enhance employee performance and productivity through targeted training and development initiatives. By equipping employees with the knowledge and skills they need to succeed, we help them perform at their best and contribute to your organization's success." />
          <DiscoverCard title="Drive Organizational Growth and Innovation" paragraph="We aim to drive organizational growth and innovation by fostering a culture of continuous learning and development. Our training programs help employees adapt to change, embrace new technologies, and think creatively, driving innovation and competitive advantage." />
          <DiscoverCard title="Boost Employee Engagement and Satisfaction" paragraph="We strive to boost employee engagement and satisfaction by providing meaningful learning opportunities that support career growth and advancement. Engaged employees are more motivated, productive, and committed to their work, leading to better outcomes for your organization." />
          <DiscoverCard title="Attract and Retain Top Talent" paragraph="Our training and development programs help you attract and retain top talent by offering opportunities for professional growth and advancement. Employees are more likely to stay with organizations that invest in their development and provide opportunities for career progression." />
          <DiscoverCard title="Foster a Culture of Continuous Learning" paragraph="We aim to foster a culture of continuous learning and development where employees are empowered to take ownership of their own growth and development. By promoting lifelong learning and skill development, we help create a workforce that is adaptable, resilient, and future-ready." />
          <br/>
          <DiscoverCard title="" paragraph="Partner with Bizz Buzz Creation to elevate your training and development initiatives and empower your employees to reach their full potential. Our comprehensive training solutions are designed to enhance skills, boost performance, and drive organizational success. Contact us today to learn more about how we can help you achieve your training and development objectives!" />
        </div>
        </div>
      </div> */}
    </>
  )
}

export default HRPage