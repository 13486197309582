import React from "react";
import "./Blog.css";
import { Img } from "react-image";

function Blog() {
  return (
    <>
      <div className="blog">
        <h1 className="blog-heading mb-30 text-center weight-600 ">Blogs</h1>
        <div className="content-header">
          <h2 className="week-heading weight-700">The Week's Best</h2>
          <p className="see-all ">See all posts &rarr;</p>
        </div>
        <div className="blog-container">
          <div className="blog-post large">
            <div className="date-badge">May 20, 2024</div>
          </div>
          <div className="column">
            <div className="image_portion mb-15">
              <Img
                className="image1"
                src="https://www.hostinger.com/tutorials/wp-content/uploads/sites/2/2022/03/what-is-a-blog-1.webp"
                alt="Post"
              />
            </div>
            <div className="blog-post small1">
              <div className="date-badge">May 20, 2024</div>
            </div>
          </div>
        </div>
        <button className="post-btn">See all posts &rarr;</button>
      </div>
      
    </>
  );
}

export default Blog;
